@charset "UTF-8";
/**
 * 1. Focus rings shouldn't be visible on scrollable regions, but a11y requires them to be focusable.
 *    Browser's supporting `:focus-visible` will still show outline on keyboard focus only.
 *    Others like Safari, won't show anything at all.
 */
@keyframes euiAnimFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes euiGrow {
    0% {
        opacity: 0;
    }
    1% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes euiButtonActive {
    50% {
        transform: translateY(1px);
    }
}

.eui-alignBaseline {
    vertical-align: baseline !important;
}

.eui-alignBottom {
    vertical-align: bottom !important;
}

.eui-alignMiddle {
    vertical-align: middle !important;
}

.eui-alignTop {
    vertical-align: top !important;
}

.eui-displayBlock {
    display: block !important;
}

.eui-displayInline {
    display: inline !important;
}

.eui-displayInlineBlock {
    display: inline-block !important;
}

.eui-fullWidth {
    display: block !important;
    width: 100% !important;
}

.eui-textCenter {
    text-align: center !important;
}

.eui-textLeft {
    text-align: left !important;
}

.eui-textRight {
    text-align: right !important;
}

.eui-textNoWrap {
    white-space: nowrap !important;
}

.eui-textInheritColor {
    color: inherit !important;
}

.eui-textBreakWord {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
}

.eui-textBreakAll {
    overflow-wrap: break-word !important;
    word-break: break-all !important;
}

.eui-textBreakNormal {
    overflow-wrap: normal !important;
    word-wrap: normal !important;
    word-break: normal !important;
}

.eui-textOverflowWrap {
    overflow-wrap: break-word !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .eui-textOverflowWrap {
        word-break: break-all !important;
    }
}

.eui-textTruncate {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

/**
 *  Responsive
 *
 *  1. Be sure to hide the element initially
 */
[class*='eui-showFor'] {
    display: none !important;
    /* 1 */
}

@media only screen and (max-width: 574px) {
    .eui-hideFor--xs {
        display: none !important;
    }
}

@media only screen and (max-width: 574px) {
    .eui-showFor--xs {
        display: inline !important;
    }
}

@media only screen and (max-width: 574px) {
    .eui-showFor--xs--block {
        display: block !important;
    }
}

@media only screen and (max-width: 574px) {
    .eui-showFor--xs--inlineBlock {
        display: inline-block !important;
    }
}

@media only screen and (max-width: 574px) {
    .eui-showFor--xs--flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .eui-hideFor--s {
        display: none !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .eui-showFor--s {
        display: inline !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .eui-showFor--s--block {
        display: block !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .eui-showFor--s--inlineBlock {
        display: inline-block !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .eui-showFor--s--flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .eui-hideFor--m {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .eui-showFor--m {
        display: inline !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .eui-showFor--m--block {
        display: block !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .eui-showFor--m--inlineBlock {
        display: inline-block !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .eui-showFor--m--flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eui-hideFor--l {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eui-showFor--l {
        display: inline !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eui-showFor--l--block {
        display: block !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eui-showFor--l--inlineBlock {
        display: inline-block !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eui-showFor--l--flex {
        display: flex !important;
    }
}

@media only screen and (min-width: 1200px) {
    .eui-hideFor--xl {
        display: none !important;
    }
}

@media only screen and (min-width: 1200px) {
    .eui-showFor--xl {
        display: inline !important;
    }
}

@media only screen and (min-width: 1200px) {
    .eui-showFor--xl--block {
        display: block !important;
    }
}

@media only screen and (min-width: 1200px) {
    .eui-showFor--xl--inlineBlock {
        display: inline-block !important;
    }
}

@media only screen and (min-width: 1200px) {
    .eui-showFor--xl--flex {
        display: flex !important;
    }
}

/**
  * IE doesn't properly wrap groups if it is within a flex-item of a flex-group.
  * Adding the following styles to the flex-item that contains the wrapping group, will fix IE.
  * https://github.com/philipwalton/flexbugs/issues/104
  */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiIEFlexWrapFix {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0%;
    }
}

/**
  * Overflow scrolling
  */
.eui-yScroll {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.eui-yScroll::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.eui-yScroll::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.eui-yScroll::-webkit-scrollbar-corner,
.eui-yScroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.eui-yScroll:focus {
    outline: none;
    /* 1 */
}

.eui-xScroll {
    scrollbar-width: thin;
    overflow-x: auto;
}

.eui-xScroll::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.eui-xScroll::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.eui-xScroll::-webkit-scrollbar-corner,
.eui-xScroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.eui-xScroll:focus {
    outline: none;
    /* 1 */
}

.eui-yScrollWithShadows::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.eui-yScrollWithShadows::-webkit-scrollbar-corner,
.eui-yScrollWithShadows::-webkit-scrollbar-track {
    background-color: transparent;
}

.eui-yScrollWithShadows:focus {
    outline: none;
    /* 1 */
}

.eui-yScrollWithShadows::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.eui-xScrollWithShadows::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.eui-xScrollWithShadows::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.eui-xScrollWithShadows::-webkit-scrollbar-corner,
.eui-xScrollWithShadows::-webkit-scrollbar-track {
    background-color: transparent;
}

.eui-xScrollWithShadows:focus {
    outline: none;
    /* 1 */
}

.euiYScrollWithShadows::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiYScrollWithShadows::-webkit-scrollbar-corner,
.euiYScrollWithShadows::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiYScrollWithShadows:focus {
    outline: none;
    /* 1 */
}

/**
  * Forcing focus ring on non-EUI elements
  */
.eui-isFocusable:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
}

/**
 * Adapted from Eric Meyer's reset (http://meyerweb.com/eric/tools/css/reset/, v2.0 | 20110126).
 *
 */
*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: none;
    vertical-align: baseline;
}

code,
pre,
kbd,
samp {
    font-family: 'Roboto Mono', Consolas, Menlo, Courier, monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

input,
textarea,
select,
button {
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 16px;
    color: #343741;
    height: 100%;
    background-color: #fafbfd;
}

body {
    line-height: 1;
}

*:focus {
    outline: none;
}

*:focus::-moz-focus-inner {
    border: none;
}

*:focus:-moz-focusring {
    outline: none;
}

a:hover {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
    outline: none;
}

a:hover,
button,
[role='button'] {
    cursor: pointer;
}

input {
    margin: 0;
    padding: 0;
}

input:disabled {
    opacity: 1;
    /* required on iOS */
}

button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    font-size: inherit;
    color: inherit;
    border-radius: 0;
}

button:hover {
    cursor: pointer;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    margin: 0;
}

fieldset {
    min-inline-size: auto;
}

svg text {
    letter-spacing: normal !important;
}

html {
    scrollbar-width: thin;
    scrollbar-color: rgba(105, 112, 125, 0.5) transparent;
}

.euiScreenReaderOnly,
.euiScreenReaderOnly--showOnFocus:not(:focus):not(:active) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.euiSkipLink {
    transition: none !important;
}

.euiSkipLink:focus {
    animation: none !important;
}

.euiSkipLink.euiSkipLink--absolute:focus {
    position: absolute;
}

.euiSkipLink.euiSkipLink--fixed:focus {
    position: fixed;
    top: 4px;
    left: 4px;
    z-index: 1001;
}

.euiAccordion__triggerWrapper {
    display: flex;
    align-items: center;
}

.euiAccordion__button {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.euiAccordion__button:hover,
.euiAccordion__button:focus {
    text-decoration: underline;
    cursor: pointer;
}

.euiAccordion__buttonReverse {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.euiAccordion__buttonReverse .euiAccordion__iconWrapper {
    margin-left: 8px;
    margin-right: 4px;
}

.euiAccordion__iconWrapper {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-right: 8px;
    border-radius: 4px;
    flex-shrink: 0;
}

.euiAccordion__iconWrapper .euiAccordion__icon {
    vertical-align: top;
    transition: transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiAccordion__iconWrapper .euiAccordion__icon-isOpen {
    transform: rotate(90deg);
}

.euiAccordion__iconButton {
    margin-left: 8px;
    margin-right: 4px;
}

.euiAccordion__optionalAction {
    flex-shrink: 0;
}

.euiAccordion__childWrapper {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transform: translatez(0);
    transition: height 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiAccordion__padding--xs {
    padding: 4px;
}

.euiAccordion__padding--s {
    padding: 8px;
}

.euiAccordion__padding--m {
    padding: 16px;
}

.euiAccordion__padding--l {
    padding: 24px;
}

.euiAccordion__padding--xl {
    padding: 32px;
}

.euiAccordion.euiAccordion-isOpen .euiAccordion__childWrapper {
    visibility: visible;
    opacity: 1;
    height: auto;
}

.euiAccordion__children-isLoading {
    line-height: 1.5;
    display: flex;
    align-items: center;
}

.euiAccordion__children-isLoading .euiAccordion__spinner {
    margin-right: 4px;
}

.euiAccordionForm__extraAction {
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiAccordionForm__extraAction:focus {
    opacity: 1;
}

.euiAccordionForm__title {
    display: inline-block;
}

.euiAccordionForm__button {
    padding: 16px 16px 16px 0;
}

.euiAccordionForm__button:hover {
    text-decoration: none;
}

.euiAccordionForm__button:hover .euiAccordionForm__title {
    text-decoration: underline;
}

.euiAccordionForm {
    border-top: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
}

.euiAccordionForm + .euiAccordionForm {
    border-top: none;
}

.euiAccordionForm:hover .euiAccordionForm__extraAction {
    opacity: 1;
    visibility: visible;
}

.euiAspectRatio {
    position: relative;
}

.euiAspectRatio > * {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.euiAvatar {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    text-align: center;
    vertical-align: middle;
    overflow-x: hidden;
    font-weight: 500;
}

.euiAvatar--user {
    position: relative;
    border-radius: 50%;
}

.euiAvatar--user:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    content: '';
    pointer-events: none;
    border: 1px solid rgba(52, 55, 65, 0.05);
}

.euiAvatar--space {
    position: relative;
    border-radius: 4px;
}

.euiAvatar--space:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    content: '';
    pointer-events: none;
    border: 1px solid rgba(52, 55, 65, 0.05);
}

.euiAvatar-isDisabled {
    cursor: not-allowed;
    filter: grayscale(100%);
}

.euiAvatar--plain {
    background-color: #fff;
}

.euiAvatar--s {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
}

.euiAvatar--m {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 14.4px;
}

.euiAvatar--l {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 19.2px;
}

.euiAvatar--xl {
    width: 64px;
    height: 64px;
    line-height: 64px;
    font-size: 25.6px;
}

/**
 * 1. Accounts for the border
 */
.euiBadge {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    /* 1 */
    padding: 0 8px;
    display: inline-block;
    text-decoration: none;
    border-radius: 2px;
    border: solid 1px transparent;
    background-color: transparent;
    white-space: nowrap;
    vertical-align: middle;
    cursor: default;
    max-width: 100%;
    text-align: left;
}

.euiBadge.euiBadge-isDisabled {
    color: #88888b !important;
    background-color: #c2c3c6 !important;
}

.euiBadge:focus-within {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiBadge + .euiBadge {
    margin-left: 4px;
}

.euiBadge .euiBadge__content {
    min-height: 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.euiBadge .euiBadge__childButton {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    flex: 1 1 auto;
    text-align: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
}

.euiBadge .euiBadge__childButton:disabled {
    cursor: not-allowed;
}

.euiBadge .euiBadge__childButton:not(:disabled):hover,
.euiBadge .euiBadge__childButton:not(:disabled):focus {
    text-decoration: underline;
}

.euiBadge .euiBadge__iconButton {
    flex: 0 0 auto;
    font-size: 0;
    margin-left: 4px;
}

.euiBadge .euiBadge__iconButton:focus {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    border-radius: 2px;
}

.euiBadge .euiBadge__iconButton:disabled {
    cursor: not-allowed;
}

.euiBadge .euiBadge__iconButton .euiBadge__icon {
    margin: 0 !important;
}

.euiBadge .euiBadge__text {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    flex: 1 1 auto;
    cursor: default;
}

.euiBadge .euiBadge__icon {
    flex: 0 0 auto;
}

.euiBadge .euiBadge__icon:not(:only-child) {
    margin-left: 4px;
}

.euiBadge.euiBadge--iconLeft .euiBadge__content {
    flex-direction: row-reverse;
}

.euiBadge.euiBadge--iconLeft .euiBadge__iconButton,
.euiBadge.euiBadge--iconLeft .euiBadge__icon:not(:only-child) {
    margin-right: 4px;
    margin-left: 0;
}

.euiBadge-isClickable:not(:disabled):hover,
.euiBadge-isClickable:not(:disabled):focus {
    text-decoration: underline;
}

.euiBadge-isClickable.euiBadge-isDisabled {
    cursor: not-allowed;
}

.euiBadge-isClickable:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiBadge-isClickable .euiBadge__text {
    cursor: inherit;
}

.euiBadge--hollow {
    background-color: #fff;
    border-color: #d3dae6;
    color: #343741;
}

.euiBadgeGroup__item {
    display: inline-block;
    max-width: 100%;
}

.euiBadgeGroup--gutterExtraSmall {
    margin: -2px;
}

.euiBadgeGroup--gutterExtraSmall > .euiBadgeGroup__item {
    margin: 2px;
    max-width: calc(100% - 4px);
}

.euiBadgeGroup--gutterSmall {
    margin: -4px;
}

.euiBadgeGroup--gutterSmall > .euiBadgeGroup__item {
    margin: 4px;
    max-width: calc(100% - 8px);
}

.euiBetaBadge {
    display: inline-block;
    padding: 0 16px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 1px #d3dae6;
    vertical-align: super;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    cursor: default;
}

.euiBetaBadge:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiBetaBadge--iconOnly {
    padding: 0;
    width: 24px;
}

.euiBetaBadge--iconOnly .euiBetaBadge__icon {
    position: relative;
    margin-top: -1px;
}

.euiNotificationBadge {
    flex-shrink: 0;
    display: inline-block;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    height: 16px;
    min-width: 16px;
    padding-left: 4px;
    padding-right: 4px;
    vertical-align: middle;
    text-align: center;
    transition: all 150ms ease-in;
    cursor: default;
    background: #dd0a73;
    color: #fff;
}

.euiNotificationBadge--medium {
    line-height: 20px;
    height: 20px;
    min-width: 24px;
}

.euiNotificationBadge--subdued {
    background: #e0e5ee;
    color: #000;
}

.euiBasicTable-loading {
    position: relative;
}

.euiBasicTable-loading tbody {
    overflow: hidden;
}

@keyframes euiBasicTableLoading {
    from {
        left: 0;
        width: 0;
    }
    20% {
        left: 0;
        width: 40%;
    }
    80% {
        left: 60%;
        width: 40%;
    }
    100% {
        left: 100%;
        width: 0;
    }
}

.euiBeacon {
    position: relative;
    background-color: #54b399;
    border-radius: 50%;
}

.euiBeacon:before,
.euiBeacon:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #54b399;
}

.euiBeacon:before {
    animation: euiBeaconPulseLarge 2.5s infinite ease-out;
}

.euiBeacon:after {
    animation: euiBeaconPulseSmall 2.5s infinite ease-out 0.25s;
}

@keyframes euiBeaconPulseLarge {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    70% {
        transform: scale(3);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes euiBeaconPulseSmall {
    0% {
        transform: scale(0.1);
        opacity: 1;
    }
    70% {
        transform: scale(2);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.euiBottomBar {
    box-shadow: 0 0 12px -1px rgba(65, 78, 101, 0.2), 0 0 4px -1px rgba(65, 78, 101, 0.2),
        0 0 2px 0 rgba(65, 78, 101, 0.2);
    background: #404040;
    color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    animation: euiBottomBarAppear 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    z-index: 998;
}

.euiBottomBar.euiBottomBar--paddingSmall {
    padding: 8px;
}

.euiBottomBar.euiBottomBar--paddingMedium {
    padding: 16px;
}

.euiBottomBar.euiBottomBar--paddingLarge {
    padding: 24px;
}

@keyframes euiBottomBarAppear {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

.euiButton {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: none;
    border: solid 1px transparent;
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    border-radius: 4px;
    min-width: 112px;
}

@media screen and (prefers-reduced-motion: no-preference) {
    .euiButton {
        transition: transform 250ms ease-in-out, background 250ms ease-in-out;
    }

    .euiButton:hover:not([class*='isDisabled']) {
        transform: translateY(-1px);
    }

    .euiButton:focus {
        animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
    }

    .euiButton:active:not([class*='isDisabled']) {
        transform: translateY(1px);
    }
}

.euiButton:hover:not([class*='isDisabled']),
.euiButton:focus {
    text-decoration: underline;
}

.euiButton .euiButton__content {
    padding: 0 12px;
}

.euiButton .euiButton__text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.euiButton.euiButton--small {
    height: 32px;
    line-height: 32px;
}

.euiButton:hover,
.euiButton:active {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
}

.euiButton:not([class*='isDisabled']):hover,
.euiButton:not([class*='isDisabled']):focus,
.euiButton:not([class*='isDisabled']):focus-within {
    background-color: rgba(207, 31, 47, 0.1);
}

.euiButton.euiButton-isDisabled {
    pointer-events: auto;
    cursor: not-allowed;
    color: #afb0b3;
    border-color: #c2c3c6;
}

.euiButton.euiButton-isDisabled .euiButtonContent__icon {
    fill: currentColor;
}

.euiButton.euiButton-isDisabled.euiButton--fill {
    color: #88888b;
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButton.euiButton-isDisabled.euiButton--fill:hover,
.euiButton.euiButton-isDisabled.euiButton--fill:focus,
.euiButton.euiButton-isDisabled.euiButton--fill:focus-within {
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButton.euiButton-isDisabled:hover,
.euiButton.euiButton-isDisabled:focus,
.euiButton.euiButton-isDisabled:focus-within {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    text-decoration: none;
}

.euiButton--primary:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(136, 102, 105, 0.3);
}

.euiButton--primary:not([class*='isDisabled']):hover,
.euiButton--primary:not([class*='isDisabled']):focus,
.euiButton--primary:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(136, 102, 105, 0.15), 0 2px 2px -1px rgba(136, 102, 105, 0.3);
    background-color: rgba(207, 31, 47, 0.1);
}

.euiButton--secondary {
    color: #017d73;
    border-color: #017d73;
}

.euiButton--secondary.euiButton--fill {
    background-color: #017d73;
    border-color: #017d73;
    color: #fff;
}

.euiButton--secondary.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--secondary.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--secondary.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #01645c;
    border-color: #01645c;
}

.euiButton--secondary:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(39, 87, 83, 0.3);
}

.euiButton--secondary:not([class*='isDisabled']):hover,
.euiButton--secondary:not([class*='isDisabled']):focus,
.euiButton--secondary:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(39, 87, 83, 0.15), 0 2px 2px -1px rgba(39, 87, 83, 0.3);
    background-color: rgba(1, 125, 115, 0.1);
}

.euiButton--warning {
    color: #9b6900;
    border-color: #f5a700;
}

.euiButton--warning.euiButton--fill {
    background-color: #f5a700;
    border-color: #f5a700;
    color: #000;
}

.euiButton--warning.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--warning.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--warning.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #dc9600;
    border-color: #dc9600;
}

.euiButton--warning:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(172, 140, 74, 0.3);
}

.euiButton--warning:not([class*='isDisabled']):hover,
.euiButton--warning:not([class*='isDisabled']):focus,
.euiButton--warning:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(172, 140, 74, 0.15), 0 2px 2px -1px rgba(172, 140, 74, 0.3);
    background-color: rgba(245, 167, 0, 0.1);
}

.euiButton--danger {
    color: #bd271e;
    border-color: #bd271e;
}

.euiButton--danger.euiButton--fill {
    background-color: #bd271e;
    border-color: #bd271e;
    color: #fff;
}

.euiButton--danger.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--danger.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--danger.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #a7221b;
    border-color: #a7221b;
}

.euiButton--danger:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(123, 97, 96, 0.3);
}

.euiButton--danger:not([class*='isDisabled']):hover,
.euiButton--danger:not([class*='isDisabled']):focus,
.euiButton--danger:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(123, 97, 96, 0.15), 0 2px 2px -1px rgba(123, 97, 96, 0.3);
    background-color: rgba(189, 39, 30, 0.1);
}

.euiButton--ghost {
    color: #fff;
    border-color: #fff;
}

.euiButton--ghost.euiButton--fill {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.euiButton--ghost.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--ghost.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--ghost.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
}

.euiButton--ghost:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
}

.euiButton--ghost:not([class*='isDisabled']):hover,
.euiButton--ghost:not([class*='isDisabled']):focus,
.euiButton--ghost:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 2px 2px -1px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
}

.euiButton--text {
    color: #343741;
    border-color: #69707d;
}

.euiButton--text.euiButton--fill {
    background-color: #69707d;
    border-color: #69707d;
    color: #fff;
}

.euiButton--text.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--text.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--text.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #5d646f;
    border-color: #5d646f;
}

.euiButton--text:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(115, 115, 115, 0.3);
}

.euiButton--text:not([class*='isDisabled']):hover,
.euiButton--text:not([class*='isDisabled']):focus,
.euiButton--text:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(115, 115, 115, 0.15), 0 2px 2px -1px rgba(115, 115, 115, 0.3);
    background-color: rgba(105, 112, 125, 0.1);
}

.euiButton.euiButton-isDisabled.euiButton--ghost,
.euiButton.euiButton-isDisabled.euiButton--ghost:hover,
.euiButton.euiButton-isDisabled.euiButton--ghost:focus,
.euiButton.euiButton-isDisabled.euiButton--ghost:focus-within {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
    color: #69707d;
    border-color: #69707d;
}

.euiButton.euiButton-isDisabled.euiButton--ghost.euiButton--fill {
    background-color: #69707d;
    color: #a1a5ae;
}

.euiButton--fullWidth {
    display: block;
    width: 100%;
}

.euiButtonContent {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.euiButtonContent .euiButtonContent__icon,
.euiButtonContent .euiButtonContent__spinner {
    flex-shrink: 0;
}

.euiButtonContent > * + * {
    margin-inline-start: 8px;
}

.euiButtonContent--iconRight {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    flex-direction: row-reverse;
}

.euiButtonContent--iconRight .euiButtonContent__icon,
.euiButtonContent--iconRight .euiButtonContent__spinner {
    flex-shrink: 0;
}

.euiButtonContent--iconRight > * + * {
    margin-inline-start: 0;
    margin-inline-end: 8px;
}

/**
 * 1. We don't want any of the animations that come inherited from the mixin.
 *    These should act like normal links instead.
 * 2. Change the easing, quickness to not bounce so lighter backgrounds don't flash
 */
.euiButtonEmpty {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: none;
    border: solid 1px transparent;
    border-color: transparent;
    background-color: transparent;
    box-shadow: none;
    transform: none !important;
    /* 1 */
    animation: none !important;
    /* 1 */
    transition-timing-function: ease-in;
    /* 2 */
    transition-duration: 150ms;
    /* 2 */
}

@media screen and (prefers-reduced-motion: no-preference) {
    .euiButtonEmpty {
        transition: transform 250ms ease-in-out, background 250ms ease-in-out;
    }

    .euiButtonEmpty:hover:not([class*='isDisabled']) {
        transform: translateY(-1px);
    }

    .euiButtonEmpty:focus {
        animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
    }

    .euiButtonEmpty:active:not([class*='isDisabled']) {
        transform: translateY(1px);
    }
}

.euiButtonEmpty:hover:not([class*='isDisabled']),
.euiButtonEmpty:focus {
    text-decoration: underline;
}

.euiButtonEmpty .euiButtonEmpty__content {
    padding: 0 8px;
}

.euiButtonEmpty .euiButtonEmpty__text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.euiButtonEmpty.euiButtonEmpty--small {
    height: 32px;
}

.euiButtonEmpty.euiButtonEmpty--xSmall {
    height: 24px;
    font-size: 14px;
}

.euiButtonEmpty:disabled {
    pointer-events: auto;
    cursor: not-allowed;
    color: #afb0b3;
}

.euiButtonEmpty:disabled .euiButtonContent__icon {
    fill: currentColor;
}

.euiButtonEmpty:disabled:focus {
    background-color: transparent;
}

.euiButtonEmpty:disabled:hover,
.euiButtonEmpty:disabled:focus {
    text-decoration: none;
}

.euiButtonEmpty--flushLeft .euiButtonEmpty__content,
.euiButtonEmpty--flushRight .euiButtonEmpty__content,
.euiButtonEmpty--flushBoth .euiButtonEmpty__content {
    padding-left: 0;
    padding-right: 0;
}

.euiButtonEmpty--flushLeft {
    margin-right: 8px;
}

.euiButtonEmpty--flushRight {
    margin-left: 8px;
}

.euiButtonEmpty--danger:focus {
    background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonEmpty--disabled {
    color: #757577;
}

.euiButtonEmpty--disabled:focus {
    background-color: rgba(175, 176, 179, 0.1);
}

.euiButtonEmpty--disabled:hover {
    cursor: not-allowed;
}

.euiButtonEmpty--ghost {
    color: #fff;
}

.euiButtonEmpty--ghost:focus {
    background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonEmpty--text {
    color: #343741;
}

.euiButtonEmpty--text:focus {
    background-color: rgba(52, 55, 65, 0.1);
}

.euiButtonEmpty--success {
    color: #017d73;
}

.euiButtonEmpty--success:focus {
    background-color: rgba(1, 125, 115, 0.1);
}

.euiButtonEmpty--warning {
    color: #9b6900;
}

.euiButtonEmpty--warning:focus {
    background-color: rgba(155, 105, 0, 0.1);
}

.euiButtonIcon {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    text-decoration: none;
    border: solid 1px transparent;
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    border-radius: 4px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
}

@media screen and (prefers-reduced-motion: no-preference) {
    .euiButtonIcon {
        transition: transform 250ms ease-in-out, background 250ms ease-in-out;
    }

    .euiButtonIcon:hover:not([class*='isDisabled']) {
        transform: translateY(-1px);
    }

    .euiButtonIcon:focus {
        animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
    }

    .euiButtonIcon:active:not([class*='isDisabled']) {
        transform: translateY(1px);
    }
}

.euiButtonIcon:hover:not([class*='isDisabled']),
.euiButtonIcon:focus {
    text-decoration: underline;
}

.euiButtonIcon > svg {
    pointer-events: none;
}

.euiButtonIcon.euiButtonIcon--empty {
    box-shadow: none !important;
    border: none;
}

.euiButtonIcon.euiButtonIcon-isDisabled {
    pointer-events: auto;
    cursor: not-allowed;
    color: #afb0b3;
    border-color: #c2c3c6;
}

.euiButtonIcon.euiButtonIcon-isDisabled .euiButtonContent__icon {
    fill: currentColor;
}

.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--fill {
    color: #88888b;
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--fill:hover,
.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--fill:focus,
.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--fill:focus-within {
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButtonIcon.euiButtonIcon-isDisabled:hover,
.euiButtonIcon.euiButtonIcon-isDisabled:focus,
.euiButtonIcon.euiButtonIcon-isDisabled:focus-within {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    text-decoration: none;
}

.euiButtonIcon--xSmall {
    height: 24px;
    width: 24px;
}

.euiButtonIcon--small {
    height: 32px;
    width: 32px;
}

.euiButtonIcon--accent {
    color: #dd0a73;
    border-color: #dd0a73;
}

.euiButtonIcon--accent.euiButtonIcon--fill {
    background-color: #dd0a73;
    border-color: #dd0a73;
    color: #fff;
}

.euiButtonIcon--accent.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--accent.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--accent.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #c50966;
    border-color: #c50966;
}

.euiButtonIcon--accent:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(152, 79, 115, 0.3);
}

.euiButtonIcon--accent:not([class*='isDisabled']):hover,
.euiButtonIcon--accent:not([class*='isDisabled']):focus,
.euiButtonIcon--accent:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(152, 79, 115, 0.15), 0 2px 2px -1px rgba(152, 79, 115, 0.3);
    background-color: rgba(221, 10, 115, 0.1);
}

.euiButtonIcon--danger {
    color: #bd271e;
    border-color: #bd271e;
}

.euiButtonIcon--danger.euiButtonIcon--fill {
    background-color: #bd271e;
    border-color: #bd271e;
    color: #fff;
}

.euiButtonIcon--danger.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--danger.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--danger.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #a7221b;
    border-color: #a7221b;
}

.euiButtonIcon--danger:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(123, 97, 96, 0.3);
}

.euiButtonIcon--danger:not([class*='isDisabled']):hover,
.euiButtonIcon--danger:not([class*='isDisabled']):focus,
.euiButtonIcon--danger:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(123, 97, 96, 0.15), 0 2px 2px -1px rgba(123, 97, 96, 0.3);
    background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonIcon--ghost {
    color: #fff;
    border-color: #fff;
}

.euiButtonIcon--ghost.euiButtonIcon--fill {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.euiButtonIcon--ghost.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--ghost.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--ghost.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
}

.euiButtonIcon--ghost:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
}

.euiButtonIcon--ghost:not([class*='isDisabled']):hover,
.euiButtonIcon--ghost:not([class*='isDisabled']):focus,
.euiButtonIcon--ghost:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 2px 2px -1px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonIcon--primary:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(136, 102, 105, 0.3);
}

.euiButtonIcon--primary:not([class*='isDisabled']):hover,
.euiButtonIcon--primary:not([class*='isDisabled']):focus,
.euiButtonIcon--primary:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(136, 102, 105, 0.15), 0 2px 2px -1px rgba(136, 102, 105, 0.3);
    background-color: rgba(207, 31, 47, 0.1);
}

.euiButtonIcon--subdued {
    color: #6a717d;
    border-color: #6a717d;
}

.euiButtonIcon--subdued.euiButtonIcon--fill {
    background-color: #6a717d;
    border-color: #6a717d;
    color: #fff;
}

.euiButtonIcon--subdued.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--subdued.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--subdued.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #5e656f;
    border-color: #5e656f;
}

.euiButtonIcon--subdued:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(116, 116, 116, 0.3);
}

.euiButtonIcon--subdued:not([class*='isDisabled']):hover,
.euiButtonIcon--subdued:not([class*='isDisabled']):focus,
.euiButtonIcon--subdued:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(116, 116, 116, 0.15), 0 2px 2px -1px rgba(116, 116, 116, 0.3);
    background-color: rgba(106, 113, 125, 0.1);
}

.euiButtonIcon--success {
    color: #017d73;
    border-color: #017d73;
}

.euiButtonIcon--success.euiButtonIcon--fill {
    background-color: #017d73;
    border-color: #017d73;
    color: #fff;
}

.euiButtonIcon--success.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--success.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--success.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #01645c;
    border-color: #01645c;
}

.euiButtonIcon--success:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(39, 87, 83, 0.3);
}

.euiButtonIcon--success:not([class*='isDisabled']):hover,
.euiButtonIcon--success:not([class*='isDisabled']):focus,
.euiButtonIcon--success:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(39, 87, 83, 0.15), 0 2px 2px -1px rgba(39, 87, 83, 0.3);
    background-color: rgba(1, 125, 115, 0.1);
}

.euiButtonIcon--text {
    color: #343741;
    border-color: #69707d;
}

.euiButtonIcon--text.euiButtonIcon--fill {
    background-color: #69707d;
    border-color: #69707d;
    color: #fff;
}

.euiButtonIcon--text.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--text.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--text.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #5d646f;
    border-color: #5d646f;
}

.euiButtonIcon--text:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(115, 115, 115, 0.3);
}

.euiButtonIcon--text:not([class*='isDisabled']):hover,
.euiButtonIcon--text:not([class*='isDisabled']):focus,
.euiButtonIcon--text:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(115, 115, 115, 0.15), 0 2px 2px -1px rgba(115, 115, 115, 0.3);
    background-color: rgba(105, 112, 125, 0.1);
}

.euiButtonIcon--warning {
    color: #9b6900;
    border-color: #9b6900;
}

.euiButtonIcon--warning.euiButtonIcon--fill {
    background-color: #9b6900;
    border-color: #9b6900;
    color: #fff;
}

.euiButtonIcon--warning.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--warning.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--warning.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #825800;
    border-color: #825800;
}

.euiButtonIcon--warning:not([class*='isDisabled']) {
    box-shadow: 0 2px 2px -1px rgba(109, 89, 47, 0.3);
}

.euiButtonIcon--warning:not([class*='isDisabled']):hover,
.euiButtonIcon--warning:not([class*='isDisabled']):focus,
.euiButtonIcon--warning:not([class*='isDisabled']):focus-within {
    box-shadow: 0 4px 8px 0 rgba(109, 89, 47, 0.15), 0 2px 2px -1px rgba(109, 89, 47, 0.3);
    background-color: rgba(155, 105, 0, 0.1);
}

.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--ghost,
.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--ghost:hover,
.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--ghost:focus,
.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--ghost:focus-within {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
    color: #69707d;
    border-color: #69707d;
}

.euiButtonIcon.euiButtonIcon-isDisabled.euiButtonIcon--ghost.euiButton--fill {
    background-color: #69707d;
    color: #a1a5ae;
}

.euiButtonGroup {
    display: inline-block;
    max-width: 100%;
    position: relative;
}

.euiButtonGroup--fullWidth {
    display: block;
}

.euiButtonGroup--fullWidth .euiButtonGroup__buttons {
    width: 100%;
}

.euiButtonGroup--fullWidth .euiButtonGroup__buttons .euiButtonGroupButton {
    flex: 1;
}

.euiButtonGroup__buttons {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    border-radius: 5px;
    max-width: 100%;
    display: flex;
    overflow: hidden;
}

.euiButtonGroup--isDisabled .euiButtonGroup__buttons {
    box-shadow: none;
}

.euiButtonGroup--compressed .euiButtonGroup__buttons {
    box-shadow: none !important;
    border-radius: 2px;
    background-color: #fbfcfd;
    height: 32px;
    border: 1px solid rgba(133, 68, 43, 0.1);
    overflow: visible;
}

.euiButtonGroupButton {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out,
        color 250ms ease-in-out;
    min-width: 0;
    flex-shrink: 1;
    flex-grow: 0;
}

.euiButtonGroupButton .euiButton__content {
    padding: 0 12px;
}

.euiButtonGroupButton-isIconOnly .euiButton__content {
    padding: 0 8px;
}

.euiButtonGroupButton .euiButton__text {
    text-overflow: ellipsis;
    overflow: hidden;
}

.euiButtonGroupButton.euiButtonGroupButton--small {
    height: 32px;
    line-height: 32px;
}

.euiButtonGroupButton:not([class*='isDisabled']):hover,
.euiButtonGroupButton:not([class*='isDisabled']):focus,
.euiButtonGroupButton:not([class*='isDisabled']):focus-within {
    background-color: rgba(207, 31, 47, 0.1);
    text-decoration: underline;
}

.euiButtonGroupButton.euiButtonGroupButton-isDisabled {
    pointer-events: auto;
    cursor: not-allowed;
    color: #afb0b3;
}

.euiButtonGroupButton.euiButtonGroupButton-isDisabled .euiButtonContent__icon {
    fill: currentColor;
}

.euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton-isSelected {
    color: #88888b;
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton-isSelected:focus-within {
    background-color: #c2c3c6;
    border-color: #c2c3c6;
}

.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):focus-within {
    background-color: rgba(207, 31, 47, 0.1);
}

.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']) {
    color: #017d73;
}

.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #017d73;
    border-color: #017d73;
    color: #fff;
}

.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #01645c;
    border-color: #01645c;
}

.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--secondary:not([class*='isDisabled']):focus-within {
    background-color: rgba(1, 125, 115, 0.1);
}

.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']) {
    color: #9b6900;
}

.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #f5a700;
    border-color: #f5a700;
    color: #000;
}

.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #dc9600;
    border-color: #dc9600;
}

.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--warning:not([class*='isDisabled']):focus-within {
    background-color: rgba(245, 167, 0, 0.1);
}

.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']) {
    color: #bd271e;
}

.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #bd271e;
    border-color: #bd271e;
    color: #fff;
}

.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #a7221b;
    border-color: #a7221b;
}

.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--danger:not([class*='isDisabled']):focus-within {
    background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']) {
    color: #fff;
}

.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
}

.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--ghost:not([class*='isDisabled']):focus-within {
    background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']) {
    color: #343741;
}

.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #69707d;
    border-color: #69707d;
    color: #fff;
}

.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #5d646f;
    border-color: #5d646f;
}

.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']):focus-within {
    background-color: rgba(105, 112, 125, 0.1);
}

.euiButtonGroupButton__textShift::after {
    display: block;
    content: attr(data-text);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/**
 * Medium and Small sizing (regular button style)
 */
.euiButtonGroup--medium .euiButtonGroupButton,
.euiButtonGroup--small .euiButtonGroupButton {
    border: 1px solid #d3dae6;
}

.euiButtonGroup--medium .euiButtonGroupButton:not(:first-child),
.euiButtonGroup--small .euiButtonGroupButton:not(:first-child) {
    margin-left: -1px;
}

.euiButtonGroup--medium .euiButtonGroupButton:first-child,
.euiButtonGroup--small .euiButtonGroupButton:first-child {
    border-radius: 4px 0 0 4px;
}

.euiButtonGroup--medium .euiButtonGroupButton:last-child,
.euiButtonGroup--small .euiButtonGroupButton:last-child {
    border-radius: 0 4px 4px 0;
}

.euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost,
.euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:hover,
.euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:focus,
.euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:focus-within,
.euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost,
.euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:hover,
.euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:focus,
.euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost:focus-within {
    color: #69707d;
}

.euiButtonGroup--isDisabled
    .euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost,
.euiButtonGroup--isDisabled
    .euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost {
    border-color: #69707d;
}

.euiButtonGroup--medium
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost.euiButtonGroupButton-isSelected,
.euiButtonGroup--small
    .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton--ghost.euiButtonGroupButton-isSelected {
    background-color: #69707d;
    color: #a1a5ae;
}

.euiButtonGroup--medium .euiButtonGroupButton-isSelected,
.euiButtonGroup--small .euiButtonGroupButton-isSelected {
    z-index: 0;
}

.euiButtonGroup--medium .euiButtonGroupButton-isSelected + .euiButtonGroupButton-isSelected,
.euiButtonGroup--small .euiButtonGroupButton-isSelected + .euiButtonGroupButton-isSelected {
    box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}

/**
 * Compressed (form style)
 */
.euiButtonGroup--compressed .euiButtonGroupButton {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    border-radius: 4px;
    padding: 2px;
    background-clip: content-box;
}

.euiButtonGroup--compressed .euiButtonGroupButton .euiButton__content {
    padding-left: 8px;
    padding-right: 8px;
}

.euiButtonGroup--compressed .euiButtonGroupButton.euiButtonGroupButton-isSelected {
    font-weight: 600;
}

/**
 * 1. Add vertical space between breadcrumbs,
 *    but make sure the whole breadcrumb set doesn't add space below itself
 */
.euiBreadcrumbs {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: -4px;
    /* 1 */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 0;
}

.euiBreadcrumb {
    display: inline-block;
    margin-bottom: 4px;
    /* 1 */
}

.euiBreadcrumb:not(.euiBreadcrumb--last) {
    margin-right: 8px;
    color: #6a717d;
}

.euiBreadcrumb--last {
    font-weight: 500;
}

.euiBreadcrumb--collapsed {
    flex-shrink: 0;
}

.euiBreadcrumbSeparator {
    flex-shrink: 0;
    display: inline-block;
    margin-right: 8px;
    width: 1px;
    height: 16px;
    transform: translateY(-1px) rotate(15deg);
    background: #d3dae6;
}

.euiBreadcrumbs__inPopover .euiBreadcrumb--last {
    font-weight: 400;
    color: #69707d !important;
}

.euiBreadcrumbs--truncate {
    white-space: nowrap;
    flex-wrap: nowrap;
}

.euiBreadcrumbs--truncate .euiBreadcrumb:not(.euiBreadcrumb--collapsed) {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.euiBreadcrumbs--truncate .euiBreadcrumb:not(.euiBreadcrumb--collapsed).euiBreadcrumb--last {
    max-width: none;
}

.euiBreadcrumb--truncate {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    max-width: 160px;
    text-align: center;
    vertical-align: top;
}

.euiCallOut {
    padding: 16px;
    border-left: 2px solid transparent;
}

.euiCallOut.euiCallOut--small {
    padding: 8px;
}

.euiCallOut .euiCallOutHeader__icon {
    flex: 0 0 auto;
    transform: translateY(2px);
}

.euiCallOut .euiCallOutHeader__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    font-weight: 400;
    margin-bottom: 0;
}

.euiCallOut--small .euiCallOutHeader__title {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    font-weight: 400;
}

.euiCallOut--success {
    border-color: #017d73;
    background-color: #e6f2f1;
}

.euiCallOut--success .euiCallOutHeader__icon {
    fill: #01776d;
}

.euiCallOut--success .euiCallOutHeader__title {
    color: #01776d;
}

.euiCallOut--warning {
    border-color: #f5a700;
    background-color: #fef6e6;
}

.euiCallOut--warning .euiCallOutHeader__icon {
    fill: #936400;
}

.euiCallOut--warning .euiCallOutHeader__title {
    color: #936400;
}

.euiCallOut--danger {
    border-color: #bd271e;
    background-color: #f8e9e9;
}

.euiCallOut--danger .euiCallOutHeader__icon {
    fill: #bd271e;
}

.euiCallOut--danger .euiCallOutHeader__title {
    color: #bd271e;
}

/**
 * 1. Align icon with first line of title text if it wraps.
 * 2. If content exists under the header, space it appropriately.
 * 3. Apply margin to all but last item in the flex.
 */
.euiCallOutHeader {
    display: flex;
    align-items: baseline;
    /* 1 */
}

.euiCallOutHeader + * {
    margin-top: 8px;
    /* 1 */
}

.euiCallOutHeader > * + * {
    margin-left: 8px;
    /* 3 */
}

.euiCard {
    background-color: #fff;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    flex-grow: 1;
}

.euiCard.euiCard--flexGrowZero {
    flex-grow: 0;
}

.euiCard.euiCard--noBorder {
    border: none;
}

.euiCard.euiCard--hasShadow {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    border: 1px solid #d3dae6;
}

.euiCard.euiCard--isClickable {
    display: block;
    width: 100%;
    text-align: left;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCard.euiCard--isClickable:hover,
.euiCard.euiCard--isClickable:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    transform: translateY(-2px);
    cursor: pointer;
}

.euiCard.euiCard--borderRadiusNone {
    border-radius: 0;
}

.euiCard.euiCard--borderRadiusMedium {
    border-radius: 4px;
}

.euiCard.euiCard--transparent {
    background-color: transparent;
}

.euiCard.euiCard--plain {
    background-color: #fff;
}

.euiCard.euiCard--subdued {
    background-color: #fafbfd;
}

.euiCard.euiCard--accent {
    background-color: #fce7f1;
}

.euiCard.euiCard--primary {
    background-color: #fae9ea;
}

.euiCard.euiCard--success {
    background-color: #e6f2f1;
}

.euiCard.euiCard--warning {
    background-color: #fef6e6;
}

.euiCard.euiCard--danger {
    background-color: #f8e9e9;
}

/**
 * 1. Footer is always at the bottom.
 * 2. Fix for IE where the image correctly resizes in width but doesn't collapse its height
      (https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421)
 * 3. Horizontal layouts should always top left align no matter the textAlign prop
 */
.euiCard {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    /* 2 */
}

.euiCard.euiCard-isDisabled {
    cursor: not-allowed;
    box-shadow: none;
}

.euiCard.euiCard-isDisabled:not(.euiCard--plain) {
    background-color: #f5f7fa;
}

.euiCard.euiCard-isDisabled .euiCard__title,
.euiCard.euiCard-isDisabled .euiCard__description,
.euiCard.euiCard-isDisabled .euiCard__top {
    color: #69707d;
}

.euiCard.euiCard-isDisabled .euiCard__top svg,
.euiCard.euiCard-isDisabled .euiCard__top img {
    filter: grayscale(100%);
}

.euiCard.euiCard-isDisabled .euiCard__titleAnchor,
.euiCard.euiCard-isDisabled .euiCard__titleButton {
    cursor: inherit;
}

.euiCard.euiCard-isDisabled:hover,
.euiCard.euiCard-isDisabled:focus {
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
    text-decoration: none;
}

.euiCard.euiCard--isClickable {
    display: flex;
}

.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus-within {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
}

.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__title,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__titleAnchor,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__titleButton,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__title,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__titleAnchor,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__titleButton {
    text-decoration: underline;
}

.euiCard .euiCard__top,
.euiCard .euiCard__content,
.euiCard .euiCard__footer {
    width: 100%;
}

.euiCard.euiCard--leftAligned {
    text-align: left;
    align-items: flex-start;
}

.euiCard.euiCard--leftAligned .euiCard__titleButton {
    text-align: left;
}

.euiCard.euiCard--centerAligned {
    text-align: center;
    align-items: center;
}

.euiCard.euiCard--rightAligned {
    text-align: right;
    align-items: flex-end;
}

.euiCard.euiCard--rightAligned .euiCard__titleButton {
    text-align: right;
}

.euiCard.euiCard-isSelected {
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCard--hasBetaBadge {
    position: relative;
    overflow: visible;
}

.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    min-width: 40%;
    max-width: calc(100% - 32px);
}

.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiToolTipAnchor,
.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiCard__betaBadge {
    width: 100%;
}

.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiCard__betaBadge {
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;
}

.euiCard--isSelectable {
    position: relative;
}

.euiCard--paddingNone {
    padding: 0;
}

.euiCard--paddingNone.euiCard--isSelectable {
    padding-bottom: 40px;
}

.euiCard--paddingNone .euiCard__top .euiCard__image {
    width: calc(100% + (0 * 2));
    left: 0;
    top: 0;
    margin-bottom: 0;
}

.euiCard--paddingNone .euiCard__top .euiCard__image + .euiCard__icon {
    transform: translate(-50%, -75%);
    transform: translate(-50%, calc(-50% + 0));
}

.euiCard--paddingSmall {
    padding: 8px;
}

.euiCard--paddingSmall.euiCard--isSelectable {
    padding-bottom: 48px;
}

.euiCard--paddingSmall .euiCard__top .euiCard__image {
    width: calc(100% + (8px * 2));
    left: -8px;
    top: -8px;
    margin-bottom: -8px;
}

.euiCard--paddingSmall .euiCard__top .euiCard__image + .euiCard__icon {
    transform: translate(-50%, -75%);
    transform: translate(-50%, calc(-50% + -8px));
}

.euiCard--paddingMedium {
    padding: 16px;
}

.euiCard--paddingMedium.euiCard--isSelectable {
    padding-bottom: 56px;
}

.euiCard--paddingMedium .euiCard__top .euiCard__image {
    width: calc(100% + (16px * 2));
    left: -16px;
    top: -16px;
    margin-bottom: -16px;
}

.euiCard--paddingMedium .euiCard__top .euiCard__image + .euiCard__icon {
    transform: translate(-50%, -75%);
    transform: translate(-50%, calc(-50% + -16px));
}

.euiCard--paddingLarge {
    padding: 24px;
}

.euiCard--paddingLarge.euiCard--isSelectable {
    padding-bottom: 64px;
}

.euiCard--paddingLarge .euiCard__top .euiCard__image {
    width: calc(100% + (24px * 2));
    left: -24px;
    top: -24px;
    margin-bottom: -24px;
}

.euiCard--paddingLarge .euiCard__top .euiCard__image + .euiCard__icon {
    transform: translate(-50%, -75%);
    transform: translate(-50%, calc(-50% + -24px));
}

.euiCard__top {
    flex-grow: 0;
    /* 1 */
    position: relative;
    min-height: 1px;
    /* 2 */
}

.euiCard__top .euiCard__image {
    position: relative;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    overflow: hidden;
}

.euiCard__top .euiCard__image img {
    width: 100%;
}

.euiCard__top .euiCard__image + .euiCard__icon {
    position: absolute;
    top: 50%;
    left: 50%;
}

.euiCard__top .euiCard__icon {
    margin-top: 8px;
}

.euiCard__footer:not(:empty) {
    flex-grow: 0;
    /* 1 */
    margin-top: 16px;
}

.euiCard--plain:not(.euiCard--isSelectable) {
    border: none;
    box-shadow: none;
}

.euiCard--plain:not(.euiCard--isSelectable) .euiCard__image {
    border-radius: 4px;
}

.euiCard--isSelectable--text.euiCard-isSelected {
    border-color: #017d73;
}

.euiCard--isSelectable--success.euiCard-isSelected {
    border-color: #017d73;
}

.euiCard--isSelectable--ghost.euiCard-isSelected {
    border-color: #69707d;
}

.euiCard__top + .euiCard__content {
    margin-top: 16px;
}

.euiCard__content {
    flex-grow: 1;
    /* 1 */
}

.euiCard__content .euiCard__description,
.euiCard__content .euiCard__children {
    margin-top: 8px;
}

.euiCard__content .euiCard__titleAnchor,
.euiCard__content .euiCard__titleButton {
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
}

.euiCard__content .euiCard__titleAnchor:focus,
.euiCard__content .euiCard__titleButton:focus {
    text-decoration: underline;
}

.euiCard.euiCard--horizontal .euiCard__content {
    text-align: left;
    /* 3 */
}

.euiCard.euiCard--horizontal.euiCard--hasIcon {
    flex-direction: row;
    align-items: flex-start !important;
    /* 3 */
}

.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__top,
.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__content {
    width: auto;
    margin-top: 0;
}

.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__top .euiCard__icon {
    margin-top: 0;
    margin-right: 16px;
}

.euiCardSelect {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px !important;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-weight: 700;
}

.euiCardSelect--text:enabled {
    background-color: #f5f7fa;
}

.euiCardSelect--primary:enabled {
    background-color: #fae9ea;
}

.euiCardSelect--success:enabled {
    background-color: #e6f2f1;
    color: #01776d;
}

.euiCardSelect--danger:enabled {
    background-color: #f8e9e9;
}

.euiCardSelect--ghost:enabled {
    background-color: #69707d;
}

.euiCardSelect:disabled {
    background-color: #fafbfd;
}

.euiCheckableCard {
    background-color: #fff;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    flex-grow: 1;
}

.euiCheckableCard.euiCheckableCard--flexGrowZero {
    flex-grow: 0;
}

.euiCheckableCard.euiCheckableCard--noBorder {
    border: none;
}

.euiCheckableCard.euiCheckableCard--hasShadow {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    border: 1px solid #d3dae6;
}

.euiCheckableCard.euiCheckableCard--isClickable {
    display: block;
    width: 100%;
    text-align: left;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCheckableCard.euiCheckableCard--isClickable:hover,
.euiCheckableCard.euiCheckableCard--isClickable:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    transform: translateY(-2px);
    cursor: pointer;
}

.euiCheckableCard.euiCheckableCard--borderRadiusNone {
    border-radius: 0;
}

.euiCheckableCard.euiCheckableCard--borderRadiusMedium {
    border-radius: 4px;
}

.euiCheckableCard.euiCheckableCard--transparent {
    background-color: transparent;
}

.euiCheckableCard.euiCheckableCard--plain {
    background-color: #fff;
}

.euiCheckableCard.euiCheckableCard--subdued {
    background-color: #fafbfd;
}

.euiCheckableCard.euiCheckableCard--accent {
    background-color: #fce7f1;
}

.euiCheckableCard.euiCheckableCard--primary {
    background-color: #fae9ea;
}

.euiCheckableCard.euiCheckableCard--success {
    background-color: #e6f2f1;
}

.euiCheckableCard.euiCheckableCard--warning {
    background-color: #fef6e6;
}

.euiCheckableCard.euiCheckableCard--danger {
    background-color: #f8e9e9;
}

.euiCheckableCard {
    transition: border-color 250ms ease-in;
    overflow: hidden;
}

.euiCheckableCard:not(.euiCheckableCard-isDisabled):focus-within {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiCheckableCard__row {
    display: flex;
    align-items: stretch;
}

.euiCheckableCard__control {
    display: flex;
    flex: 0 0 40px;
    justify-content: center;
    align-items: center;
    background-color: #f5f7fa;
    transition: background-color 250ms ease-in;
}

.euiCheckableCard__label {
    flex-grow: 1;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 8px 8px 16px;
    cursor: pointer;
}

.euiCheckableCard__label-isDisabled {
    color: #98a2b3;
    cursor: not-allowed;
}

.euiCheckableCard__children {
    padding: 0 8px 8px 16px;
}

.euiCodeBlock {
    max-width: 100%;
    display: block;
    position: relative;
    background: #f5f7fa;
    color: #343741;
    /*
     * 1. Size the code against the text its embedded within.
     */
}

.euiCodeBlock .euiCodeBlock__pre {
    scrollbar-width: thin;
    height: 100%;
    overflow: auto;
    display: block;
}

.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-corner,
.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiCodeBlock .euiCodeBlock__pre--whiteSpacePre {
    white-space: pre;
}

.euiCodeBlock .euiCodeBlock__pre--whiteSpacePreWrap {
    white-space: pre-wrap;
}

.euiCodeBlock .euiCodeBlock__code {
    font-family: 'Roboto Mono', Consolas, Menlo, Courier, monospace;
    letter-spacing: normal;
    display: block;
    line-height: 1.5;
    font-weight: 400;
    font-size: inherit;
}

.euiCodeBlock .euiCodeBlock__controls {
    position: absolute;
    top: 0;
    right: 0;
}

.euiCodeBlock .euiCodeBlock__fullScreenButton + .euiCodeBlock__copyButton {
    margin-top: 4px;
}

.euiCodeBlock.euiCodeBlock-isFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.euiCodeBlock.euiCodeBlock-isFullScreen .euiCodeBlock__pre {
    padding: 32px !important;
}

.euiCodeBlock.euiCodeBlock-isFullScreen .euiCodeBlock__controls {
    top: 4px;
    right: 4px;
}

.euiCodeBlock.euiCodeBlock--fontSmall {
    font-size: 12px;
}

.euiCodeBlock.euiCodeBlock--fontMedium {
    font-size: 14px;
}

.euiCodeBlock.euiCodeBlock--fontLarge {
    font-size: 16px;
}

.euiCodeBlock.euiCodeBlock--hasControls .euiCodeBlock__pre--whiteSpacePreWrap {
    padding-right: 28px;
}

.euiCodeBlock.euiCodeBlock--hasControls .euiCodeBlock__pre--whiteSpacePre {
    margin-right: 28px;
}

.euiCodeBlock.euiCodeBlock--paddingSmall .euiCodeBlock__pre {
    padding: 8px;
}

.euiCodeBlock.euiCodeBlock--paddingSmall .euiCodeBlock__controls {
    top: 8px;
    right: 8px;
}

.euiCodeBlock.euiCodeBlock--paddingSmall.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePreWrap {
    padding-right: 36px;
}

.euiCodeBlock.euiCodeBlock--paddingSmall.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePre {
    margin-right: 36px;
}

.euiCodeBlock.euiCodeBlock--paddingMedium .euiCodeBlock__pre {
    padding: 16px;
}

.euiCodeBlock.euiCodeBlock--paddingMedium .euiCodeBlock__controls {
    top: 16px;
    right: 16px;
}

.euiCodeBlock.euiCodeBlock--paddingMedium.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePreWrap {
    padding-right: 44px;
}

.euiCodeBlock.euiCodeBlock--paddingMedium.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePre {
    margin-right: 44px;
}

.euiCodeBlock.euiCodeBlock--paddingLarge .euiCodeBlock__pre {
    padding: 24px;
}

.euiCodeBlock.euiCodeBlock--paddingLarge .euiCodeBlock__controls {
    top: 24px;
    right: 24px;
}

.euiCodeBlock.euiCodeBlock--paddingLarge.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePreWrap {
    padding-right: 52px;
}

.euiCodeBlock.euiCodeBlock--paddingLarge.euiCodeBlock--hasControls
    .euiCodeBlock__pre--whiteSpacePre {
    margin-right: 52px;
}

.euiCodeBlock.euiCodeBlock--inline {
    display: inline-block;
    white-space: pre;
    color: #343741;
    font-size: 90%;
    /* 1 */
    padding: 0 8px;
    background: #f5f7fa;
}

.euiCodeBlock.euiCodeBlock--inline .euiCodeBlock__pre {
    padding: 0 4px;
}

.euiCodeBlock.euiCodeBlock--inline .euiCodeBlock__code {
    display: inline;
    white-space: normal;
}

.euiCodeBlock.euiCodeBlock--transparentBackground {
    background: transparent;
}

.euiCodeBlock .hljs-comment,
.euiCodeBlock .hljs-quote {
    color: #6a717d;
    font-style: italic;
}

.euiCodeBlock .hljs-selector-tag {
    color: inherit;
    font-weight: 700;
}

.euiCodeBlock .hljs-string,
.euiCodeBlock .hljs-subst,
.euiCodeBlock .hljs-doctag {
    color: #ac4e6d;
}

.euiCodeBlock .hljs-number,
.euiCodeBlock .hljs-literal,
.euiCodeBlock .hljs-regexp,
.euiCodeBlock .hljs-variable,
.euiCodeBlock .hljs-template-variable,
.euiCodeBlock .hljs-tag .hljs-attr {
    color: #3b7d6a;
}

.euiCodeBlock .hljs-keyword {
    color: #7c609e;
}

.euiCodeBlock .hljs-function > .hljs-title {
    color: inherit;
}

.euiCodeBlock .hljs-tag {
    color: #4a7194;
}

.euiCodeBlock .hljs-name {
    color: #4a7194;
}

.euiCodeBlock .hljs-type,
.euiCodeBlock .hljs-class .hljs-title {
    color: #4a7194;
}

.euiCodeBlock .hljs-attribute {
    color: inherit;
}

.euiCodeBlock .hljs-symbol,
.euiCodeBlock .hljs-bullet,
.euiCodeBlock .hljs-built_in,
.euiCodeBlock .hljs-builtin-name,
.euiCodeBlock .hljs-link {
    color: #b34f3b;
}

.euiCodeBlock .hljs-params {
    color: inherit;
}

.euiCodeBlock .hljs-meta {
    color: #6a717d;
}

.euiCodeBlock .hljs-title {
    color: #996130;
}

.euiCodeBlock .hljs-section {
    color: #b34f3b;
}

.euiCodeBlock .hljs-addition,
.euiCodeBlock .hljs-deletion {
    padding-left: 4px;
    margin-left: -4px;
}

.euiCodeBlock .hljs-addition {
    box-shadow: -4px 0 #3b7d6a;
}

.euiCodeBlock .hljs-deletion {
    box-shadow: -4px 0 #bd271e;
}

.euiCodeBlock .hljs-selector-class {
    color: inherit;
}

.euiCodeBlock .hljs-selector-id {
    color: inherit;
}

.euiCodeBlock .hljs-emphasis {
    font-style: italic;
}

.euiCodeBlock .hljs-strong {
    font-weight: 700;
}

.euiCodeBlock .hljs-link {
    text-decoration: underline;
}

.euiCodeEditorWrapper {
    position: relative;
}

.euiCodeEditorWrapper .ace_hidden-cursors {
    opacity: 0;
}

.euiCodeEditorWrapper.euiCodeEditorWrapper-isEditing .ace_hidden-cursors {
    opacity: 1;
}

.euiCodeEditorKeyboardHint {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.euiCodeEditorKeyboardHint.euiCodeEditorKeyboardHint-isInactive {
    display: none;
}

.euiCollapsibleNav {
    border-left: 1px solid #d3dae6;
    box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
        0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    border-color: #c6cad1;
    border-top-color: #e3e4e8;
    border-bottom-color: #aaafba;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 3000;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    clip-path: polygon(-20% 0, 100% 0, 100% 100%, -20% 100%);
    border-left: none;
    right: auto;
    left: 0;
    width: 320px;
    max-width: 80vw;
    animation: euiCollapsibleNavIn 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    clip-path: polygon(0 0, 150% 0, 150% 100%, 0 100%);
}

.euiCollapsibleNav__closeButton {
    position: absolute;
    right: 0;
    top: 16px;
    margin-right: -27%;
    padding: 12px 0;
    line-height: 1;
    border-radius: 4px;
}

.euiCollapsibleNav__closeButton:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    background: #fff !important;
}

.euiCollapsibleNav.euiCollapsibleNav--isDocked {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
    z-index: 1000;
    clip-path: none;
}

.euiCollapsibleNav.euiCollapsibleNav--isDocked .euiCollapsibleNav__closeButton {
    display: none;
}

.euiBody--collapsibleNavIsDocked {
    padding-left: 320px !important;
    transition: padding 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

@media only screen and (max-width: 574px) {
    .euiCollapsibleNav__closeButton {
        margin-right: -15%;
    }

    .euiCollapsibleNav__closeButton .euiCollapsibleNav__closeButtonText {
        position: absolute;
        left: -10000px;
        top: auto;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}

@keyframes euiCollapsibleNavIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    75% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.euiCollapsibleNavGroup:not(:first-child) {
    border-top: 1px solid #d3dae6;
}

.euiCollapsibleNavGroup .euiAccordion__triggerWrapper {
    padding: 16px;
}

.euiCollapsibleNavGroup--light {
    background-color: #fafbfd;
}

.euiCollapsibleNavGroup--dark {
    background-color: #2a2c34;
    color: #fff;
}

.euiCollapsibleNavGroup--dark .euiCollapsibleNavGroup__title {
    color: inherit;
    line-height: inherit;
}

.euiCollapsibleNavGroup__heading {
    font-weight: 600;
}

.euiCollapsibleNavGroup__heading:not(.euiAccordion__button) {
    padding: 16px;
}

.euiCollapsibleNavGroup__children {
    padding: 8px;
}

.euiCollapsibleNavGroup--withHeading .euiCollapsibleNavGroup__children {
    padding-top: 0;
}

.euiColorPicker {
    position: relative;
    width: 152px;
}

.euiColorPicker__popoverAnchor .euiColorPicker__input {
    padding-right: 40px;
}

.euiColorPicker__popoverAnchor .euiColorPicker__input[class*='--compressed'] {
    padding-right: 32px;
}

.euiColorPicker__popoverAnchor .euiColorPicker__input + .euiFormControlLayoutIcons {
    color: inherit;
}

.euiSwatchInput__stroke {
    fill: none;
    stroke: rgba(0, 0, 0, 0.2);
}

.euiColorPicker__popoverPanel--pickerOnly {
    padding-bottom: 0 !important;
}

.euiColorPicker__input--inGroup {
    height: 38px !important;
    box-shadow: none !important;
    border-radius: 0;
}

.euiColorPicker__input--inGroup.euiFieldText--compressed {
    height: 30px !important;
    border-radius: 0;
}

.euiColorPicker__alphaRange .euiRangeInput {
    min-width: 0;
}

.euiColorPickerSwatch {
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 2px;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
}

.euiColorPickerSwatch:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiHue {
    background: linear-gradient(
        to right,
        #ff3232 0%,
        #fff130 20%,
        #45ff30 35%,
        #28fff0 52%,
        #282cff 71%,
        #ff28fb 88%,
        #ff0094 100%
    );
    height: 24px;
    margin: 4px 0;
    position: relative;
}

.euiHue:before,
.euiHue:after {
    content: '';
    left: 0;
    position: absolute;
    height: 8px;
    background: #fff;
    width: 100%;
}

.euiHue:after {
    bottom: 0;
}

.euiHue__range {
    position: relative;
    height: 24px;
    width: calc(100% + 2px);
    margin: 0 -1px;
    appearance: none;
    background: transparent;
    z-index: 2;
}

.euiHue__range::-webkit-slider-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiHue__range::-moz-range-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiHue__range::-ms-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiHue__range::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: 0;
}

.euiHue__range::-ms-thumb {
    margin-top: 0;
}

.euiHue__range::-ms-track {
    height: 24px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

.euiHue__range::-moz-focus-outer {
    border: none;
}

.euiHue__range::-ms-fill-lower,
.euiHue__range::-ms-fill-upper {
    background: transparent;
}

.euiSaturation {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 2px;
    touch-action: none;
    z-index: 3;
}

.euiSaturation .euiSaturation__lightness,
.euiSaturation .euiSaturation__saturation {
    position: absolute;
    top: -1px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 2px;
}

.euiSaturation .euiSaturation__lightness {
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}

.euiSaturation .euiSaturation__saturation {
    background: linear-gradient(to top, black, rgba(0, 0, 0, 0));
}

.euiSaturation .euiSaturation__indicator {
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-top: -6px;
    margin-left: -6px;
    border: 1px solid #343741;
}

.euiSaturation .euiSaturation__indicator:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    border: 1px solid #f5f7fa;
}

.euiSaturation:focus .euiSaturation__indicator {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiColorStops__addContainer {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 16px;
    margin-top: -8px;
}

.euiColorStops__addContainer:hover:not(.euiColorStops__addContainer-isDisabled) {
    cursor: pointer;
}

.euiColorStops__addContainer:hover:not(.euiColorStops__addContainer-isDisabled)
    .euiColorStops__addTarget {
    opacity: 0.7;
}

.euiColorStops__addTarget {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 0;
    height: 16px;
    width: 16px;
    background-color: #f5f7fa;
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms;
}

.euiColorStop {
    width: 152px;
}

.euiColorStopPopover.euiPopover {
    position: absolute;
    top: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
}

.euiColorStopPopover-hasFocus {
    z-index: 1;
}

.euiColorStopPopover__anchor {
    position: absolute;
    width: 100%;
    height: 100%;
}

.euiColorStopPopover__anchor:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: #fff;
}

.euiColorStopThumb.euiRangeThumb:not(:disabled) {
    top: 0;
    margin-top: 0;
    pointer-events: auto;
    cursor: grab;
    border: solid 3px #fff;
    box-shadow: 0 0 0 1px #98a2b3, 0 2px 2px -1px rgba(152, 162, 179, 0.2),
        0 1px 5px -2px rgba(152, 162, 179, 0.2);
}

.euiColorStopThumb.euiRangeThumb:not(:disabled):active {
    cursor: grabbing;
}

.euiColorStopPopover-isLoadingPanel {
    visibility: hidden !important;
}

.euiColorStops.euiColorStops-isDragging:not(.euiColorStops-isDisabled):not(.euiColorStops-isReadOnly) {
    cursor: grabbing;
}

.euiColorStops__highlight {
    color: #69707d;
}

.euiColorPalettePicker__itemTitle {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiColorPalettePicker__itemTitle + .euiColorPaletteDisplay {
    margin-top: 4px;
}

.euiColorPaletteDisplay {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 8px;
}

.euiColorPaletteDisplay--sizeExtraSmall {
    position: relative;
    height: 4px;
    border-radius: 4px;
}

.euiColorPaletteDisplay--sizeExtraSmall:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    content: '';
    pointer-events: none;
    border: 1px solid rgba(52, 55, 65, 0.2);
}

.euiColorPaletteDisplay--sizeExtraSmall .euiColorPaletteDisplayFixed__bleedArea {
    height: 4px;
}

.euiColorPaletteDisplay--sizeSmall {
    position: relative;
    height: 8px;
    border-radius: 8px;
}

.euiColorPaletteDisplay--sizeSmall:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    content: '';
    pointer-events: none;
    border: 1px solid rgba(52, 55, 65, 0.2);
}

.euiColorPaletteDisplay--sizeSmall .euiColorPaletteDisplayFixed__bleedArea {
    height: 8px;
}

.euiColorPaletteDisplay--sizeMedium {
    position: relative;
    height: 16px;
    border-radius: 16px;
}

.euiColorPaletteDisplay--sizeMedium:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 16px;
    content: '';
    pointer-events: none;
    border: 1px solid rgba(52, 55, 65, 0.2);
}

.euiColorPaletteDisplay--sizeMedium .euiColorPaletteDisplayFixed__bleedArea {
    height: 16px;
}

.euiColorPaletteDisplayFixed__bleedArea {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 8px;
    width: calc(100% + 1px);
}

.euiComboBox {
    max-width: 400px;
    width: 100%;
    height: auto;
    position: relative;
    /**
     * 1. Allow pills to truncate their text with an ellipsis.
     * 2. Don't allow pills/placeholder to overlap with the caret, loading icon or clear button.
     * 3. The height on combo can be larger than normal text inputs.
     */
    /**
     * 1. Force field height to match other field heights.
     * 2. Force input height to expand to fill this element.
     * 3. Reset appearance on Safari.
     * 4. Fix react-input-autosize appearance.
     * 5. Prevent a lot of input from causing the react-input-autosize to overflow the container.
     */
}

.euiComboBox--fullWidth {
    max-width: 100%;
}

.euiComboBox--compressed {
    height: 32px;
}

.euiComboBox--inGroup {
    height: 100%;
}

.euiComboBox--compressed,
.euiComboBox .euiFormControlLayout {
    height: auto;
}

.euiComboBox .euiComboBox__inputWrap {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    max-width: 400px;
    width: 100%;
    height: auto;
    padding: 4px 8px;
    display: flex;
    /* 1 */
    outline: none;
    padding-right: 40px;
    /* 2 */
}

.euiComboBox .euiComboBox__inputWrap--fullWidth {
    max-width: 100%;
}

.euiComboBox .euiComboBox__inputWrap--compressed {
    height: 32px;
}

.euiComboBox .euiComboBox__inputWrap--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiComboBox .euiComboBox__inputWrap {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiComboBox .euiComboBox__inputWrap {
        line-height: 1em;
    }
}

.euiComboBox .euiComboBox__inputWrap::-webkit-input-placeholder {
    color: #69707d;
}

.euiComboBox .euiComboBox__inputWrap::-moz-placeholder {
    color: #69707d;
}

.euiComboBox .euiComboBox__inputWrap:-ms-input-placeholder {
    color: #69707d;
}

.euiComboBox .euiComboBox__inputWrap:-moz-placeholder {
    color: #69707d;
}

.euiComboBox .euiComboBox__inputWrap::placeholder {
    color: #69707d;
}

.euiComboBox .euiComboBox__inputWrap--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiComboBox .euiComboBox__inputWrap--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiComboBox .euiComboBox__inputWrap--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiComboBox .euiComboBox__inputWrap--withIcon {
    padding-left: 40px;
}

.euiComboBox .euiComboBox__inputWrap--fullWidth {
    max-width: 100%;
}

.euiComboBox .euiComboBox__inputWrap--compressed {
    height: 32px;
}

.euiComboBox .euiComboBox__inputWrap--inGroup {
    height: 100%;
}

.euiComboBox .euiComboBox__inputWrap .euiComboBoxPill {
    max-width: calc(100% - 2px - 16px);
}

.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap) {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    height: auto;
    /* 3 */
    flex-wrap: wrap;
    /* 1 */
    align-content: flex-start;
}

.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap):hover {
    cursor: text;
}

.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable {
    padding-right: 62px;
    /* 2 */
}

.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading {
    padding-right: 62px;
    /* 2 */
}

.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading .euiComboBoxPlaceholder {
    padding-right: 62px;
    /* 2 */
}

.euiComboBox
    .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading.euiComboBox__inputWrap-isClearable {
    padding-right: 84px;
    /* 2 */
}

.euiComboBox .euiComboBox__input {
    display: inline-flex !important;
    /* 1 */
    height: 32px;
    /* 2 */
    overflow: hidden;
    /* 5 */
}

.euiComboBox .euiComboBox__input > input {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    appearance: none;
    /* 3 */
    padding: 0;
    border: none;
    background: transparent;
    font-size: 14px;
    color: #343741;
    margin: 4px;
    line-height: 1.5;
    /* 4 */
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-moz-placeholder {
    color: #98a2b3;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-ms-input-placeholder {
    color: #98a2b3;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-moz-placeholder {
    color: #98a2b3;
}

.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::placeholder {
    color: #98a2b3;
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap {
    line-height: 32px;
    /* 2 */
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 32px;
    /* 2 */
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable {
    padding-right: 54px;
    /* 2 */
}

.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading {
    padding-right: 54px;
    /* 2 */
}

.euiComboBox.euiComboBox--compressed
    .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading
    .euiComboBoxPlaceholder {
    padding-right: 54px;
    /* 2 */
}

.euiComboBox.euiComboBox--compressed
    .euiComboBox__inputWrap.euiComboBox__inputWrap-isLoading.euiComboBox__inputWrap-isClearable {
    padding-right: 76px;
    /* 2 */
}

.euiComboBox .euiFormControlLayout__prepend,
.euiComboBox .euiFormControlLayout__append {
    height: auto !important;
}

.euiComboBox__input {
    max-width: 100%;
}

.euiComboBox__input input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

/*
 * 1. Overwrites the base styling of EuiBadge, to give it a larger size and margins
 * that make sense in the input wrap.
 */
.euiComboBoxPill {
    height: 22px;
    line-height: 22px;
    vertical-align: baseline;
}

.euiComboBoxPill,
.euiComboBoxPill + .euiComboBoxPill {
    margin: 4px;
}

.euiComboBox--compressed .euiComboBoxPill,
.euiComboBox--compressed .euiComboBoxPill + .euiComboBoxPill {
    margin: 5px 4px 0 0;
}

.euiComboBoxPill--plainText {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    line-height: 24px;
    font-size: 14px;
    padding: 0;
    color: #343741;
    vertical-align: middle;
    display: inline-block;
}

.euiComboBoxPlaceholder {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    padding-right: 40px;
    position: absolute;
    pointer-events: none;
    padding-left: 4px;
    line-height: 32px;
    color: #98a2b3;
    margin-bottom: 0 !important;
}

/**
 * 1. Using specificity to override panel shadow
 * 2. Prevent really long input from overflowing the container.
 */
.euiComboBoxOptionsList {
    transform: none !important;
    top: 0;
}

.euiComboBoxOptionsList.euiPopover__panel-isAttached.euiComboBoxOptionsList--top {
    /* 1 */
    box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
        0 0 2px 0 rgba(152, 162, 179, 0.2);
}

.euiComboBoxOptionsList__empty {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    /* 2 */
    padding: 8px;
    text-align: center;
    color: #69707d;
    word-wrap: break-word;
}

.euiComboBoxOptionsList__rowWrap {
    padding: 0;
    max-height: 200px;
    overflow: hidden;
}

.euiComboBoxOptionsList__rowWrap > div {
    scrollbar-width: thin;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-corner,
.euiComboBoxOptionsList__rowWrap > div::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiComboBoxOption {
    font-size: 14px;
    padding: 4px 8px 4px 16px;
    width: 100%;
    text-align: left;
    border: 1px solid #d3dae6;
    border-color: transparent;
    display: flex;
    align-items: center;
}

.euiComboBoxOption:hover {
    text-decoration: underline;
}

.euiComboBoxOption.euiComboBoxOption-isDisabled {
    color: #98a2b3;
    cursor: not-allowed;
}

.euiComboBoxOption.euiComboBoxOption-isDisabled:hover {
    text-decoration: none;
}

.euiComboBoxOption__contentWrapper {
    display: flex;
}

.euiComboBoxOption__contentWrapper .euiComboBoxOption__emptyStateText {
    flex: 1;
    text-align: left;
    margin-bottom: 0;
}

.euiComboBoxOption__contentWrapper .euiComboBoxOption__enterBadge {
    align-self: flex-start;
    margin-left: 4px;
}

.euiComboBoxOption__content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    text-align: left;
}

/**
 * 1. Force each title to be the same height as an option, so that the virtualized scroll logic
 *    works.
 */
.euiComboBoxTitle {
    font-size: 12px;
    padding: 11px 8px 4px;
    /* 1 */
    width: 100%;
    font-weight: 700;
    color: #000;
}

.euiComment {
    font-size: 14px;
    display: flex;
    padding-bottom: 16px;
    min-height: 56px;
}

.euiComment .euiCommentEvent {
    flex-grow: 1;
}

.euiComment .euiCommentTimeline {
    position: relative;
    flex-grow: 0;
    margin-right: 16px;
}

.euiComment .euiCommentTimeline::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 24px;
    width: 2px;
    background-color: #d3dae6;
    height: calc(100% + 24px);
}

.euiComment:last-of-type .euiCommentTimeline::before {
    display: none;
}

.euiComment--update:not(.euiComment--hasBody) {
    align-items: center;
}

.euiCommentEvent--regular {
    background-color: #fff;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    flex-grow: 1;
}

.euiCommentEvent--regular.euiCommentEvent--regular--flexGrowZero {
    flex-grow: 0;
}

.euiCommentEvent--regular.euiCommentEvent--regular--noBorder {
    border: none;
}

.euiCommentEvent--regular.euiCommentEvent--regular--hasShadow {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    border: 1px solid #d3dae6;
}

.euiCommentEvent--regular.euiCommentEvent--regular--isClickable {
    display: block;
    width: 100%;
    text-align: left;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCommentEvent--regular.euiCommentEvent--regular--isClickable:hover,
.euiCommentEvent--regular.euiCommentEvent--regular--isClickable:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    transform: translateY(-2px);
    cursor: pointer;
}

.euiCommentEvent--regular.euiCommentEvent--regular--borderRadiusNone {
    border-radius: 0;
}

.euiCommentEvent--regular.euiCommentEvent--regular--borderRadiusMedium {
    border-radius: 4px;
}

.euiCommentEvent--regular.euiCommentEvent--regular--transparent {
    background-color: transparent;
}

.euiCommentEvent--regular.euiCommentEvent--regular--plain {
    background-color: #fff;
}

.euiCommentEvent--regular.euiCommentEvent--regular--subdued {
    background-color: #fafbfd;
}

.euiCommentEvent--regular.euiCommentEvent--regular--accent {
    background-color: #fce7f1;
}

.euiCommentEvent--regular.euiCommentEvent--regular--primary {
    background-color: #fae9ea;
}

.euiCommentEvent--regular.euiCommentEvent--regular--success {
    background-color: #e6f2f1;
}

.euiCommentEvent--regular.euiCommentEvent--regular--warning {
    background-color: #fef6e6;
}

.euiCommentEvent--regular.euiCommentEvent--regular--danger {
    background-color: #f8e9e9;
}

.euiCommentEvent {
    overflow: hidden;
}

.euiCommentEvent__header {
    line-height: 1.5;
    display: flex;
    align-items: center;
}

.euiCommentEvent__headerData {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.euiCommentEvent__headerData > div {
    padding-right: 4px;
}

.euiCommentEvent__headerUsername {
    font-weight: 600;
}

.euiCommentEvent--regular {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    border-radius: 4px;
    border: 1px solid #d3dae6;
}

.euiCommentEvent--regular .euiCommentEvent__header {
    min-height: 40px;
    background-color: #f5f7fa;
    border-bottom: 1px solid #d3dae6;
    padding: 4px 8px;
    /**
     * Fix for IE when using align-items:center in an item that has min-height
        (https://github.com/philipwalton/flexbugs/issues/231#issuecomment-362790042)
     */
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiCommentEvent--regular .euiCommentEvent__header::after {
        content: '';
        min-height: 32px;
        font-size: 0;
        display: block;
    }
}

.euiCommentEvent--regular .euiCommentEvent__headerData {
    flex-grow: 1;
}

.euiCommentEvent--regular .euiCommentEvent__body {
    padding: 8px;
}

.euiCommentEvent--update .euiCommentEvent__header {
    justify-content: flex-start;
    padding: 4px 0;
}

.euiCommentEvent--update .euiCommentEvent__headerData {
    padding-right: 8px;
}

.euiCommentEvent--update .euiCommentEvent__body {
    padding-top: 4px;
}

.euiCommentTimeline__content {
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.euiCommentTimeline__icon--default {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    border-radius: 50%;
    background-color: #f5f7fa;
}

.euiCommentTimeline__icon--default.euiCommentTimeline__icon--regular {
    width: 40px;
    height: 40px;
}

.euiCommentTimeline__icon--default.euiCommentTimeline__icon--update {
    width: 24px;
    height: 24px;
}

.euiContextMenu {
    width: 256px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    transition: height 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    border-radius: 4px;
}

.euiContextMenu .euiContextMenu__content {
    padding: 8px;
}

/**
  * 1. When there are multiple ContextMenuPanels, the ContextMenu will absolutely
  *    position them. ContextMenuPanel will break the layout of a Popover if it's
  *    absolutely positioned by default.
  */
.euiContextMenu__panel {
    position: absolute;
    /* 1 */
}

.euiContextMenu__icon {
    margin-right: 8px;
}

.euiContextMenuPanel {
    width: 100%;
    visibility: visible;
    outline-offset: -3px;
}

.euiContextMenuPanel.euiContextMenuPanel-txInLeft {
    pointer-events: none;
    animation: euiContextMenuPanelTxInLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiContextMenuPanel.euiContextMenuPanel-txOutLeft {
    pointer-events: none;
    animation: euiContextMenuPanelTxOutLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiContextMenuPanel.euiContextMenuPanel-txInRight {
    pointer-events: none;
    animation: euiContextMenuPanelTxInRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiContextMenuPanel.euiContextMenuPanel-txOutRight {
    pointer-events: none;
    animation: euiContextMenuPanelTxOutRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiContextMenuPanel--next {
    transform: translateX(256px);
    visibility: hidden;
}

.euiContextMenuPanel--previous {
    transform: translateX(-256px);
    visibility: hidden;
}

.euiContextMenuPanelTitle {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #d3dae6;
    padding: 12px;
    width: 100%;
    text-align: left;
    outline-offset: -3px;
}

.euiContextMenuPanelTitle:enabled:hover,
.euiContextMenuPanelTitle:enabled:focus {
    text-decoration: underline;
}

.euiContextMenuPanelTitle--small {
    color: #1a1c21;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #d3dae6;
    padding: 6px 8px;
}

@keyframes euiContextMenuPanelTxInLeft {
    0% {
        transform: translateX(256px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes euiContextMenuPanelTxOutLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-256px);
    }
}

@keyframes euiContextMenuPanelTxInRight {
    0% {
        transform: translateX(-256px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes euiContextMenuPanelTxOutRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(256px);
    }
}

.euiContextMenuItem {
    display: block;
    padding: 12px;
    width: 100%;
    text-align: left;
    color: #343741;
    outline-offset: -3px;
}

.euiContextMenuItem:hover,
.euiContextMenuItem:focus {
    text-decoration: underline;
}

.euiContextMenuItem.euiContextMenuItem-isDisabled {
    color: #afb0b3;
    cursor: default;
}

.euiContextMenuItem.euiContextMenuItem-isDisabled:hover,
.euiContextMenuItem.euiContextMenuItem-isDisabled:focus {
    text-decoration: none;
}

.euiContextMenuItem--small {
    padding: 6px 8px;
}

.euiContextMenuItem--small .euiContextMenuItem__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiContextMenuItem__inner {
    display: flex;
}

.euiContextMenuItem__text {
    flex-grow: 1;
    overflow: hidden;
}

.euiContextMenuItem__arrow {
    align-self: flex-end;
}

.euiContextMenu__itemLayout {
    display: flex;
    align-items: center;
}

.euiContextMenu__itemLayout.euiContextMenu__itemLayout--bottom {
    align-items: flex-end;
}

.euiContextMenu__itemLayout.euiContextMenu__itemLayout--top {
    align-items: flex-start;
}

.euiContextMenu__itemLayout .euiContextMenu__icon {
    flex-shrink: 0;
}

.euiControlBar {
    background: #343741;
    color: white;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 40px 0 #343741, inset 0 600rem 0 #fafbfd;
    bottom: 0;
    transform: translateY(0);
    height: 40px;
    max-height: calc(100vh - 80px);
}

.euiControlBar--fixed {
    position: fixed;
    z-index: 6000;
}

.euiControlBar--absolute {
    position: absolute;
    z-index: 1000;
}

.euiControlBar--relative {
    position: relative;
}

.euiControlBar-isOpen {
    animation-duration: 250ms;
    animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
    animation-fill-mode: forwards;
}

.euiControlBar-isOpen.euiControlBar--large {
    animation-name: euiControlBarOpenPanelLarge;
    height: calc(100vh - 80px);
    bottom: -100vh;
}

.euiControlBar-isOpen.euiControlBar--medium {
    animation-name: euiControlBarOpenPanelMedium;
    height: 480px;
    bottom: -480px;
}

.euiControlBar-isOpen.euiControlBar--small {
    animation-name: euiControlBarOpenPanelSmall;
    height: 240px;
    bottom: -240px;
}

.euiControlBar__controls {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 12px;
}

.euiControlBar__content {
    scrollbar-width: thin;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 40px);
    background-color: #fafbfd;
    animation-name: euiControlBarShowContent;
    animation-duration: 350ms;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
    color: #343741;
}

.euiControlBar__content::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiControlBar__content::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiControlBar__content::-webkit-scrollbar-corner,
.euiControlBar__content::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiControlBar__icon {
    flex-shrink: 0;
    margin-left: 8px;
    margin-right: 8px;
}

.euiControlBar__buttonIcon {
    flex-shrink: 0;
    min-width: 40px;
    min-height: 40px;
}

.euiControlBar__button {
    flex-shrink: 0;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 14px;
}

.euiControlBar__button:enabled:hover {
    transform: none;
    box-shadow: none;
}

.euiControlBar__button:last-child {
    margin-right: 4px;
}

.euiControlBar__breadcrumbs .euiBreadcrumb:not(.euiBreadcrumb--last) {
    color: #9ca1aa;
}

.euiControlBar__breadcrumbs .euiBreadcrumbSeparator {
    background: rgba(255, 255, 255, 0.2);
}

.euiControlBar__spacer {
    flex-grow: 1;
    height: 100%;
}

.euiControlBar__divider {
    flex-shrink: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.2);
}

.euiControlBar__text {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 0 8px;
    color: white;
}

.euiControlBar__text:last-child {
    padding-right: 0;
}

.euiControlBar__tab {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: white;
    padding: 0 16px;
    text-align: center;
    height: 100%;
}

.euiControlBar__tab:hover,
.euiControlBar__tab:focus {
    text-decoration: underline;
    cursor: pointer;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--primary:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiLink.euiLink--secondary {
    color: #5eaea7;
}

.euiControlBar__controls .euiLink.euiLink--secondary:hover {
    color: #4da49d;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--secondary:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--secondary:enabled:not(.euiButton--fill) {
    color: #5eaea7;
    border-color: #5eaea7;
}

.euiControlBar__controls .euiButtonIcon--secondary {
    color: #5eaea7;
}

.euiControlBar__controls .euiLink.euiLink--warning {
    color: #f5a700;
}

.euiControlBar__controls .euiLink.euiLink--warning:hover {
    color: #f8c14d;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--warning:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--warning:enabled:not(.euiButton--fill) {
    color: #f5a700;
    border-color: #f5a700;
}

.euiControlBar__controls .euiButtonIcon--warning {
    color: #f5a700;
}

.euiControlBar__controls .euiLink.euiLink--danger {
    color: #db8a85;
}

.euiControlBar__controls .euiLink.euiLink--danger:hover {
    color: #d16862;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--danger:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--danger:enabled:not(.euiButton--fill) {
    color: #db8a85;
    border-color: #db8a85;
}

.euiControlBar__controls .euiButtonIcon--danger {
    color: #db8a85;
}

.euiControlBar__controls .euiLink.euiLink--ghost {
    color: #fff;
}

.euiControlBar__controls .euiLink.euiLink--ghost:hover {
    color: white;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--ghost:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--ghost:enabled:not(.euiButton--fill) {
    color: #fff;
    border-color: #fff;
}

.euiControlBar__controls .euiButtonIcon--ghost {
    color: #fff;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #9ca0aa;
}

.euiControlBar__controls .euiLink.euiLink--text:hover {
    color: #969ba4;
}

.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}

.euiControlBar__controls .euiControlBar__button.euiButton--text:enabled {
    box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--text:enabled:not(.euiButton--fill) {
    color: #9ca0aa;
    border-color: #9ca0aa;
}

.euiControlBar__controls .euiButtonIcon--text {
    color: #9ca0aa;
}

@media only screen and (max-width: 574px) {
    .euiControlBar:not(.euiControlBar--showOnMobile) {
        display: none;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiControlBar:not(.euiControlBar--showOnMobile) {
        display: none;
    }
}

@keyframes euiControlBarOpenPanelLarge {
    0% {
        transform: translateY(calc((40px * 3) * -1));
    }
    100% {
        transform: translateY(-100vh);
    }
}

@keyframes euiControlBarOpenPanelMedium {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(-480px);
    }
}

@keyframes euiControlBarOpenPanelSmall {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(-240px);
    }
}

@keyframes euiControlBarShowContent {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* This file is a heavy retheme of react-datepicker's Sass as of v1.4.0
** https://github.com/Hacker0x01/react-datepicker
**
** In places where features were disabled, I've commented out the original Sass
** selectors rather than removing it so we can better understand what's changed.
** Commented out selectors that don't have properties indicate that we are not
** using those dom elements for styling of any kind. For example, react-datepicker
** has lots of pointer arrows attached to its popovers, but we choose not to render
** then in any way.
**
** Similarly, you will also find several times where we use display: none to
** completely remove extraneous UI (they had some overly obvious legends for example).
*/
.euiDatePicker .euiFormControlLayout {
    height: auto;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
    border: 1px solid #d3dae6;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
}

.euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
    border: 1px solid #d3dae6;
    background-color: #fff;
    border-radius: 4px;
}

.react-datepicker {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 12px;
    color: #000;
    display: flex;
    position: relative;
    border-radius: 4px;
}

.react-datepicker--time-only
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
}

.react-datepicker--time-only
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    li.react-datepicker__time-list-item {
    font-size: 14px;
    text-align: left;
    padding-left: 36px;
    padding-right: 36px;
    color: #343741;
}

.react-datepicker--time-only
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    color: #fff;
}

.react-datepicker--time-only
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    li.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
    color: #afb0b3;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper {
    z-index: 2000;
    animation: euiAnimFadeIn 150ms ease-in;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='top'] {
    box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
        0 0 2px 0 rgba(152, 162, 179, 0.2);
    border-radius: 4px 4px 0 0;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='right'] {
    margin-left: 0;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='left'] {
    margin-right: 0;
}

.react-datepicker__header {
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.react-datepicker__header--time {
    display: none;
}

.react-datepicker__header__dropdown {
    padding: 16px 0 8px 0;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 4px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
    display: none;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    cursor: pointer;
    position: absolute;
    top: 18px;
    width: 0;
    padding: 0;
    z-index: 1;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    background-position: center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPHBhdGggaWQ9ImFycm93X2Rvd24tYSIgZD0iTTEzLjA2ODg1MDgsNS4xNTcyNTAzOCBMOC4zODQyMzk3NSw5Ljc2ODI3NDI4IEM4LjE3MDU0NDE1LDkuOTc4NjEzMDggNy44Mjk5OTIxNCw5Ljk3OTE0MDk1IDcuNjE1NzYwMjUsOS43NjgyNzQyOCBMMi45MzExNDkxNSw1LjE1NzI1MDM4IEMyLjcxODEzNTksNC45NDc1ODMyMSAyLjM3Mjc3MzE5LDQuOTQ3NTgzMjEgMi4xNTk3NTk5NCw1LjE1NzI1MDM4IEMxLjk0Njc0NjY5LDUuMzY2OTE3NTYgMS45NDY3NDY2OSw1LjcwNjg1NTIyIDIuMTU5NzU5OTQsNS45MTY1MjI0IEw2Ljg0NDM3MTA0LDEwLjUyNzU0NjMgQzcuNDg1MTc0MjQsMTEuMTU4MjgzNiA4LjUxNjQ0OTc5LDExLjE1NjY4NTEgOS4xNTU2Mjg5NiwxMC41Mjc1NDYzIEwxMy44NDAyNDAxLDUuOTE2NTIyNCBDMTQuMDUzMjUzMyw1LjcwNjg1NTIyIDE0LjA1MzI1MzMsNS4zNjY5MTc1NiAxMy44NDAyNDAxLDUuMTU3MjUwMzggQzEzLjYyNzIyNjgsNC45NDc1ODMyMSAxMy4yODE4NjQxLDQuOTQ3NTgzMjEgMTMuMDY4ODUwOCw1LjE1NzI1MDM4IFoiLz4gIDwvZGVmcz4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDx1c2UgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYXJyb3dfZG93bi1hIi8+ICA8L2c+PC9zdmc+);
    left: 20px;
    height: 16px;
    width: 16px;
    transform: rotate(90deg);
    transition: transform 90ms ease-in-out;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--previous:focus {
    border-radius: 4px;
    transform: scale(1.2) rotate(90deg);
}

.react-datepicker__month-read-view:focus,
.react-datepicker__year-read-view:focus {
    text-decoration: underline;
}

.euiDatePopoverButton-isInvalid {
    background-color: #f8e9e9;
    color: #bd271e;
}

.euiDatePopoverButton-isInvalid:focus,
.euiDatePopoverButton-isInvalid.euiDatePopoverButton-isSelected {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
}

.euiDataGrid__controlScroll {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    max-height: 400px;
    padding: 8px;
    margin: -8px;
}

.euiDataGridColumnSelector__columnList {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    max-height: 400px;
    margin: 0 -8px;
}

.euiDataGridColumnSorting__fieldList {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    padding-top: 4px;
    padding-bottom: 4px;
    max-height: 300px;
}

.euiFlyoutBody .euiFlyoutBody__overflow {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
}

.euiFlyoutBody .euiFlyoutBody__overflow.euiFlyoutBody__overflow--hasBanner {
    mask-image: linear-gradient(to bottom, red calc(100% - 7.5px), rgba(255, 0, 0, 0.1) 100%);
}

.euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorFooter,
.euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorTextArea,
.euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorTextArea:focus,
.euiMarkdownEditorDropZone--isDraggingError
    .euiMarkdownEditor:focus-within
    .euiMarkdownEditorTextArea {
    background-color: rgba(189, 39, 30, 0.1) !important;
}

.euiMarkdownEditorDropZone--hasError .euiMarkdownEditorTextArea,
.euiMarkdownEditorDropZone--hasError .euiMarkdownEditorTextArea:focus {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    ) !important;
}

.euiModalBody .euiModalBody__overflow {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    padding: 8px 24px;
}

.euiSelectableList__list {
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    scrollbar-width: thin;
}

.euiStepNumber--warning {
    color: #936400;
    background-color: #fef6e6;
}

.react-datepicker__navigation--previous:hover {
    background-color: #f5f7fa;
    box-shadow: 0 0 0 2px #f5f7fa;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
    cursor: not-allowed;
    opacity: 0.2;
}

.react-datepicker__navigation--next {
    background-position: center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPHBhdGggaWQ9ImFycm93X2Rvd24tYSIgZD0iTTEzLjA2ODg1MDgsNS4xNTcyNTAzOCBMOC4zODQyMzk3NSw5Ljc2ODI3NDI4IEM4LjE3MDU0NDE1LDkuOTc4NjEzMDggNy44Mjk5OTIxNCw5Ljk3OTE0MDk1IDcuNjE1NzYwMjUsOS43NjgyNzQyOCBMMi45MzExNDkxNSw1LjE1NzI1MDM4IEMyLjcxODEzNTksNC45NDc1ODMyMSAyLjM3Mjc3MzE5LDQuOTQ3NTgzMjEgMi4xNTk3NTk5NCw1LjE1NzI1MDM4IEMxLjk0Njc0NjY5LDUuMzY2OTE3NTYgMS45NDY3NDY2OSw1LjcwNjg1NTIyIDIuMTU5NzU5OTQsNS45MTY1MjI0IEw2Ljg0NDM3MTA0LDEwLjUyNzU0NjMgQzcuNDg1MTc0MjQsMTEuMTU4MjgzNiA4LjUxNjQ0OTc5LDExLjE1NjY4NTEgOS4xNTU2Mjg5NiwxMC41Mjc1NDYzIEwxMy44NDAyNDAxLDUuOTE2NTIyNCBDMTQuMDUzMjUzMyw1LjcwNjg1NTIyIDE0LjA1MzI1MzMsNS4zNjY5MTc1NiAxMy44NDAyNDAxLDUuMTU3MjUwMzggQzEzLjYyNzIyNjgsNC45NDc1ODMyMSAxMy4yODE4NjQxLDQuOTQ3NTgzMjEgMTMuMDY4ODUwOCw1LjE1NzI1MDM4IFoiLz4gIDwvZGVmcz4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDx1c2UgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYXJyb3dfZG93bi1hIi8+ICA8L2c+PC9zdmc+);
    right: 20px;
    height: 16px;
    width: 16px;
    transform: rotate(-90deg);
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    left: 248px;
}

.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--next:focus {
    border-radius: 4px;
    transform: scale(1.2) rotate(-90deg);
}

.react-datepicker__navigation--next:hover {
    background-color: #f5f7fa;
    box-shadow: 0 0 0 2px #f5f7fa;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
    cursor: not-allowed;
    opacity: 0.2;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #f5f7fa;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #d3dce9;
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #f5f7fa;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #d3dce9;
}

.react-datepicker__month {
    margin: 0 16px 16px 16px;
    text-align: center;
    border-radius: 4px;
}

.react-datepicker__time-container {
    border-left: #d3dae6;
    width: auto;
    display: flex;
    padding: 16px 0;
    border-radius: 0 4px 4px 0;
    flex-grow: 1;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    flex-grow: 1;
    display: flex;
    padding-left: 4px;
    flex-direction: column;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    scrollbar-width: thin;
    height: 204px !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    align-items: center;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-corner,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding: 4px 8px;
    margin-bottom: 4px;
    text-align: right;
    color: #69707d;
    white-space: nowrap;
    line-height: 12px;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover,
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:focus {
    cursor: pointer;
    text-decoration: underline;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #d3dae6;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled:hover {
    cursor: not-allowed;
    text-decoration: none;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #f5f7fa;
    display: inline-block;
    width: 32px;
    line-height: 28px;
    text-align: center;
    margin: 0 4px;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 4px;
    background-color: #fff;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 32px;
    line-height: 28px;
    text-align: center;
    margin: 0 2px;
}

.react-datepicker__day-name {
    color: #69707d;
    text-transform: uppercase;
}

.react-datepicker__day {
    cursor: pointer;
    border: solid 2px transparent;
    transition: transform 90ms ease-in-out;
}

.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
    text-decoration: underline;
    font-weight: 700;
    transform: scale(1.2);
}

.react-datepicker__day--outside-month {
    color: #69707d;
}

.react-datepicker__day--highlighted {
    border-radius: 4px;
    background-color: #017d73;
    color: #fff;
}

.react-datepicker__day--highlighted:hover {
    background-color: #01645c;
}

.react-datepicker__day--in-range {
    background-color: rgba(207, 31, 47, 0.1);
    color: #000;
    border-radius: 0;
    border-top: solid 6px #fff;
    border-bottom: solid 6px #fff;
    border-right: none;
    border-left: none;
    line-height: 20px;
}

.react-datepicker__month--selecting-range
    .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #fff;
    color: #000;
}

.react-datepicker__day--disabled {
    cursor: not-allowed;
    color: #d3dae6;
}

.react-datepicker__day--disabled:hover {
    background-color: transparent;
}

.react-datepicker__input-container {
    position: relative;
}

.react-datepicker__year-read-view {
    font-weight: 300;
    color: #69707d;
}

.react-datepicker__month-read-view {
    font-weight: 500;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    font-size: 20px;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #d3dce9;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    display: none;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    animation: euiAnimFadeIn 150ms ease-in;
    align-content: space-around;
    align-items: center;
    padding: 8px;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown {
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: flex-end;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__year-option:last-of-type {
    display: none;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    font-size: 12px;
    padding: 8px;
    color: #343741;
    flex-basis: 33.3%;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #f5f7fa;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #d3dce9;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #d3dce9;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    display: none;
}

.react-datepicker__screenReaderOnly {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.react-datepicker__focusTrap {
    display: flex;
}

/**
 * 1. Account for inner box-shadow style border
 */
.euiDatePickerRange {
    max-width: 400px;
    width: 100%;
    height: auto;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    display: flex;
    align-items: center;
    padding: 1px;
    /* 1 */
}

.euiDatePickerRange--fullWidth {
    max-width: 100%;
}

.euiDatePickerRange--compressed {
    height: 32px;
}

.euiDatePickerRange--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiDatePickerRange {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiDatePickerRange > * {
    flex-grow: 1;
}

.euiDatePickerRange .euiFieldText.euiDatePicker {
    box-shadow: none !important;
    text-align: center;
}

.euiDatePickerRange .react-datepicker-popper .euiFieldText.euiDatePicker {
    text-align: left;
}

.euiDatePickerRange--inGroup {
    box-shadow: none;
    padding: 0;
}

.euiDatePickerRange--inGroup .euiDatePicker {
    height: 38px;
}

.euiDatePickerRange > .euiDatePickerRange__delimeter {
    background-color: transparent !important;
    line-height: 1 !important;
    flex: 0 0 auto;
    padding-left: 6px;
    padding-right: 6px;
}

.euiDatePickerRange--readOnly {
    background: #eef2f7;
}

.euiSuperDatePicker__absoluteDateFormRow {
    padding: 0 8px 8px;
}

.euiDatePopoverButton {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    display: block;
    width: 100%;
    padding: 0 8px;
    line-height: 38px;
    height: 38px;
    word-break: break-all;
    transition: background 150ms ease-in;
    background-size: 100%;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiDatePopoverButton {
        line-height: 1em;
    }
}

.euiDatePopoverButton::-webkit-input-placeholder {
    color: #69707d;
}

.euiDatePopoverButton::-moz-placeholder {
    color: #69707d;
}

.euiDatePopoverButton:-ms-input-placeholder {
    color: #69707d;
}

.euiDatePopoverButton:-moz-placeholder {
    color: #69707d;
}

.euiDatePopoverButton::placeholder {
    color: #69707d;
}

.euiDatePopoverButton-needsUpdating {
    background-color: #e6f2f1;
    color: #01776d;
}

.euiDatePopoverButton-needsUpdating:focus,
.euiDatePopoverButton-needsUpdating.euiDatePopoverButton-isSelected {
    background-image: linear-gradient(
        to top,
        #017d73,
        #017d73 2px,
        transparent 2px,
        transparent 100%
    );
}

.euiDatePopoverButton:disabled {
    background-color: #eef2f7;
    color: #69707d;
    cursor: default;
}

.euiDatePopoverButton--start {
    text-align: right;
}

.euiDatePopoverButton--end {
    text-align: left;
}

.euiDatePopoverContent {
    width: 400px;
    max-width: 100%;
}

.euiDatePopoverContent__padded {
    padding: 8px;
}

.euiDatePopoverContent__padded--large {
    padding: 16px;
}

@media only screen and (max-width: 574px) {
    .euiDatePopoverContent {
        width: 284px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiDatePopoverContent {
        width: 284px;
    }
}

.euiQuickSelectPopover__content {
    width: 400px;
    max-width: 100%;
}

.euiQuickSelectPopover__section {
    scrollbar-width: thin;
    max-height: 132px;
    overflow: hidden;
    overflow-y: auto;
    padding: 8px 0 4px;
}

.euiQuickSelectPopover__section::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiQuickSelectPopover__section::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiQuickSelectPopover__section::-webkit-scrollbar-corner,
.euiQuickSelectPopover__section::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiQuickSelectPopover__buttonText {
    margin-right: 4px !important;
}

.euiQuickSelectPopover__anchor {
    height: 100%;
}

.euiQuickSelectPopover__sectionItem {
    font-size: 14px;
    line-height: 14px;
}

.euiQuickSelectPopover__sectionItem:not(:last-of-type) {
    margin-bottom: 8px;
}

.euiQuickSelect__applyButton {
    min-width: 0;
}

.euiRefreshInterval__startButton {
    min-width: 90px;
}

.euiSuperDatePicker__flexWrapper {
    max-width: calc(100% + 8px);
    width: 606px;
}

.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly {
    width: 400px;
}

.euiSuperDatePicker__flexWrapper--noUpdateButton {
    width: 480px;
}

.euiSuperDatePicker {
    max-width: 100% !important;
}

.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper {
    flex: 1 1 100%;
    overflow: hidden;
}

.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper > .euiDatePickerRange {
    max-width: none;
    width: auto;
    border-radius: 0 0 0 0;
}

.euiSuperDatePicker__startPopoverButton {
    margin-right: -12px;
}

.euiSuperDatePicker__prettyFormat {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    display: block;
    width: 100%;
    padding: 0 8px;
    line-height: 38px;
    height: 38px;
    word-break: break-all;
    transition: background 150ms ease-in;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiSuperDatePicker__prettyFormat {
        line-height: 1em;
    }
}

.euiSuperDatePicker__prettyFormat::-webkit-input-placeholder {
    color: #69707d;
}

.euiSuperDatePicker__prettyFormat::-moz-placeholder {
    color: #69707d;
}

.euiSuperDatePicker__prettyFormat:-ms-input-placeholder {
    color: #69707d;
}

.euiSuperDatePicker__prettyFormat:-moz-placeholder {
    color: #69707d;
}

.euiSuperDatePicker__prettyFormat::placeholder {
    color: #69707d;
}

.euiSuperDatePicker__prettyFormat:not(:disabled):hover,
.euiSuperDatePicker__prettyFormat:focus {
    text-decoration: none;
}

.euiSuperDatePicker__prettyFormat:not(:disabled):hover .euiSuperDatePicker__prettyFormatLink,
.euiSuperDatePicker__prettyFormat:focus .euiSuperDatePicker__prettyFormatLink {
    text-decoration: underline;
}

.euiSuperDatePicker__prettyFormat:disabled {
    background-color: #eef2f7;
    color: #69707d;
    cursor: not-allowed;
}

.euiSuperDatePicker__prettyFormat:disabled .euiSuperDatePicker__prettyFormatLink {
    display: none;
}

@media only screen and (max-width: 574px) {
    .euiSuperDatePicker__flexWrapper {
        width: calc(100% + 8px);
    }

    .euiSuperDatePicker__prettyFormatLink {
        flex-shrink: 1;
        min-width: 3em;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiSuperDatePicker__flexWrapper {
        width: calc(100% + 8px);
    }

    .euiSuperDatePicker__prettyFormatLink {
        flex-shrink: 1;
        min-width: 3em;
    }
}

.euiSuperUpdateButton {
    min-width: 118px;
}

@media only screen and (max-width: 574px) {
    .euiSuperUpdateButton {
        min-width: 0;
    }

    .euiSuperUpdateButton .euiSuperUpdateButton__text {
        display: none;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiSuperUpdateButton {
        min-width: 0;
    }

    .euiSuperUpdateButton .euiSuperUpdateButton__text {
        display: none;
    }
}

.euiDataGrid {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
}

.euiDataGrid--fullScreen {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8000;
    background: #fff;
}

.euiDataGrid--fullScreen .euiDataGrid__pagination {
    padding-bottom: 4px;
    background: #f5f7fa;
    border-top: 1px solid #d3dae6;
}

.euiDataGrid--fullScreen .euiDataGrid__verticalScroll .euiDataGridRow {
    will-change: transform;
}

.euiDataGrid__content {
    height: 100%;
    font-feature-settings: 'tnum' 1;
    max-width: 100%;
    width: 100%;
    z-index: 2;
    background: #fff;
}

.euiDataGrid__controls {
    background: #fafbfd;
    position: relative;
    z-index: 3;
    border: 1px solid #d3dae6;
    padding: 4px;
    flex-grow: 0;
}

.euiDataGrid__controls > * {
    margin-left: 2px;
}

.euiDataGrid__controlBtn {
    border-radius: 4px;
}

.euiDataGrid__controlBtn:focus {
    background: #dddee1;
}

.euiDataGrid__controlBtn--active,
.euiDataGrid__controlBtn--active:focus {
    font-weight: 600;
    color: #000;
}

.euiDataGrid--bordersNone .euiDataGrid__controls {
    border: none;
    background: #fff;
}

.euiDataGrid--bordersHorizontal .euiDataGrid__controls {
    border-right: none;
    border-left: none;
    border-top: none;
    background: #fff;
}

.euiDataGrid__pagination {
    padding-top: 4px;
    flex-grow: 0;
}

.euiDataGrid__verticalScroll {
    flex-grow: 1;
    overflow-y: hidden;
    height: 100%;
}

.euiDataGrid__overflow {
    overflow-y: hidden;
    height: 100%;
    background: #fff;
}

.euiDataGrid__restrictBody {
    height: 100vh;
    overflow: hidden;
}

.euiDataGrid__controlScroll::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiDataGrid__controlScroll::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiDataGrid__controlScroll::-webkit-scrollbar-corner,
.euiDataGrid__controlScroll::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiDataGrid__controlScroll:focus {
    outline: none;
    /* 1 */
}

.euiDataGrid__focusWrap {
    height: 100%;
}

.euiDataGrid__virtualized {
    scrollbar-width: thin;
    scroll-padding: 0;
}

.euiDataGrid__virtualized::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiDataGrid__virtualized::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid #fff;
    background-clip: content-box;
}

.euiDataGrid__virtualized::-webkit-scrollbar-corner,
.euiDataGrid__virtualized::-webkit-scrollbar-track {
    background-color: #fff;
}

.euiDataGridHeader {
    display: inline-flex;
    min-width: 100%;
    z-index: 3;
    background: #fff;
    position: sticky;
    top: 0;
}

.euiDataGridHeaderCell {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 700;
    padding: 6px;
    flex: 0 0 auto;
    position: relative;
    align-items: center;
    display: flex;
}

.euiDataGridHeaderCell > * {
    max-width: 100%;
    width: 100%;
}

.euiDataGridHeaderCell.euiDataGridHeaderCell--numeric {
    text-align: right;
}

.euiDataGridHeaderCell.euiDataGridHeaderCell--currency {
    text-align: right;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__button {
    flex: 0 0 auto;
    position: relative;
    align-items: center;
    display: flex;
    text-align: left;
    font-weight: 700;
    outline: none;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn)
    .euiDataGridHeaderCell__sortingArrow {
    margin-right: 4px;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__content {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
}

.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__popover {
    flex-grow: 0;
    flex-basis: auto;
    width: auto;
    padding-left: 4px;
}

.euiDataGridHeader__action--selected {
    font-weight: 700 !important;
}

.euiDataGrid--bordersNone.euiDataGrid--bordersHorizontal .euiDataGridHeader {
    background: #fff;
}

.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #d3dae6;
    border-bottom-color: #343741;
}

.euiDataGrid--bordersNone.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
    border-bottom: 2px solid #d3dae6;
    border-color: #343741;
}

.euiDataGrid--headerShade .euiDataGridHeaderCell {
    background: #f5f7fa;
}

.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell {
    border-right: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
    border-left: none;
}

.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell:first-of-type {
    border-left: 1px solid #d3dae6;
}

.euiDataGrid--headerShade.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
    border-top: none;
    border-bottom: 1px solid #d3dae6;
}

.euiDataGrid--bordersNone .euiDataGridHeaderCell {
    border: none;
}

.euiDataGrid--borderhorizontal .euiDataGridHeaderCell {
    border-top: none;
    border-right: none;
    border-left: none;
}

.euiDataGrid--fontSizeSmall .euiDataGridHeaderCell {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiDataGrid--fontSizeLarge .euiDataGridHeaderCell {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
}

.euiDataGrid--paddingSmall .euiDataGridHeaderCell {
    padding: 4px;
}

.euiDataGrid--paddingLarge .euiDataGridHeaderCell {
    padding: 8px;
}

.euiDataGrid--noControls.euiDataGrid--bordersAll .euiDataGridHeaderCell {
    border-top: 1px solid #d3dae6;
}

.euiDataGrid--noControls.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
    border-top: 1px solid #d3dae6;
}

.euiDataGridRowCell.euiDataGridFooterCell {
    font-weight: 700;
}

.euiDataGrid--stickyFooter .euiDataGridFooter {
    position: sticky;
    bottom: 0;
}

.euiDataGrid--footerOverline .euiDataGridRowCell.euiDataGridFooterCell {
    border-top: 2px solid #d3dae6;
    border-top-color: #343741 !important;
    background: #fff !important;
}

.euiDataGrid--bordersNone .euiDataGridRowCell.euiDataGridFooterCell {
    border-left: none;
    border-right: none;
}

.euiDataGrid--bordersHorizontal .euiDataGridRowCell.euiDataGridFooterCell {
    border-left: none;
    border-right: none;
}

.euiDataGrid--footerShade .euiDataGridRowCell.euiDataGridFooterCell {
    background: #f5f7fa;
}

.euiDataGridColumnResizer {
    position: absolute;
    top: 0;
    right: -8px;
    height: 100%;
    width: 16px;
    cursor: ew-resize;
    opacity: 0;
    z-index: 2;
}

.euiDataGridColumnResizer:hover,
.euiDataGridColumnResizer:active {
    opacity: 1;
}

.euiDataGridColumnResizer:hover ~ .euiDataGridHeaderCell__content,
.euiDataGridColumnResizer:active ~ .euiDataGridHeaderCell__content {
    user-select: none;
}

.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer {
    right: 0;
    width: 8px;
}

.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer:after {
    left: auto;
    right: 0;
}

.euiDataGridRow {
    display: inline-flex;
    min-width: 100%;
}

.euiDataGridRowCell {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 6px;
    border-right: solid 1px #edf0f5;
    border-bottom: 1px solid #d3dae6;
    flex: 0 0 auto;
    background: #fff;
    position: relative;
    align-items: center;
    display: flex;
}

.euiDataGridRowCell > * {
    max-width: 100%;
    width: 100%;
}

.euiDataGridRowCell.euiDataGridRowCell--firstColumn {
    border-left: 1px solid #d3dae6;
}

.euiDataGridRowCell.euiDataGridRowCell--lastColumn {
    border-right-color: #d3dae6;
}

.euiDataGridRowCell:hover .euiDataGridRowCell__expandButtonIcon {
    animation-duration: 90ms;
    animation-name: euiDataGridCellButtonSlideIn;
    animation-iteration-count: 1;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
}

.euiDataGridRowCell:hover .euiDataGridRowCell__actionButtonIcon {
    animation-duration: 90ms;
    animation-name: euiDataGridCellButtonSlideIn;
    animation-iteration-count: 1;
    animation-delay: 250ms;
    animation-fill-mode: forwards;
}

.euiDataGridRowCell:not(:hover) .euiDataGridRowCell__expandButtonIcon,
.euiDataGridRowCell.euiDataGridRowCell--open .euiDataGridRowCell__expandButtonIcon {
    animation: none;
    margin-left: 6px;
    width: 12px;
}

.euiDataGridRowCell:not(:hover) .euiDataGridRowCell__actionButtonIcon,
.euiDataGridRowCell.euiDataGridRowCell--open .euiDataGridRowCell__actionButtonIcon {
    animation: none;
    margin-left: 6px;
    width: 12px;
}

.euiDataGridRowCell:focus .euiDataGridRowCell__actionButtonIcon {
    margin-left: 6px;
    width: 12px;
}

.euiDataGridRowCell:not(:hover):not(:focus):not(.euiDataGridRowCell--open)
    .euiDataGridRowCell__expandButtonIcon,
.euiDataGridRowCell:not(:hover):not(:focus):not(.euiDataGridRowCell--open)
    .euiDataGridRowCell__actionButtonIcon {
    display: none;
}

.euiDataGridRowCell:focus:not(:first-of-type) {
    padding-left: 5px;
}

.euiDataGridRowCell.euiDataGridRowCell--numeric {
    text-align: right;
}

.euiDataGridRowCell.euiDataGridRowCell--currency {
    text-align: right;
}

.euiDataGridRowCell.euiDataGridRowCell--boolean {
    text-transform: capitalize;
}

.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__content,
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__truncate,
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn).euiDataGridRowCell__truncate,
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__expandContent {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    overflow: hidden;
    white-space: nowrap;
}

.euiDataGridRowCell__popover {
    scrollbar-width: thin;
    overflow: auto;
    max-width: 400px !important;
    max-height: 400px !important;
}

.euiDataGridRowCell__popover::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiDataGridRowCell__popover::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiDataGridRowCell__popover::-webkit-scrollbar-corner,
.euiDataGridRowCell__popover::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiDataGridRowCell__expand {
    width: 100%;
    max-width: 100%;
}

.euiDataGridRowCell__expandFlex {
    display: flex;
    align-items: center;
}

.euiDataGridRowCell__expandContent {
    flex-grow: 1;
}

.euiDataGridRowCell__expandButton {
    display: flex;
    flex-grow: 0;
}

.euiDataGridRowCell__expandButtonIcon {
    height: 12px;
    border-radius: 2px;
    width: 0;
    overflow: hidden;
    transition: none;
}

.euiDataGridRowCell__expandButtonIcon-isActive {
    margin-left: 6px;
    width: 12px;
}

.euiDataGridRowCell__actionButtonIcon {
    height: 12px;
    border-radius: 2px;
    width: 0;
    overflow: hidden;
    transition: none;
}

.euiDataGrid--rowHoverHighlight .euiDataGridRow:hover .euiDataGridRowCell {
    background-color: #fffcdd !important;
}

.euiDataGrid--stripes .euiDataGridRowCell.euiDataGridRowCell--stripe {
    background: #f5f7fa;
}

.euiDataGrid--bordersNone .euiDataGridRowCell {
    border-color: transparent !important;
}

.euiDataGrid--bordersHorizontal .euiDataGridRowCell {
    border-right-color: transparent;
    border-left-color: transparent;
}

.euiDataGrid--fontSizeSmall .euiDataGridRowCell {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiDataGrid--fontSizeLarge .euiDataGridRowCell {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
}

.euiDataGrid--paddingSmall .euiDataGridRowCell {
    padding: 4px;
}

.euiDataGrid--paddingSmall .euiDataGridRowCell:focus:not(:first-of-type) {
    padding-left: 3px;
}

.euiDataGrid--paddingLarge .euiDataGridRowCell {
    padding: 8px;
}

.euiDataGrid--paddingLarge .euiDataGridRowCell:focus:not(:first-of-type) {
    padding-left: 7px;
}

@keyframes euiDataGridCellButtonSlideIn {
    from {
        margin-left: 0;
        width: 0;
    }
    to {
        margin-left: 6px;
        width: 12px;
    }
}

.euiDataGridColumnSelector__item {
    padding: 4px;
}

.euiDataGridColumnSelector__item-isDragging {
    box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    background: #fff;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiDataGridColumnSelector__columnList::-webkit-scrollbar-corner,
.euiDataGridColumnSelector__columnList::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiDataGridColumnSelector__columnList:focus {
    outline: none;
    /* 1 */
}

.euiDataGridColumnSelector__itemLabel {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiDataGridColumnSelectorPopover {
    transform: none !important;
    transition: none !important;
    margin-top: -8px;
    min-width: 192px;
}

.euiDataGridColumnSorting__item-isDragging {
    box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    background: #fff;
}

.euiDataGridColumnSortingPopover {
    transform: none !important;
    transition: none !important;
    margin-top: -8px;
    min-width: 192px;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-corner,
.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiDataGridColumnSorting__fieldList:focus {
    outline: none;
    /* 1 */
}

.euiDataGridColumnSorting__field {
    display: block;
    padding: 4px 8px;
    width: 100%;
    outline-offset: -3px;
}

.euiDataGridColumnSorting__field:hover {
    cursor: pointer;
    text-decoration: underline;
}

.euiDataGridColumnSorting__field:focus {
    cursor: pointer;
    text-decoration: underline;
    background-color: #fae9ea;
}

.euiDataGridColumnSorting__field:disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #afb0b3;
}

.euiDataGridColumnSorting__orderButtons {
    padding-left: 24px;
}

.euiDataGridColumnSorting__orderButtons .euiDataGridColumnSorting__order {
    min-width: 200px;
    border: none;
}

.euiDataGridColumnSorting__orderButtons .euiDataGridColumnSorting__order button {
    font-size: 12px !important;
}

.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1.5;
    margin-top: 16px;
}

.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__title:first-of-type {
    margin-top: 0;
}

.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--center {
    text-align: center;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--reverse .euiDescriptionList__title {
    color: #343741;
    font-weight: 400;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--reverse
    .euiDescriptionList__description {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed
    .euiDescriptionList__title {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed
    .euiDescriptionList__description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__title {
    color: #343741;
    font-weight: 400;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__description {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column,
.euiDescriptionList.euiDescriptionList--responsiveColumn {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
}

.euiDescriptionList.euiDescriptionList--column > *,
.euiDescriptionList.euiDescriptionList--responsiveColumn > * {
    margin-top: 16px;
}

.euiDescriptionList.euiDescriptionList--column > *:first-child,
.euiDescriptionList.euiDescriptionList--column > :nth-child(2),
.euiDescriptionList.euiDescriptionList--responsiveColumn > *:first-child,
.euiDescriptionList.euiDescriptionList--responsiveColumn > :nth-child(2) {
    margin-top: 0;
}

.euiDescriptionList.euiDescriptionList--column .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1.5;
    width: 50%;
    padding-right: 8px;
}

.euiDescriptionList.euiDescriptionList--column .euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    width: 50%;
    padding-left: 8px;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--center
    .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
    .euiDescriptionList__title {
    text-align: right;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--reverse
    .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
    .euiDescriptionList__title {
    color: #343741;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--reverse
    .euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
    .euiDescriptionList__description {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed
    .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed
    .euiDescriptionList__title {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed
    .euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed
    .euiDescriptionList__description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__title {
    color: #343741;
    font-weight: 400;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed.euiDescriptionList--reverse
    .euiDescriptionList__description {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    line-height: 1.5;
}

@media only screen and (max-width: 574px) {
    .euiDescriptionList.euiDescriptionList--responsiveColumn {
        display: block;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title,
    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
        width: 100%;
        padding: 0;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-top: 0;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
        .euiDescriptionList__title,
    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
        .euiDescriptionList__description {
        text-align: center;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
        .euiDescriptionList__title {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
        .euiDescriptionList__description {
        color: #1a1c21;
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        letter-spacing: -0.02em;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiDescriptionList.euiDescriptionList--responsiveColumn {
        display: block;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title,
    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
        width: 100%;
        padding: 0;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-top: 0;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
        .euiDescriptionList__title,
    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
        .euiDescriptionList__description {
        text-align: center;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
        .euiDescriptionList__title {
        font-size: 14px;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
        .euiDescriptionList__description {
        color: #1a1c21;
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        letter-spacing: -0.02em;
    }
}

.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__title {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    display: inline;
    border-radius: 4px;
    font-weight: 400;
    background: #f5f7fa;
    border: 1px solid #d3dae6;
    padding: 0 4px;
    margin: 0 4px;
}

.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__title:first-of-type {
    margin-left: 0;
}

.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__description {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    display: inline;
    word-break: break-all;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--compressed
    .euiDescriptionList__title {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--compressed
    .euiDescriptionList__description {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--center {
    text-align: center;
}

.euiDraggable.euiDraggable--isDragging {
    z-index: 9000 !important;
}

.euiDraggable.euiDraggable--hasClone:not(.euiDraggable--isDragging) {
    transform: none !important;
}

.euiDraggable.euiDraggable--withoutDropAnimation {
    transition-duration: 0.001s !important;
}

.euiDraggable:focus > .euiDraggable__item,
.euiDraggable.euiDraggable--hasCustomDragHandle
    > .euiDraggable__item
    [data-react-beautiful-dnd-drag-handle]:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiDraggable .euiDraggable__item.euiDraggable__item--isDisabled {
    cursor: not-allowed;
}

.euiDraggable--s {
    padding: 2px;
}

.euiDraggable--m {
    padding: 4px;
}

.euiDraggable--l {
    padding: 8px;
}

.euiDroppable {
    transition: background-color 500ms ease;
}

.euiDroppable.euiDroppable--isDraggingType:not(.euiDroppable--isDisabled) {
    background-color: rgba(1, 125, 115, 0.1);
}

.euiDroppable.euiDroppable--isDraggingType:not(.euiDroppable--isDisabled).euiDroppable--isDraggingOver {
    background-color: rgba(1, 125, 115, 0.25);
}

.euiDroppable .euiDroppable__placeholder.euiDroppable__placeholder--isHidden {
    display: none !important;
}

.euiDroppable--withPanel {
    background-color: #fff;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    flex-grow: 1;
}

.euiDroppable--withPanel.euiDroppable--withPanel--flexGrowZero {
    flex-grow: 0;
}

.euiDroppable--withPanel.euiDroppable--withPanel--noBorder {
    border: none;
}

.euiDroppable--withPanel.euiDroppable--withPanel--hasShadow {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    border: 1px solid #d3dae6;
}

.euiDroppable--withPanel.euiDroppable--withPanel--isClickable {
    display: block;
    width: 100%;
    text-align: left;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiDroppable--withPanel.euiDroppable--withPanel--isClickable:hover,
.euiDroppable--withPanel.euiDroppable--withPanel--isClickable:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    transform: translateY(-2px);
    cursor: pointer;
}

.euiDroppable--withPanel.euiDroppable--withPanel--borderRadiusNone {
    border-radius: 0;
}

.euiDroppable--withPanel.euiDroppable--withPanel--borderRadiusMedium {
    border-radius: 4px;
}

.euiDroppable--withPanel.euiDroppable--withPanel--transparent {
    background-color: transparent;
}

.euiDroppable--withPanel.euiDroppable--withPanel--plain {
    background-color: #fff;
}

.euiDroppable--withPanel.euiDroppable--withPanel--subdued {
    background-color: #fafbfd;
}

.euiDroppable--withPanel.euiDroppable--withPanel--accent {
    background-color: #fce7f1;
}

.euiDroppable--withPanel.euiDroppable--withPanel--success {
    background-color: #e6f2f1;
}

.euiDroppable--withPanel.euiDroppable--withPanel--warning {
    background-color: #fef6e6;
}

.euiDroppable--withPanel.euiDroppable--withPanel--danger {
    background-color: #f8e9e9;
}

.euiDroppable--withPanel {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
    border-radius: 4px;
}

.euiDroppable--noGrow {
    flex-grow: 0;
}

.euiDroppable--grow {
    flex-grow: 1;
}

.euiDroppable--s {
    padding: 2px;
}

.euiDroppable--m {
    padding: 4px;
}

.euiDroppable--l {
    padding: 8px;
}

.euiEmptyPrompt {
    max-width: 36em;
    text-align: center;
    padding: 24px;
    margin: auto;
}

.euiErrorBoundary {
    background: repeating-linear-gradient(
        45deg,
        rgba(189, 39, 30, 0.25),
        rgba(189, 39, 30, 0.25) 1px,
        rgba(189, 39, 30, 0.05) 1px,
        rgba(189, 39, 30, 0.05) 20px
    );
    overflow: auto;
    padding: 16px;
}

.euiErrorBoundary__text {
    background-color: #fff;
    padding: 8px;
}

.euiErrorBoundary__stack {
    white-space: pre-wrap;
}

/**
 * 1. Keep each expression's text together as much as possible,
 *    but then wrap long words
 */
.euiExpression {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    /* 1 */
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: normal;
    border-bottom: 2px solid transparent;
    display: inline-block;
    /* 1 */
    text-align: left;
    padding: 2px 0;
    transition: all 250ms ease-in-out;
    color: #343741;
}

.euiExpression:focus {
    border-bottom-style: solid;
}

.euiExpression + .euiExpression {
    margin-left: 8px;
}

.euiExpression.euiExpression--columns {
    border-color: transparent;
    border-bottom-style: solid;
    margin-bottom: 4px;
}

.euiExpression.euiExpression--truncate {
    max-width: 100%;
}

.euiExpression.euiExpression--truncate .euiExpression__description,
.euiExpression.euiExpression--truncate .euiExpression__value {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    display: inline-block;
    vertical-align: bottom;
}

.euiExpression-isUppercase .euiExpression__description {
    text-transform: uppercase;
}

.euiExpression-isClickable {
    cursor: pointer;
    border-bottom: 2px dotted #d3dae6;
}

.euiExpression-isClickable:hover:not(:disabled) {
    border-bottom-style: solid;
    transform: translateY(-1px);
}

.euiExpression__icon {
    margin-left: 4px;
}

.euiExpression-isActive {
    border-bottom-style: solid;
}

.euiExpression--columns {
    width: 100%;
    display: flex;
    padding: 4px;
    border-radius: 4px;
}

.euiExpression--columns.euiExpression-isClickable {
    background-color: #f5f7fa;
}

.euiExpression--columns.euiExpression-isClickable:focus .euiExpression__description,
.euiExpression--columns.euiExpression-isClickable:focus .euiExpression__value,
.euiExpression--columns.euiExpression-isClickable:hover:not(:disabled) .euiExpression__description,
.euiExpression--columns.euiExpression-isClickable:hover:not(:disabled) .euiExpression__value {
    text-decoration: underline;
}

.euiExpression--columns .euiExpression__description {
    text-align: right;
    margin-right: 8px;
    flex-shrink: 0;
}

.euiExpression--columns .euiExpression__value {
    flex-grow: 1;
}

.euiExpression--columns .euiExpression__icon {
    margin-top: 4px;
}

.euiExpression--subdued:focus {
    background-color: rgba(106, 113, 125, 0.1);
}

.euiExpression--subdued.euiExpression-isActive {
    border-bottom-color: #6a717d;
    border-color: #6a717d;
}

.euiExpression--subdued .euiExpression__description {
    color: #6a717d;
}

.euiExpression--secondary:focus {
    background-color: rgba(1, 125, 115, 0.1);
}

.euiExpression--secondary.euiExpression-isActive {
    border-bottom-color: #017d73;
    border-color: #017d73;
}

.euiExpression--secondary .euiExpression__description {
    color: #017d73;
}

.euiExpression--warning:focus {
    background-color: rgba(155, 105, 0, 0.1);
}

.euiExpression--warning.euiExpression-isActive {
    border-bottom-color: #9b6900;
    border-color: #9b6900;
}

.euiExpression--warning .euiExpression__description {
    color: #9b6900;
}

.euiExpression--danger:focus {
    background-color: rgba(189, 39, 30, 0.1);
}

.euiExpression--danger.euiExpression-isActive {
    border-bottom-color: #bd271e;
    border-color: #bd271e;
}

.euiExpression--danger .euiExpression__description {
    color: #bd271e;
}

.euiExpression--accent:focus {
    background-color: rgba(221, 10, 115, 0.1);
}

.euiExpression--accent.euiExpression-isActive {
    border-bottom-color: #dd0a73;
    border-color: #dd0a73;
}

.euiExpression--accent .euiExpression__description {
    color: #dd0a73;
}

/**
 * 1. Make sure the quantity doesn't get an underline on hover
 */
.euiFacetButton {
    display: inline-block;
    appearance: none;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    text-align: center;
    white-space: nowrap;
    max-width: 100%;
    vertical-align: middle;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    height: 32px;
    text-align: left;
    text-decoration: none;
    transition: all 150ms ease-in;
}

.euiFacetButton:hover:not(:disabled) .euiFacetButton__text,
.euiFacetButton:focus:not(:disabled) .euiFacetButton__text {
    text-decoration: underline;
    /* 1 */
}

.euiFacetButton:focus {
    background-color: #fae9ea;
    box-shadow: -4px 0 #fae9ea, 4px 0 #fae9ea;
}

.euiFacetButton:disabled {
    color: #afb0b3;
    pointer-events: none;
}

.euiFacetButton:disabled .euiFacetButton__content {
    pointer-events: auto;
    cursor: not-allowed;
}

.euiFacetButton:disabled .euiFacetButton__icon,
.euiFacetButton:disabled .euiFacetButton__quantity {
    opacity: 0.5;
}

.euiFacetButton:disabled:focus {
    background-color: transparent;
}

.euiFacetButton:disabled:hover,
.euiFacetButton:disabled:focus {
    text-decoration: none;
}

.euiFacetButton__content {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
}

.euiFacetButton__content .euiButtonContent__icon,
.euiFacetButton__content .euiButtonContent__spinner {
    flex-shrink: 0;
}

.euiFacetButton__content > * + * {
    margin-inline-start: 8px;
}

.euiFacetButton__text {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    flex-grow: 1;
    vertical-align: middle;
}

.euiFacetButton__text::after {
    display: block;
    content: attr(data-text);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.euiFacetButton--isSelected .euiFacetButton__text {
    font-weight: 700;
}

.euiFacetButton__icon {
    transition: all 150ms ease-in;
}

.euiFacetGroup--gutterNone .euiFacetButton {
    margin-top: 0;
    margin-bottom: 0;
}

.euiFacetGroup--gutterNone.euiFacetGroup--horizontal {
    margin-left: -12px;
}

.euiFacetGroup--gutterNone.euiFacetGroup--horizontal .euiFacetButton {
    margin-left: 12px;
    max-width: calc(100% - 12px);
}

.euiFacetGroup--gutterSmall .euiFacetButton {
    margin-top: 2px;
    margin-bottom: 2px;
}

.euiFacetGroup--gutterSmall.euiFacetGroup--horizontal {
    margin-left: -16px;
}

.euiFacetGroup--gutterSmall.euiFacetGroup--horizontal .euiFacetButton {
    margin-left: 16px;
    max-width: calc(100% - 16px);
}

.euiFacetGroup--gutterMedium .euiFacetButton {
    margin-top: 4px;
    margin-bottom: 4px;
}

.euiFacetGroup--gutterMedium.euiFacetGroup--horizontal {
    margin-left: -20px;
}

.euiFacetGroup--gutterMedium.euiFacetGroup--horizontal .euiFacetButton {
    margin-left: 20px;
    max-width: calc(100% - 20px);
}

.euiFacetGroup--gutterLarge .euiFacetButton {
    margin-top: 6px;
    margin-bottom: 6px;
}

.euiFacetGroup--gutterLarge.euiFacetGroup--horizontal {
    margin-left: -24px;
}

.euiFacetGroup--gutterLarge.euiFacetGroup--horizontal .euiFacetButton {
    margin-left: 24px;
    max-width: calc(100% - 24px);
}

.euiFilterGroup {
    display: inline-flex;
    max-width: 100%;
    border-right: 1px solid rgba(133, 68, 43, 0.1);
    box-shadow: 0 1px 2px -1px rgba(152, 162, 179, 0.2), 0 3px 3px -2px rgba(152, 162, 179, 0.2);
    overflow: hidden;
}

.euiFilterGroup > * {
    flex: 1 1 auto;
    min-width: 48px;
}

.euiFilterGroup > .euiFilterButton--noGrow {
    flex-grow: 0;
}

.euiFilterGroup > .euiFilterButton-hasNotification {
    min-width: 96px;
}

.euiFilterGroup > .euiFilterButton--hasIcon {
    min-width: 128px;
}

.euiFilterGroup .euiPopover__anchor {
    display: block;
}

.euiFilterGroup .euiPopover__anchor .euiFilterButton {
    width: 100%;
}

.euiFilterGroup--fullWidth {
    display: flex;
}

.euiFilterGroup__popoverPanel {
    width: 288px;
}

@media only screen and (max-width: 574px) {
    .euiFilterGroup {
        flex-wrap: wrap;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiFilterGroup {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 574px) {
    .euiFilterGroup {
        display: flex;
    }

    .euiFilterGroup .euiFilterButton {
        flex-grow: 1 !important;
    }
}

.euiFilterButton {
    background-color: #fbfcfd;
    height: 40px;
    width: auto;
    border: 1px solid rgba(133, 68, 43, 0.1);
    border-right: none;
    font-size: 14px;
}

.euiFilterButton:disabled {
    color: #afb0b3;
    pointer-events: none;
}

.euiFilterButton:disabled .euiFilterButton__notification {
    opacity: 0.5;
}

.euiFilterButton:hover:not(:disabled),
.euiFilterButton:focus:not(:disabled) {
    text-decoration: none;
}

.euiFilterButton:hover:not(:disabled) .euiFilterButton__textShift,
.euiFilterButton:focus:not(:disabled) .euiFilterButton__textShift {
    text-decoration: underline;
}

.euiFilterButton-hasActiveFilters {
    font-weight: 700;
}

.euiFilterButton--hasIcon .euiButtonEmpty__content {
    justify-content: space-between;
}

.euiFilterButton--withNext + .euiFilterButton {
    margin-left: -4px;
    border-left: none;
}

.euiFilterButton-isSelected {
    background-color: #f5f7fa;
}

.euiFilterButton__text-hasNotification {
    display: flex;
    align-items: center;
}

.euiFilterButton__notification {
    margin-left: 8px;
    vertical-align: text-bottom;
}

.euiFilterButton__textShift {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    min-width: 48px;
}

.euiFilterButton__textShift::after {
    display: block;
    content: attr(data-text);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

.euiFilterSelectItem {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 4px 12px;
    display: block;
    width: 100%;
    text-align: left;
    color: #343741;
    border-bottom: 1px solid #d3dae6;
    border-color: #eef2f7;
    outline-offset: -3px;
}

.euiFilterSelectItem:hover {
    cursor: pointer;
    text-decoration: underline;
}

.euiFilterSelectItem:focus {
    cursor: pointer;
    text-decoration: underline;
    background-color: #fae9ea;
}

.euiFilterSelectItem:disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #afb0b3;
}

.euiFilterSelectItem__content {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

.euiFilterSelect__items {
    scrollbar-width: thin;
    overflow-y: auto;
    max-height: 480px;
}

.euiFilterSelect__items::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiFilterSelect__items::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiFilterSelect__items::-webkit-scrollbar-corner,
.euiFilterSelect__items::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiFilterSelect__note {
    height: 64px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.euiFilterSelect__noteContent {
    color: #69707d;
    font-size: 14px;
}

/**
 * 1. Not always needed, but fixes an IE11 issue when flex-groups are nested under display: flex elements.
 * 2. IE requires a unit to grow.
 */
.euiFlexGroup {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    /* 1 */
}

.euiFlexGroup .euiFlexItem {
    flex-grow: 1;
    flex-basis: 0%;
    /* 2 */
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFlexGroup .euiFlexItem {
        min-width: 1px;
    }
}

.euiFlexGroup--gutterExtraSmall {
    margin: -2px;
}

.euiFlexGroup--gutterExtraSmall > .euiFlexItem {
    margin: 2px;
}

.euiFlexGroup--gutterSmall {
    margin: -4px;
}

.euiFlexGroup--gutterSmall > .euiFlexItem {
    margin: 4px;
}

.euiFlexGroup--gutterMedium {
    margin: -8px;
}

.euiFlexGroup--gutterMedium > .euiFlexItem {
    margin: 8px;
}

.euiFlexGroup--gutterLarge {
    margin: -12px;
}

.euiFlexGroup--gutterLarge > .euiFlexItem {
    margin: 12px;
}

.euiFlexGroup--gutterExtraLarge {
    margin: -20px;
}

.euiFlexGroup--gutterExtraLarge > .euiFlexItem {
    margin: 20px;
}

.euiFlexGroup--justifyContentSpaceEvenly {
    justify-content: space-evenly;
}

.euiFlexGroup--justifyContentSpaceBetween {
    justify-content: space-between;
}

.euiFlexGroup--justifyContentSpaceAround {
    justify-content: space-around;
}

.euiFlexGroup--justifyContentCenter {
    justify-content: center;
}

.euiFlexGroup--justifyContentFlexEnd {
    justify-content: flex-end;
}

.euiFlexGroup--alignItemsFlexStart {
    align-items: flex-start;
}

.euiFlexGroup--alignItemsCenter {
    align-items: center;
}

.euiFlexGroup--alignItemsFlexEnd {
    align-items: flex-end;
}

.euiFlexGroup--alignItemsBaseline {
    align-items: baseline;
}

.euiFlexGroup--directionRow {
    flex-direction: row;
}

.euiFlexGroup--directionRowReverse {
    flex-direction: row-reverse;
}

.euiFlexGroup--directionColumn {
    flex-direction: column;
}

.euiFlexGroup--directionColumnReverse {
    flex-direction: column-reverse;
}

.euiFlexGroup--wrap {
    flex-wrap: wrap;
}

@media only screen and (max-width: 574px) {
    .euiFlexGroup--responsive {
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiFlexGroup--responsive {
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
    }
}

.euiFlexGrid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
}

.euiFlexGrid > .euiFlexItem {
    flex-grow: 0;
}

.euiFlexGrid > .euiFlexItem.euiFlexItem--flexGrowZero {
    flex-grow: 0 !important;
    flex-basis: auto !important;
}

/**
  * 1. For vertical layouts we use columns instead of flex
  */
.euiFlexGrid--directionColumn {
    display: block;
    /* 1 */
    column-gap: 0;
}

.euiFlexGrid--directionColumn > .euiFlexItem {
    display: inline-block;
    /* 1 */
    line-height: initial;
}

/**
 * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
 * without columns.
 */
.euiFlexGrid--gutterNone {
    margin: 0px;
    align-items: stretch;
}

.euiFlexGrid--gutterNone > .euiFlexItem {
    margin: 0px;
}

.euiFlexGrid--gutterNone.euiFlexGrid--fourths > .euiFlexItem {
    flex-basis: calc(25% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 4;
}

.euiFlexGrid--gutterNone.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--thirds > .euiFlexItem {
    flex-basis: calc(33.3% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 3;
}

.euiFlexGrid--gutterNone.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--halves > .euiFlexItem {
    flex-basis: calc(50% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--halves.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 2;
}

.euiFlexGrid--gutterNone.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--single > .euiFlexItem {
    flex-basis: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--single.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 1;
}

.euiFlexGrid--gutterNone.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 0px);
}

/**
 * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
 * without columns.
 */
.euiFlexGrid--gutterSmall {
    margin: -4px;
    align-items: stretch;
}

.euiFlexGrid--gutterSmall > .euiFlexItem {
    margin: 4px;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--fourths > .euiFlexItem {
    flex-basis: calc(25% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 4;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--thirds > .euiFlexItem {
    flex-basis: calc(33.3% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 3;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--halves > .euiFlexItem {
    flex-basis: calc(50% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--halves.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 2;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--single > .euiFlexItem {
    flex-basis: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--single.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 1;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 8px);
}

/**
 * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
 * without columns.
 */
.euiFlexGrid--gutterMedium {
    margin: -8px;
    align-items: stretch;
}

.euiFlexGrid--gutterMedium > .euiFlexItem {
    margin: 8px;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--fourths > .euiFlexItem {
    flex-basis: calc(25% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 4;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--thirds > .euiFlexItem {
    flex-basis: calc(33.3% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 3;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--halves > .euiFlexItem {
    flex-basis: calc(50% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--halves.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 2;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--single > .euiFlexItem {
    flex-basis: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--single.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 1;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 16px);
}

/**
 * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
 * without columns.
 */
.euiFlexGrid--gutterLarge {
    margin: -12px;
    align-items: stretch;
}

.euiFlexGrid--gutterLarge > .euiFlexItem {
    margin: 12px;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--fourths > .euiFlexItem {
    flex-basis: calc(25% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 4;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--thirds > .euiFlexItem {
    flex-basis: calc(33.3% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 3;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--halves > .euiFlexItem {
    flex-basis: calc(50% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 2;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--single > .euiFlexItem {
    flex-basis: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--single.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 1;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 24px);
}

/**
 * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
 * without columns.
 */
.euiFlexGrid--gutterXLarge {
    margin: -16px;
    align-items: stretch;
}

.euiFlexGrid--gutterXLarge > .euiFlexItem {
    margin: 16px;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths > .euiFlexItem {
    flex-basis: calc(25% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 4;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds > .euiFlexItem {
    flex-basis: calc(33.3% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 3;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--halves > .euiFlexItem {
    flex-basis: calc(50% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 2;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--single > .euiFlexItem {
    flex-basis: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--single.euiFlexGrid--directionColumn {
    /* 1 */
    column-count: 1;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
    width: calc(100% - 32px);
}

@media only screen and (max-width: 574px) {
    .euiFlexGrid.euiFlexGrid--responsive {
        margin-left: 0 !important;
        margin-right: 0 !important;
        column-count: 1 !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiFlexGrid.euiFlexGrid--responsive {
        margin-left: 0 !important;
        margin-right: 0 !important;
        column-count: 1 !important;
    }
}

/**
 * 1. Allow EuiPanels to expand to fill the item.
 * 2. IE11 hack forces inner content of flex items to respect a higher parent's width (mostly) and
 *    not cause weird wrapping issues.
 */
.euiFlexItem {
    display: flex;
    /* 1 */
    flex-direction: column;
    /* 1 */
    /*
     * 1. We need the extra specificity here to override the FlexGroup > FlexItem styles.
     * 2. FlexItem can be manually set to not grow if needed.
     */
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFlexItem {
        min-width: 1px;
        /* 2 */
    }
}

.euiFlexItem.euiFlexItem--flexGrowZero {
    /* 1 */
    flex-grow: 0;
    /* 2 */
    flex-basis: auto;
    /* 2 */
}

.euiFlexItem.euiFlexItem--flexGrow1 {
    flex-grow: 1;
}

.euiFlexItem.euiFlexItem--flexGrow2 {
    flex-grow: 2;
}

.euiFlexItem.euiFlexItem--flexGrow3 {
    flex-grow: 3;
}

.euiFlexItem.euiFlexItem--flexGrow4 {
    flex-grow: 4;
}

.euiFlexItem.euiFlexItem--flexGrow5 {
    flex-grow: 5;
}

.euiFlexItem.euiFlexItem--flexGrow6 {
    flex-grow: 6;
}

.euiFlexItem.euiFlexItem--flexGrow7 {
    flex-grow: 7;
}

.euiFlexItem.euiFlexItem--flexGrow8 {
    flex-grow: 8;
}

.euiFlexItem.euiFlexItem--flexGrow9 {
    flex-grow: 9;
}

.euiFlexItem.euiFlexItem--flexGrow10 {
    flex-grow: 10;
}

@media only screen and (max-width: 574px) {
    .euiFlexGroup--responsive > .euiFlexItem,
    .euiFlexGrid--responsive > .euiFlexItem {
        width: 100% !important;
        flex-basis: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 16px !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiFlexGroup--responsive > .euiFlexItem,
    .euiFlexGrid--responsive > .euiFlexItem {
        width: 100% !important;
        flex-basis: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 16px !important;
    }
}

.euiFlyout {
    border-left: 1px solid #d3dae6;
    box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
        0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    border-color: #c6cad1;
    border-top-color: #e3e4e8;
    border-bottom-color: #aaafba;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    z-index: 3000;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    clip-path: polygon(-20% 0, 100% 0, 100% 100%, -20% 100%);
    animation: euiFlyout 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiFlyout__closeButton {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 3;
}

.euiFlyoutBody__banner {
    overflow-x: hidden;
}

/**
 * 1. Calculating the minimum width based on the screen takeover breakpoint
 */
.euiFlyout--small {
    min-width: 384px;
    width: 25vw;
}

.euiFlyout--small.euiFlyout--maxWidth-default {
    max-width: 403px;
}

.euiFlyout--medium {
    min-width: 424px;
    width: 50vw;
}

.euiFlyout--medium.euiFlyout--maxWidth-default {
    max-width: 768px;
}

.euiFlyout--large {
    min-width: 691px;
    width: 75vw;
}

.euiFlyout--large.euiFlyout--maxWidth-default {
    max-width: 992px;
}

.euiFlyout--paddingNone .euiFlyoutHeader {
    padding: 0 0 0;
}

.euiFlyout--paddingNone .euiFlyoutHeader--hasBorder {
    padding-bottom: 0;
}

.euiFlyout--paddingNone .euiFlyoutBody__overflowContent {
    padding: 0;
}

.euiFlyout--paddingNone .euiFlyoutBody__banner .euiCallOut {
    padding-left: 0;
    padding-right: 0;
}

.euiFlyout--paddingNone .euiFlyoutFooter {
    padding: 0;
}

.euiFlyout--paddingSmall .euiFlyoutHeader {
    padding: 8px 8px 0;
}

.euiFlyout--paddingSmall .euiFlyoutHeader--hasBorder {
    padding-bottom: 8px;
}

.euiFlyout--paddingSmall .euiFlyoutBody__overflowContent {
    padding: 8px;
}

.euiFlyout--paddingSmall .euiFlyoutBody__banner .euiCallOut {
    padding-left: 8px;
    padding-right: 8px;
}

.euiFlyout--paddingSmall .euiFlyoutFooter {
    padding: 8px;
}

.euiFlyout--paddingMedium .euiFlyoutHeader {
    padding: 16px 16px 0;
}

.euiFlyout--paddingMedium .euiFlyoutHeader--hasBorder {
    padding-bottom: 16px;
}

.euiFlyout--paddingMedium .euiFlyoutBody__overflowContent {
    padding: 16px;
}

.euiFlyout--paddingMedium .euiFlyoutBody__banner .euiCallOut {
    padding-left: 16px;
    padding-right: 16px;
}

.euiFlyout--paddingMedium .euiFlyoutFooter {
    padding: 12px 16px;
}

.euiFlyout--paddingLarge .euiFlyoutHeader {
    padding: 24px 24px 0;
}

.euiFlyout--paddingLarge .euiFlyoutHeader--hasBorder {
    padding-bottom: 24px;
}

.euiFlyout--paddingLarge .euiFlyoutBody__overflowContent {
    padding: 24px;
}

.euiFlyout--paddingLarge .euiFlyoutBody__banner .euiCallOut {
    padding-left: 24px;
    padding-right: 24px;
}

.euiFlyout--paddingLarge .euiFlyoutFooter {
    padding: 16px 24px;
}

@keyframes euiFlyout {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    75% {
        opacity: 1;
        transform: translateX(0%);
    }
}

/**
 * 1. Only small flyouts should NOT takeover the entire screen
 * 2. If a custom maxWidth is set, we need to override it.
 */
@media only screen and (max-width: 574px) {
    .euiFlyout:not(.euiFlyout--small) {
        /* 1 */
        left: 0;
        min-width: 0;
        width: auto;
        border-left: none;
        max-width: 100vw !important;
        /* 2 */
    }

    .euiFlyout--small {
        width: 90vw;
        min-width: 0;
        /* 1 */
        max-width: 403px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiFlyout:not(.euiFlyout--small) {
        /* 1 */
        left: 0;
        min-width: 0;
        width: auto;
        border-left: none;
        max-width: 100vw !important;
        /* 2 */
    }

    .euiFlyout--small {
        width: 90vw;
        min-width: 0;
        /* 1 */
        max-width: 403px;
    }
}

.euiFlyoutBody {
    flex-grow: 1;
    overflow-y: hidden;
    height: 100%;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-corner,
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiFlyoutBody .euiFlyoutBody__overflow:focus {
    outline: none;
    /* 1 */
}

.euiFlyoutBody .euiFlyoutBody__banner .euiCallOut {
    border: none;
    border-radius: 0;
}

.euiFlyoutFooter {
    background: #f5f7fa;
    flex-grow: 0;
}

.euiFlyoutHeader {
    flex-grow: 0;
}

.euiFlyoutHeader--hasBorder {
    border-bottom: 1px solid #d3dae6;
}

.euiCheckbox {
    position: relative;
    /**
     * 1. Float above the visual radio and match its dimension, so that when users try to click it
     *    they actually click this input.
     */
}

.euiCheckbox .euiCheckbox__input {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
    display: inline-block;
    padding-left: 24px;
    line-height: 24px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 4px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
}

.euiCheckbox .euiCheckbox__input[disabled] {
    cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] ~ .euiCheckbox__label {
    color: #98a2b3;
    cursor: not-allowed !important;
}

.euiCheckbox .euiCheckbox__input[disabled] + .euiCheckbox__square {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
}

.euiCheckbox .euiCheckbox__input:checked[disabled] + .euiCheckbox__square {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%2894, 100, 111%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}

.euiCheckbox .euiCheckbox__input:indeterminate[disabled] + .euiCheckbox__square {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%2894, 100, 111%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}

.euiCheckbox.euiCheckbox--inList,
.euiCheckbox.euiCheckbox--noLabel {
    min-height: 16px;
    min-width: 16px;
}

.euiCheckbox.euiCheckbox--inList .euiCheckbox__square,
.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__square {
    top: 0;
}

.euiCheckbox.euiCheckbox--inList .euiCheckbox__input,
.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input {
    width: 16px;
    height: 16px;
    /* 1 */
    position: absolute;
    /* 1 */
    opacity: 0;
    /* 1 */
    z-index: 1;
    /* 1 */
    margin: 0;
    /* 1 */
    left: 0;
    /* 1 */
    cursor: pointer;
}

.euiCheckboxGroup__item + .euiCheckboxGroup__item {
    margin-top: 4px;
}

.euiCheckboxGroup__item + .euiCheckboxGroup__item.euiCheckbox--compressed {
    margin-top: 0;
}

.euiDescribedFormGroup {
    max-width: 800px;
}

.euiDescribedFormGroup + * {
    margin-top: 24px;
}

.euiDescribedFormGroup.euiDescribedFormGroup--fullWidth {
    max-width: 100%;
}

.euiDescribedFormGroup .euiDescribedFormGroup__description {
    padding-top: 8px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fields {
    min-width: 0;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xxxsmall {
    padding-top: 8px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xxsmall {
    padding-top: 11px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xsmall {
    padding-top: 14px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--small {
    padding-top: 20px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--medium {
    padding-top: 32px;
}

.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--large {
    padding-top: 44px;
}

@media only screen and (max-width: 574px) {
    .euiDescribedFormGroup .euiDescribedFormGroup__fields {
        padding-top: 0;
    }

    .euiDescribedFormGroup
        .euiDescribedFormGroup__fields
        > .euiFormRow--hasEmptyLabelSpace:first-child {
        padding-top: 0;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiDescribedFormGroup .euiDescribedFormGroup__fields {
        padding-top: 0;
    }

    .euiDescribedFormGroup
        .euiDescribedFormGroup__fields
        > .euiFormRow--hasEmptyLabelSpace:first-child {
        padding-top: 0;
    }
}

.euiFieldNumber {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
}

.euiFieldNumber--fullWidth {
    max-width: 100%;
}

.euiFieldNumber--compressed {
    height: 32px;
}

.euiFieldNumber--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiFieldNumber {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFieldNumber {
        line-height: 1em;
    }
}

.euiFieldNumber::-webkit-input-placeholder {
    color: #69707d;
}

.euiFieldNumber::-moz-placeholder {
    color: #69707d;
}

.euiFieldNumber:-ms-input-placeholder {
    color: #69707d;
}

.euiFieldNumber:-moz-placeholder {
    color: #69707d;
}

.euiFieldNumber::placeholder {
    color: #69707d;
}

.euiFieldNumber:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldNumber:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldNumber:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldNumber:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldNumber:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldNumber:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldNumber:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldNumber[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldNumber:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiFieldNumber:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiFieldNumber--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFieldNumber--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFieldNumber--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldNumber--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldNumber--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldNumber--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldNumber--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldNumber--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldNumber--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldNumber--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldNumber--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiFieldNumber--withIcon {
    padding-left: 40px;
}

.euiFieldNumber-isLoading {
    padding-right: 40px;
}

.euiFieldNumber-isLoading.euiFieldNumber--compressed {
    padding-right: 32px;
}

.euiFieldNumber--withIcon.euiFieldNumber--compressed {
    padding-left: 32px;
}

.euiFieldPassword {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    padding-left: 40px;
}

.euiFieldPassword--fullWidth {
    max-width: 100%;
}

.euiFieldPassword--compressed {
    height: 32px;
}

.euiFieldPassword--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiFieldPassword {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFieldPassword {
        line-height: 1em;
    }
}

.euiFieldPassword::-webkit-input-placeholder {
    color: #69707d;
}

.euiFieldPassword::-moz-placeholder {
    color: #69707d;
}

.euiFieldPassword:-ms-input-placeholder {
    color: #69707d;
}

.euiFieldPassword:-moz-placeholder {
    color: #69707d;
}

.euiFieldPassword::placeholder {
    color: #69707d;
}

.euiFieldPassword:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldPassword:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldPassword:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldPassword:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldPassword:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldPassword:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldPassword:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldPassword[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldPassword:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiFieldPassword:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiFieldPassword--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFieldPassword--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFieldPassword--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldPassword--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldPassword--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldPassword--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldPassword--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldPassword--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldPassword--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldPassword--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldPassword--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiFieldPassword-isLoading {
    padding-right: 40px;
}

.euiFieldPassword-isLoading.euiFieldPassword--compressed {
    padding-right: 32px;
}

.euiFieldPassword.euiFieldPassword--compressed {
    padding-left: 32px;
}

.euiFieldPassword--withToggle::-ms-reveal {
    display: none;
}

/*
 * 1. Fix for Safari to ensure that it renders like a normal text input
 *    and doesn't add extra spacing around text
 * 2. Remove the X clear button from input type search in Chrome and IE
*/
.euiFieldSearch {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    padding-left: 40px;
    -webkit-appearance: textfield;
    /* 1 */
}

.euiFieldSearch--fullWidth {
    max-width: 100%;
}

.euiFieldSearch--compressed {
    height: 32px;
}

.euiFieldSearch--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiFieldSearch {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFieldSearch {
        line-height: 1em;
    }
}

.euiFieldSearch::-webkit-input-placeholder {
    color: #69707d;
}

.euiFieldSearch::-moz-placeholder {
    color: #69707d;
}

.euiFieldSearch:-ms-input-placeholder {
    color: #69707d;
}

.euiFieldSearch:-moz-placeholder {
    color: #69707d;
}

.euiFieldSearch::placeholder {
    color: #69707d;
}

.euiFieldSearch:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldSearch:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldSearch:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldSearch:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldSearch:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldSearch:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldSearch:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldSearch[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldSearch:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiFieldSearch:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiFieldSearch--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFieldSearch--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFieldSearch--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldSearch--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldSearch--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldSearch--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldSearch--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldSearch--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldSearch--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldSearch--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldSearch--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiFieldSearch-isLoading {
    padding-right: 40px;
}

.euiFieldSearch-isLoading.euiFieldSearch--compressed {
    padding-right: 32px;
}

.euiFieldSearch::-webkit-search-decoration,
.euiFieldSearch::-webkit-search-cancel-button {
    -webkit-appearance: none;
    /* 1, 2 */
}

.euiFieldSearch::-ms-clear {
    display: none;
    /* 2 */
}

.euiFieldSearch.euiFieldSearch-isClearable {
    padding-right: 40px;
}

.euiFieldSearch.euiFieldSearch-isLoading {
    padding-right: 40px;
}

.euiFieldSearch.euiFieldSearch-isLoading.euiFieldSearch-isClearable {
    padding-right: 62px;
}

.euiFieldSearch.euiFieldSearch--compressed {
    padding-left: 32px;
}

.euiFieldSearch.euiFieldSearch--compressed.euiFieldSearch-isClearable {
    padding-right: 32px;
}

.euiFieldSearch.euiFieldSearch--compressed.euiFieldSearch-isLoading {
    padding-right: 32px;
}

.euiFieldSearch.euiFieldSearch--compressed.euiFieldSearch-isLoading.euiFieldSearch-isClearable {
    padding-right: 54px;
}

.euiFieldText {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    /* Invalid state normally comes from :invalid, but several components
    /* like EuiDatePicker need it toggled through an extra class.
    */
}

.euiFieldText--fullWidth {
    max-width: 100%;
}

.euiFieldText--compressed {
    height: 32px;
}

.euiFieldText--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiFieldText {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiFieldText {
        line-height: 1em;
    }
}

.euiFieldText::-webkit-input-placeholder {
    color: #69707d;
}

.euiFieldText::-moz-placeholder {
    color: #69707d;
}

.euiFieldText:-ms-input-placeholder {
    color: #69707d;
}

.euiFieldText:-moz-placeholder {
    color: #69707d;
}

.euiFieldText::placeholder {
    color: #69707d;
}

.euiFieldText:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldText:disabled {
    cursor: not-allowed;
    color: #929292;
    -webkit-text-fill-color: #929292;
    background: #f5f5f5;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldText:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldText:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldText:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldText:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldText:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldText[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldText:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiFieldText:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiFieldText--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFieldText--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFieldText--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldText--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldText--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFieldText--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiFieldText--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFieldText--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiFieldText--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiFieldText--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFieldText--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiFieldText--withIcon {
    padding-left: 40px;
}

.euiFieldText-isLoading {
    padding-right: 40px;
}

.euiFieldText-isLoading.euiFieldText--compressed {
    padding-right: 32px;
}

.euiFieldText.euiFieldText-isInvalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFieldText--withIcon.euiFieldText--compressed {
    padding-left: 32px;
}

/**
  * REMEMBER: --large modifiers must come last to override --compressed
  */
.euiFilePicker {
    max-width: 400px;
    width: 100%;
    height: 40px;
    position: relative;
}

.euiFilePicker--fullWidth {
    max-width: 100%;
}

.euiFilePicker--compressed {
    height: 32px;
}

.euiFilePicker--inGroup {
    height: 100%;
}

.euiFilePicker.euiFilePicker--large {
    border-radius: 0;
    overflow: hidden;
    height: auto;
}

.euiFilePicker.euiFilePicker--large.euiFilePicker--compressed {
    border-radius: 2px;
}

.euiFilePicker__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
}

.euiFilePicker__input:hover {
    cursor: pointer;
}

.euiFilePicker__input:hover:disabled {
    cursor: not-allowed;
}

.euiFilePicker__input:disabled ~ .euiFilePicker__prompt {
    color: #98a2b3;
}

.euiFilePicker__icon {
    position: absolute;
    left: 12px;
    top: 12px;
    transition: transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiFilePicker--compressed .euiFilePicker__icon {
    top: 8px;
    left: 8px;
}

.euiFilePicker--large .euiFilePicker__icon {
    position: static;
    margin-bottom: 16px;
}

/**
  * 1. Don't block the user from dropping files onto the filepicker.
  * 2. Ensure space for import icon, loading spinner, and clear button (only if it has files)
  * 3. Delay focus gradient or else it will only partially transition while file chooser opens
  * 4. Static height so that it doesn't shift its surrounding contents around
  */
.euiFilePicker__prompt {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding-left: 40px;
    /* 2 */
    height: 40px;
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    pointer-events: none;
    /* 1 */
    border-radius: 0;
    transition: box-shadow 150ms ease-in, background-color 150ms ease-in,
        background-image 150ms ease-in, background-size 150ms ease-in 150ms;
    /* 3 */
}

@supports (-moz-appearance: none) {
    .euiFilePicker__prompt {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFilePicker--compressed .euiFilePicker__prompt {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
    padding-left: 32px;
    /* 2 */
    height: 32px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFilePicker--compressed .euiFilePicker__prompt {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFilePicker--large .euiFilePicker__prompt {
    height: 128px;
    /* 4 */
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.euiFilePicker--large.euiFilePicker--compressed .euiFilePicker__prompt {
    height: 104px;
    /* 4 */
}

.euiFilePicker-isInvalid .euiFilePicker__prompt {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiFilePicker__promptText {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    line-height: 16px;
}

.euiFilePicker:not(.euiFilePicker--large):not(.euiFilePicker-hasFiles) .euiFilePicker__promptText {
    color: #98a2b3;
}

.euiFilePicker__clearButton,
.euiFilePicker__loadingSpinner {
    position: absolute;
    right: 12px;
    top: 12px;
}

.euiFilePicker--compressed .euiFilePicker__clearButton,
.euiFilePicker--compressed .euiFilePicker__loadingSpinner {
    top: 8px;
}

/**
  * 1. Undo the pointer-events: none applied to the enclosing prompt.
  */
.euiFilePicker__clearButton {
    pointer-events: auto;
    /* 1 */
}

.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton {
    width: 16px;
    height: 16px;
    pointer-events: all;
    background-color: #98a2b3;
    border-radius: 16px;
    line-height: 0;
}

.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton .euiFilePicker__clearIcon {
    width: 8px;
    height: 8px;
    fill: #fff;
    stroke: #fff;
    stroke-width: 2px;
}

.euiFilePicker--large .euiFilePicker__clearButton {
    position: relative;
    top: 0;
    right: 0;
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt::-moz-placeholder {
    color: #98a2b3;
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt:-moz-placeholder {
    color: #98a2b3;
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt::placeholder {
    color: #98a2b3;
}

.euiFilePicker:not(.euiFilePicker--large).euiFilePicker-isLoading .euiFilePicker__prompt,
.euiFilePicker:not(.euiFilePicker--large).euiFilePicker-hasFiles .euiFilePicker__prompt {
    padding-right: 40px;
    /* 2 */
}

.euiFilePicker-hasFiles .euiFilePicker__promptText {
    color: #343741;
}

.euiFilePicker--large
    .euiFilePicker__input:hover:not(:disabled)
    + .euiFilePicker__prompt
    .euiFilePicker__promptText,
.euiFilePicker--large
    .euiFilePicker__input:focus
    + .euiFilePicker__prompt
    .euiFilePicker__promptText {
    text-decoration: underline;
}

.euiFilePicker--large
    .euiFilePicker__input:hover:not(:disabled)
    + .euiFilePicker__prompt
    .euiFilePicker__icon,
.euiFilePicker--large .euiFilePicker__input:focus + .euiFilePicker__prompt .euiFilePicker__icon {
    transform: scale(1.1);
}

.euiFilePicker--large.euiFilePicker__showDrop .euiFilePicker__prompt .euiFilePicker__promptText {
    text-decoration: underline;
}

.euiFilePicker--large.euiFilePicker__showDrop .euiFilePicker__prompt .euiFilePicker__icon {
    transform: scale(1.1);
}

.euiFilePicker--large.euiFilePicker-hasFiles .euiFilePicker__promptText {
    font-weight: 700;
}

.euiForm__error {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    list-style: disc;
}

.euiForm__errors {
    margin-bottom: 16px;
}

.euiFormControlLayout {
    max-width: 400px;
    width: 100%;
    height: 40px;
}

.euiFormControlLayout--fullWidth {
    max-width: 100%;
}

.euiFormControlLayout--compressed {
    height: 32px;
}

.euiFormControlLayout--inGroup {
    height: 100%;
}

.euiFormControlLayout__childrenWrapper {
    position: relative;
}

/**
 * 1. Account for inner box-shadow style border
 * 2. Ensure truncation works in children elements
 */
.euiFormControlLayout--group {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    display: flex;
    align-items: stretch;
    padding: 1px;
    /* 1 */
}

@supports (-moz-appearance: none) {
    .euiFormControlLayout--group {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFormControlLayout--group > *,
.euiFormControlLayout--group .euiPopover__anchor,
.euiFormControlLayout--group .euiButtonEmpty,
.euiFormControlLayout--group .euiText,
.euiFormControlLayout--group .euiFormLabel,
.euiFormControlLayout--group .euiButtonIcon {
    height: 100%;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper {
    flex-grow: 1;
    overflow: hidden;
    /* 2 */
}

.euiFormControlLayout--group .euiFormControlLayout__prepend,
.euiFormControlLayout--group .euiFormControlLayout__append {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    flex-shrink: 0;
    height: 100%;
    border-radius: 0;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiIcon {
    padding: 0 8px;
    width: 32px;
    border-radius: 0;
    background-color: #e9edf3;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty {
    transform: none !important;
}

.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty .euiIcon {
    background: none !important;
    padding: 0;
    width: 16px;
}

.euiFormControlLayout--group .euiButtonIcon {
    padding: 0 8px;
    width: 32px;
    border-radius: 0;
}

.euiFormControlLayout--group .euiButtonIcon:not(:focus) {
    background-color: #e9edf3;
}

.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
    height: 100%;
    background-color: #e9edf3;
    padding: 0 8px;
    width: 32px;
    border-radius: 0;
}

.euiFormControlLayout--group > .euiFormControlLayout__prepend,
.euiFormControlLayout--group > .euiFormControlLayout__append {
    max-width: 50%;
}

.euiFormControlLayout--group .euiFormLabel,
.euiFormControlLayout--group .euiText {
    background-color: #e9edf3;
    padding: 12px;
    line-height: 16px !important;
    cursor: default !important;
}

.euiFormControlLayout--group
    .euiFormLabel
    + *:not(.euiFormControlLayout__childrenWrapper):not(input),
.euiFormControlLayout--group .euiText + *:not(.euiFormControlLayout__childrenWrapper):not(input) {
    margin-left: -12px;
}

.euiFormControlLayout--group > *:not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel,
.euiFormControlLayout--group > *:not(.euiFormControlLayout__childrenWrapper) + .euiText {
    margin-left: -12px;
}

.euiFormControlLayout--group .euiButtonEmpty {
    border-right: 1px solid #e4e8ee;
}

.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ .euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ * .euiButtonEmpty {
    border-right: none;
    border-left: 1px solid #e4e8ee;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    border-radius: 2px;
    overflow: hidden;
}

@supports (-moz-appearance: none) {
    .euiFormControlLayout--group.euiFormControlLayout--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel,
.euiFormControlLayout--group.euiFormControlLayout--compressed .euiText {
    padding: 8px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed
    .euiFormLabel
    + *:not(.euiFormControlLayout__childrenWrapper),
.euiFormControlLayout--group.euiFormControlLayout--compressed
    .euiText
    + *:not(.euiFormControlLayout__childrenWrapper) {
    margin-left: -8px;
}

.euiFormControlLayout--group.euiFormControlLayout--compressed
    > *:not(.euiFormControlLayout__childrenWrapper)
    + .euiFormLabel,
.euiFormControlLayout--group.euiFormControlLayout--compressed
    > *:not(.euiFormControlLayout__childrenWrapper)
    + .euiText {
    margin-left: -8px;
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFormControlLayout--group.euiFormControlLayout--readOnly input {
    background-color: transparent;
}

.euiFormControlLayoutDelimited {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    display: flex;
    align-items: stretch;
    padding: 1px;
    /* 1 */
}

@supports (-moz-appearance: none) {
    .euiFormControlLayoutDelimited {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFormControlLayoutDelimited .euiFormControlLayoutDelimited__delimeter {
    background-color: #fbfcfd;
}

.euiFormControlLayoutDelimited > .euiFormControlLayout__childrenWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.euiFormControlLayoutDelimited[class*='--compressed'] {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiFormControlLayoutDelimited[class*='--compressed'] {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiFormControlLayoutDelimited[class*='--compressed'] .euiFormControlLayoutDelimited__input {
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;
}

.euiFormControlLayoutDelimited[class*='--compressed'] .euiFormControlLayoutIcons {
    padding-left: 8px;
    padding-right: 8px;
}

.euiFormControlLayoutDelimited[class*='--fullWidth'] .euiFormControlLayout__childrenWrapper,
.euiFormControlLayoutDelimited[class*='--fullWidth'] input {
    width: 100%;
    max-width: none;
}

.euiFormControlLayoutDelimited[class*='-isDisabled'] {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFormControlLayoutDelimited[class*='-isDisabled']::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiFormControlLayoutDelimited[class*='-isDisabled']::-moz-placeholder {
    color: #98a2b3;
}

.euiFormControlLayoutDelimited[class*='-isDisabled']:-ms-input-placeholder {
    color: #98a2b3;
}

.euiFormControlLayoutDelimited[class*='-isDisabled']:-moz-placeholder {
    color: #98a2b3;
}

.euiFormControlLayoutDelimited[class*='-isDisabled']::placeholder {
    color: #98a2b3;
}

.euiFormControlLayoutDelimited[class*='-isDisabled'] .euiFormControlLayoutDelimited__delimeter {
    background-color: #eef2f7;
}

.euiFormControlLayoutDelimited[class*='--readOnly'] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiFormControlLayoutDelimited[class*='--readOnly'] input,
.euiFormControlLayoutDelimited[class*='--readOnly'] .euiFormControlLayoutDelimited__delimeter {
    background-color: rgba(211, 218, 230, 0.05);
}

.euiFormControlLayoutDelimited .euiFormControlLayoutIcons {
    position: static;
    padding-left: 12px;
    padding-right: 12px;
    flex-shrink: 0;
}

.euiFormControlLayoutDelimited .euiFormControlLayoutIcons:not(.euiFormControlLayoutIcons--right) {
    order: -1;
}

.euiFormControlLayoutDelimited__input {
    box-shadow: none !important;
    border-radius: 0 !important;
    text-align: center;
    height: 100%;
    min-width: 0;
}

.euiFormControlLayoutDelimited[class*='--compressed'] .euiFormControlLayoutDelimited__input {
    max-width: none;
}

.euiFormControlLayoutDelimited__delimeter {
    line-height: 1 !important;
    flex: 0 0 auto;
    padding-left: 6px;
    padding-right: 6px;
}

.euiFormControlLayoutIcons {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    display: flex;
    align-items: center;
}

.euiFormControlLayoutIcons > * + * {
    margin-left: 6px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons {
    left: 8px;
}

.euiFormControlLayoutIcons--right {
    left: auto;
    right: 12px;
}

.euiFormControlLayout--compressed .euiFormControlLayoutIcons--right {
    left: auto;
    right: 8px;
}

*:disabled + .euiFormControlLayoutIcons {
    cursor: not-allowed;
    color: #98a2b3;
}

.euiFormControlLayoutClearButton {
    width: 16px;
    height: 16px;
    pointer-events: all;
    background-color: #98a2b3;
    border-radius: 16px;
    line-height: 0;
}

.euiFormControlLayoutClearButton:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiFormControlLayoutClearButton .euiFormControlLayoutClearButton__icon {
    width: 8px;
    height: 8px;
    fill: #fff;
    stroke: #fff;
    stroke-width: 2px;
}

.euiFormControlLayoutClearButton--small {
    width: 12px;
    height: 12px;
    pointer-events: all;
    background-color: #98a2b3;
    border-radius: 12px;
    line-height: 0;
}

.euiFormControlLayoutClearButton--small:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiFormControlLayoutClearButton--small .euiFormControlLayoutClearButton__icon {
    width: 6px;
    height: 6px;
    fill: #fff;
    stroke: #fff;
    stroke-width: 4px;
}

.euiFormControlLayoutCustomIcon {
    pointer-events: none;
    font-size: 0;
}

.euiFormControlLayoutCustomIcon--clickable {
    width: 16px;
    height: 16px;
    pointer-events: all;
}

.euiFormControlLayoutCustomIcon--clickable .euiFormControlLayoutCustomIcon__icon {
    vertical-align: baseline;
    transform: none;
}

.euiFormControlLayoutCustomIcon--clickable:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiFormControlLayoutCustomIcon--clickable:disabled {
    cursor: not-allowed;
    color: #98a2b3;
}

.euiFormErrorText {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    padding-top: 4px;
    color: #bd271e;
}

.euiFormLegend {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #1a1c21;
    font-weight: 600;
}

.euiFormLegend:not(.euiFormLegend-isHidden) {
    margin-bottom: 8px;
}

.euiFormLegend:not(.euiFormLegend-isHidden).euiFormLegend--compressed {
    margin-bottom: 4px;
}

.euiFormHelpText {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    padding-top: 4px;
    color: #69707d;
}

/**
 * 1. Focused state overrides invalid state.
 */
.euiFormLabel {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #1a1c21;
    font-weight: 600;
    display: inline-block;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiFormLabel.euiFormLabel-isInvalid {
    color: #bd271e;
    /* 1 */
}

.euiFormLabel[for] {
    cursor: pointer;
}

/**
 * 1. Coerce inline form elements to behave as block-level elements.
 * 2. For inline forms, we need to add margin if the label doesn't exist.
 */
.euiFormRow {
    display: flex;
    /* 1 */
    flex-direction: column;
    /* 1 */
    max-width: 400px;
}

.euiFormRow + .euiFormRow,
.euiFormRow + .euiButton {
    margin-top: 16px;
}

.euiFormRow--fullWidth {
    max-width: 100%;
}

.euiFormRow--hasEmptyLabelSpace {
    margin-top: 22px;
    /* 2 */
    min-height: 40px;
    padding-bottom: 0;
    justify-content: center;
}

.euiFormRow__labelWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 4px;
}

.euiFormRow--horizontal {
    flex-direction: row;
    align-items: stretch;
}

.euiFormRow--horizontal .euiFormRow__label {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    hyphens: auto;
    max-width: 100%;
}

.euiFormRow--horizontal .euiFormRow__labelWrapper {
    display: block;
    line-height: 31px;
    width: calc(33% - 8px);
    margin-right: 8px;
    margin-bottom: 0;
}

.euiFormRow--horizontal .euiFormRow__fieldWrapper {
    width: 67%;
}

.euiFormRow--horizontal + .euiFormRow--horizontal {
    margin-top: 8px;
}

.euiFormRow--horizontal + .euiFormRow--horizontal.euiFormRow--hasSwitch {
    margin-top: 12px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__labelWrapper {
    line-height: 19px;
    width: auto;
    min-width: calc(33% - 8px);
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper {
    width: auto;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper .euiSwitch--compressed {
    margin-top: 2px;
}

.euiFormRow--horizontal.euiFormRow--hasSwitch + .euiFormRow--horizontal {
    margin-top: 12px;
}

.euiFormRow__fieldWrapperDisplayOnly {
    min-height: 40px;
    display: flex;
    align-items: center;
}

.euiFormRow--compressed.euiFormRow--hasEmptyLabelSpace {
    min-height: 32px;
}

.euiFormRow--compressed .euiFormRow__fieldWrapperDisplayOnly {
    min-height: 32px;
}

.euiRadio {
    position: relative;
    /**
     * 1. Float above the visual radio and match its dimension, so that when users try to click it
     *    they actually click this input.
     */
}

.euiRadio .euiRadio__input {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.euiRadio .euiRadio__input ~ .euiRadio__label {
    display: inline-block;
    padding-left: 24px;
    line-height: 24px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.euiRadio .euiRadio__input + .euiRadio__circle {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 3px;
}

.euiRadio .euiRadio__input[disabled] {
    cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] ~ .euiRadio__label {
    color: #98a2b3;
    cursor: not-allowed !important;
}

.euiRadio .euiRadio__input[disabled] + .euiRadio__circle {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
}

.euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%2894, 100, 111%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}

.euiRadio.euiRadio--inList,
.euiRadio.euiRadio--noLabel {
    min-height: 16px;
    min-width: 16px;
}

.euiRadio.euiRadio--inList .euiRadio__circle,
.euiRadio.euiRadio--noLabel .euiRadio__circle {
    top: 0;
}

.euiRadio.euiRadio--inList .euiRadio__input,
.euiRadio.euiRadio--noLabel .euiRadio__input {
    width: 16px;
    height: 16px;
    /* 1 */
    position: absolute;
    /* 1 */
    opacity: 0;
    /* 1 */
    z-index: 1;
    /* 1 */
    margin: 0;
    /* 1 */
    left: 0;
    /* 1 */
    cursor: pointer;
}

.euiRadioGroup__item + .euiRadioGroup__item {
    margin-top: 4px;
}

.euiRadioGroup__item + .euiRadioGroup__item.euiRadio--compressed {
    margin-top: 0;
}

.euiRange__horizontalSpacer {
    width: 16px;
}

.euiRangeHighlight {
    position: absolute;
    left: 0;
    width: 100%;
    top: calc(50% - 2px);
    overflow: hidden;
}

.euiRangeHighlight__progress {
    height: 4px;
    border-radius: 4px;
    background-color: #69707d;
}

.euiRangeHighlight--hasTicks {
    top: 8px;
}

.euiRangeHighlight--hasTicks.euiRangeHighlight--compressed {
    top: 6px;
}

.euiRangeInput {
    width: auto;
    min-width: 64px;
}

.euiRange__popover .euiRangeInput {
    margin: 0 !important;
    width: 100%;
}

.euiRangeLabel--min,
.euiRangeLabel--max {
    font-size: 12px;
}

.euiRangeLabel--min {
    margin-right: 8px;
}

.euiRangeLabel--max {
    margin-left: 8px;
}

.euiRangeLabel--isDisabled {
    opacity: 0.25;
}

.euiRangeLevels {
    display: flex;
    justify-content: stretch;
    position: absolute;
    left: 0;
    right: 0;
    top: 22px;
}

.euiRangeLevels--hasTicks {
    top: 12px;
}

.euiRangeLevels--compressed {
    top: 18px;
}

.euiRangeLevels--compressed.euiRangeLevels--hasTicks {
    top: 10px;
}

.euiRangeLevel {
    display: block;
    height: 6px;
    border-radius: 6px;
    margin: 2px;
}

.euiRangeLevel--primary {
    background-color: rgba(207, 31, 47, 0.3);
}

.euiRangeLevel--success {
    background-color: rgba(1, 125, 115, 0.3);
}

.euiRangeLevel--warning {
    background-color: rgba(245, 167, 0, 0.3);
}

.euiRangeLevel--danger {
    background-color: rgba(189, 39, 30, 0.3);
}

/*
 *  Input Range Customization by browser
 */
.euiRangeSlider {
    height: 40px;
    appearance: none;
    background: transparent;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.euiRangeSlider:disabled {
    cursor: not-allowed;
}

.euiRangeSlider:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
    border-color: #69707d;
    background-color: #69707d;
    box-shadow: none;
}

.euiRangeSlider:disabled::-moz-range-thumb {
    cursor: not-allowed;
    border-color: #69707d;
    background-color: #69707d;
    box-shadow: none;
}

.euiRangeSlider:disabled::-ms-thumb {
    cursor: not-allowed;
    border-color: #69707d;
    background-color: #69707d;
    box-shadow: none;
}

.euiRangeSlider:disabled ~ .euiRangeThumb {
    cursor: not-allowed;
    border-color: #69707d;
    background-color: #69707d;
    box-shadow: none;
}

.euiRangeSlider::-webkit-slider-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiRangeSlider::-moz-range-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiRangeSlider::-ms-thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
}

.euiRangeSlider::-webkit-slider-runnable-track {
    height: 2px;
    transition: all 250ms ease-in;
    width: 100%;
    background: #69707d;
    border: 0 solid #69707d;
    border-radius: 4px;
}

.euiRangeSlider::-moz-range-track {
    height: 2px;
    transition: all 250ms ease-in;
    width: 100%;
    background: #69707d;
    border: 0 solid #69707d;
    border-radius: 4px;
}

.euiRangeSlider::-ms-fill-lower {
    height: 2px;
    transition: all 250ms ease-in;
    width: 100%;
    background: #69707d;
    border: 0 solid #69707d;
    border-radius: 4px;
}

.euiRangeSlider::-ms-fill-upper {
    height: 2px;
    transition: all 250ms ease-in;
    width: 100%;
    background: #69707d;
    border: 0 solid #69707d;
    border-radius: 4px;
}

.euiRangeSlider:focus {
    outline: none;
}

.euiRangeSlider:focus-visible ~ .euiRangeTooltip .euiRangeTooltip__value,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiRangeSlider:focus-visible
    ~ .euiRangeTooltip
    .euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeSlider:focus-visible
    ~ .euiRangeTooltip
    .euiRangeTooltip__value.euiRangeTooltip__value--left,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--left {
    transform: translateX(0) translateY(-50%) scale(1.1);
}

.euiRangeSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -7px;
}

.euiRangeSlider::-ms-thumb {
    margin-top: 0;
}

.euiRangeSlider::-moz-focus-outer {
    border: none;
}

.euiRangeSlider::-ms-track {
    height: 2px;
    transition: all 250ms ease-in;
    width: 100%;
    background: transparent;
    border-color: transparent;
    border-width: 8px 0;
    color: transparent;
}

.euiRangeSlider--hasTicks {
    height: 20px;
}

.euiRangeSlider--compressed {
    height: 32px;
}

.euiRangeSlider--compressed.euiRangeSlider--hasTicks {
    height: 16px;
}

.euiRangeSlider--hasRange::-webkit-slider-runnable-track {
    background-color: rgba(105, 112, 125, 0.4);
    border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-moz-range-track {
    background-color: rgba(105, 112, 125, 0.4);
    border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-ms-fill-lower {
    background-color: rgba(105, 112, 125, 0.4);
    border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-ms-fill-upper {
    background-color: rgba(105, 112, 125, 0.4);
    border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeThumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 7px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 14px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    cursor: pointer;
    border-color: #69707d;
    padding: 0;
    height: 16px;
    width: 16px;
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -8px;
    pointer-events: none;
}

.euiRangeThumb--hasTicks {
    top: 25%;
}

.euiRangeTicks {
    position: absolute;
    left: 8px;
    right: 8px;
    top: 8px;
    display: flex;
}

.euiRangeTick {
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    position: relative;
    padding-top: 16px;
}

.euiRangeTick::before {
    width: 4px;
    height: 4px;
    content: '';
    background-color: #69707d;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: calc(50% - 2px);
}

.euiRangeTick--isCustom {
    position: absolute;
    transform: translateX(-50%);
}

.euiRangeTick--selected {
    font-weight: 500;
}

.euiRangeTick:disabled {
    cursor: not-allowed;
}

.euiRangeTicks--compressed {
    top: 6px;
}

.euiRangeTicks--compressed .euiRangeTick {
    padding-top: 14px;
}

.euiRangeTooltip {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: calc(100% - 16px);
    margin-left: 8px;
    pointer-events: none;
}

.euiRangeTooltip__value {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    border: 1px solid #404040;
    position: absolute;
    border-radius: 4px;
    padding: 2px 8px;
    background-color: #404040;
    color: #fff;
    max-width: 256px;
    top: 50%;
    transition: box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiRangeTooltip__value::after,
.euiRangeTooltip__value::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform-origin: center;
    background-color: #404040;
    width: 12px;
    height: 12px;
    border-radius: 2px;
}

.euiRangeTooltip__value::before {
    background-color: #404040;
}

.euiRangeTooltip__value.euiRangeTooltip__value--right {
    margin-left: 24px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--right:before,
.euiRangeTooltip__value.euiRangeTooltip__value--right:after {
    left: -5px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--right::before {
    margin-left: -1px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--left {
    margin-right: 24px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--left:before,
.euiRangeTooltip__value.euiRangeTooltip__value--left:after {
    left: auto;
    right: -5px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--left::before {
    margin-right: -1px;
}

.euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeTooltip__value.euiRangeTooltip__value--left {
    transform: translateX(0) translateY(-50%);
}

.euiRangeTooltip__value.euiRangeTooltip__value--right:before,
.euiRangeTooltip__value.euiRangeTooltip__value--right:after,
.euiRangeTooltip__value.euiRangeTooltip__value--left:before,
.euiRangeTooltip__value.euiRangeTooltip__value--left:after {
    bottom: 50%;
    transform: translateY(50%) rotateZ(45deg);
}

.euiRangeTooltip__value--hasTicks {
    top: 10px;
}

.euiRangeTooltip--compressed .euiRangeTooltip__value--hasTicks {
    top: 8px;
}

.euiRangeTrack {
    height: 100%;
    flex-grow: 1;
    position: relative;
    align-self: flex-start;
}

.euiRangeTrack--disabled {
    opacity: 0.25;
}

/*
 * 1. There's no way to target the layout of the extra input, so we must
 *    use the descendant selector to allow the width to shrink.
 *
 * 2. Prevent the prepend/append label from extending outside the parent element
 */
.euiRangeWrapper {
    max-width: 400px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}

.euiRangeWrapper--fullWidth {
    max-width: 100%;
}

.euiRangeWrapper--compressed {
    height: 32px;
}

.euiRangeWrapper--inGroup {
    height: 100%;
}

.euiRangeWrapper > .euiFormControlLayout {
    /* 1 */
    width: auto;
}

.euiRangeWrapper > .euiFormControlLayout.euiFormControlLayout--group {
    flex-shrink: 0;
    /* 2 */
}

.euiDualRange__slider::-webkit-slider-thumb {
    visibility: hidden;
}

.euiDualRange__slider::-moz-range-thumb {
    visibility: hidden;
}

.euiDualRange__slider::-ms-thumb {
    visibility: hidden;
}

/**
 * 1. Leave room for caret.
 * 2. Ensure the descenders don't get cut off
 */
.euiSelect {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    padding-right: 40px;
    /* 1 */
    appearance: none;
    line-height: 40px;
    /* 2 */
    padding-top: 0;
    /* 2 */
    padding-bottom: 0;
    /* 2 */
}

.euiSelect--fullWidth {
    max-width: 100%;
}

.euiSelect--compressed {
    height: 32px;
}

.euiSelect--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiSelect {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiSelect {
        line-height: 1em;
    }
}

.euiSelect::-webkit-input-placeholder {
    color: #69707d;
}

.euiSelect::-moz-placeholder {
    color: #69707d;
}

.euiSelect:-ms-input-placeholder {
    color: #69707d;
}

.euiSelect:-moz-placeholder {
    color: #69707d;
}

.euiSelect::placeholder {
    color: #69707d;
}

.euiSelect:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiSelect:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSelect:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiSelect:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiSelect:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiSelect:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiSelect:disabled::placeholder {
    color: #98a2b3;
}

.euiSelect[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSelect:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiSelect:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiSelect--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiSelect--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiSelect--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiSelect--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSelect--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiSelect--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiSelect--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiSelect--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiSelect--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiSelect--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSelect--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiSelect-isLoading {
    padding-right: 62px;
}

.euiSelect-isLoading.euiSelect--compressed {
    padding-right: 54px;
}

.euiSelect--compressed {
    padding-right: 32px;
    /* 1 */
    line-height: 32px;
    /* 2 */
    padding-top: 0;
    /* 2 */
    padding-bottom: 0;
    /* 2 */
}

.euiSelect--inGroup {
    line-height: 38px;
    /* 2 */
}

.euiSelect--inGroup.euiSelect--compressed {
    line-height: 30px;
    /* 2 */
}

.euiSelect::-ms-expand {
    display: none;
}

.euiSelect:focus::-ms-value {
    color: #343741;
    background: transparent;
}

.euiSelect:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #343741;
}

.euiSuperSelect__listbox {
    scrollbar-width: thin;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
}

.euiSuperSelect__listbox::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiSuperSelect__listbox::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiSuperSelect__listbox::-webkit-scrollbar-corner,
.euiSuperSelect__listbox::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiSuperSelect__item {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px;
}

.euiSuperSelect__item:hover {
    cursor: pointer;
    text-decoration: underline;
}

.euiSuperSelect__item:focus {
    cursor: pointer;
    text-decoration: underline;
    background-color: #fae9ea;
}

.euiSuperSelect__item:disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #afb0b3;
}

.euiSuperSelect__item--hasDividers:not(:last-of-type) {
    border-bottom: 1px solid #d3dae6;
}

/**
 * 1. Leave room for caret.
 * 2. Ensure the descenders don't get cut off
 * 3. Makes sure the height is correct when there's no selection
 */
.euiSuperSelectControl {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    padding-right: 40px;
    /* 1 */
    display: block;
    /* 3 */
    text-align: left;
    line-height: 40px;
    /* 2 */
    padding-top: 0;
    /* 2 */
    padding-bottom: 0;
    /* 2 */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.euiSuperSelectControl--fullWidth {
    max-width: 100%;
}

.euiSuperSelectControl--compressed {
    height: 32px;
}

.euiSuperSelectControl--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiSuperSelectControl {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiSuperSelectControl {
        line-height: 1em;
    }
}

.euiSuperSelectControl::-webkit-input-placeholder {
    color: #69707d;
}

.euiSuperSelectControl::-moz-placeholder {
    color: #69707d;
}

.euiSuperSelectControl:-ms-input-placeholder {
    color: #69707d;
}

.euiSuperSelectControl:-moz-placeholder {
    color: #69707d;
}

.euiSuperSelectControl::placeholder {
    color: #69707d;
}

.euiSuperSelectControl:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiSuperSelectControl:disabled {
    cursor: not-allowed;
    color: #929292;
    -webkit-text-fill-color: #929292;
    background: #f5f5f5;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSuperSelectControl:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl:disabled::placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSuperSelectControl:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiSuperSelectControl:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiSuperSelectControl--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiSuperSelectControl--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiSuperSelectControl--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiSuperSelectControl--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSuperSelectControl--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiSuperSelectControl--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiSuperSelectControl--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiSuperSelectControl-isLoading {
    padding-right: 62px;
}

.euiSuperSelectControl-isLoading.euiSuperSelectControl--compressed {
    padding-right: 54px;
}

.euiSuperSelectControl-isInvalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiSuperSelectControl--compressed {
    padding-right: 32px;
    /* 1 */
    line-height: 32px;
    /* 2 */
    padding-top: 0;
    /* 2 */
    padding-bottom: 0;
    /* 2 */
}

.euiSwitch {
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    min-height: 20px;
}

.euiSwitch .euiSwitch__label {
    cursor: pointer;
    padding-left: 8px;
    line-height: 20px;
    font-size: 14px;
    vertical-align: middle;
    display: inline-block;
}

.euiSwitch .euiSwitch__button {
    flex-shrink: 0;
    line-height: 0;
}

.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #cf1f2f;
}

.euiSwitch .euiSwitch__button:disabled:hover,
.euiSwitch .euiSwitch__button:disabled ~ .euiSwitch__label:hover {
    cursor: not-allowed;
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__body {
    background-color: rgba(152, 162, 179, 0.2);
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__thumb {
    border-color: #d3dae6;
    background-color: #d3dae6;
    box-shadow: none;
    background-color: rgba(152, 162, 179, 0.2);
}

.euiSwitch .euiSwitch__button:disabled .euiSwitch__icon {
    fill: #5e646f;
}

.euiSwitch .euiSwitch__button:disabled + .euiSwitch__label {
    color: #98a2b3;
}

.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color: rgba(152, 162, 179, 0.2);
}

.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb {
    left: 0;
}

.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__icon {
    right: -8px;
}

.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__icon.euiSwitch__icon--checked {
    right: auto;
    left: -34px;
}

.euiSwitch .euiSwitch__body {
    pointer-events: none;
    width: 44px;
    height: 20px;
    background-color: #cf1f2f;
    display: inline-block;
    position: relative;
    border-radius: 20px;
    vertical-align: middle;
}

.euiSwitch .euiSwitch__thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 9px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 18px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    position: absolute;
    display: inline-block;
    left: 24px;
    transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.euiSwitch .euiSwitch__track {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    border-radius: 20px;
}

.euiSwitch .euiSwitch__icon {
    position: absolute;
    right: -34px;
    top: 2px;
    bottom: 0;
    width: 42px;
    height: 16px;
    transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
    fill: #343741;
}

.euiSwitch .euiSwitch__icon--checked {
    right: auto;
    left: -8px;
    fill: #fff;
}

.euiSwitch:hover .euiSwitch__button:not(:disabled) .euiSwitch__thumb {
    transform: scale(1.05);
}

.euiSwitch:hover .euiSwitch__button:active .euiSwitch__thumb {
    transform: scale(0.95);
}

.euiSwitch.euiSwitch--compressed {
    min-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__label {
    line-height: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__body {
    width: 28px;
    height: 16px;
    border-radius: 16px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 6px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 12px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    left: 13px;
    top: 1px;
    transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.euiSwitch.euiSwitch--compressed .euiSwitch__track {
    border-radius: 16px;
}

.euiSwitch.euiSwitch--mini {
    min-height: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__label {
    line-height: 10px;
    font-size: 12px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__body {
    width: 22px;
    height: 10px;
    border-radius: 10px;
}

.euiSwitch.euiSwitch--mini .euiSwitch__thumb {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    padding: 3px;
    border: 1px solid #c9cbcd;
    background: #fff no-repeat center;
    border-radius: 6px;
    transition: background-color 150ms ease-in, border-color 150ms ease-in;
    left: 13px;
    top: 1px;
    transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
        transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.euiSwitch.euiSwitch--mini .euiSwitch__track {
    border-radius: 10px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb {
    left: 1px;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true']:disabled .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true']:disabled .euiSwitch__thumb {
    border-color: #c9cbcd;
}

.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb {
    border-color: #cf1f2f;
}

.euiTextArea {
    max-width: 400px;
    width: 100%;
    height: 40px;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    border: none;
    border-radius: 0;
    padding: 12px;
    line-height: 1.5;
}

.euiTextArea--fullWidth {
    max-width: 100%;
}

.euiTextArea--compressed {
    height: 32px;
}

.euiTextArea--inGroup {
    height: 100%;
}

@supports (-moz-appearance: none) {
    .euiTextArea {
        transition-property: box-shadow, background-image, background-size;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiTextArea {
        line-height: 1em;
    }
}

.euiTextArea::-webkit-input-placeholder {
    color: #69707d;
}

.euiTextArea::-moz-placeholder {
    color: #69707d;
}

.euiTextArea:-ms-input-placeholder {
    color: #69707d;
}

.euiTextArea:-moz-placeholder {
    color: #69707d;
}

.euiTextArea::placeholder {
    color: #69707d;
}

.euiTextArea:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiTextArea:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiTextArea:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiTextArea:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiTextArea:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiTextArea:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiTextArea:disabled::placeholder {
    color: #98a2b3;
}

.euiTextArea[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiTextArea:-webkit-autofill {
    -webkit-text-fill-color: #343741;
}

.euiTextArea:-webkit-autofill ~ .euiFormControlLayoutIcons {
    color: #343741;
}

.euiTextArea--compressed {
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    padding: 8px;
    border-radius: 2px;
}

@supports (-moz-appearance: none) {
    .euiTextArea--compressed {
        transition-property: box-shadow, background-image, background-size;
    }
}

.euiTextArea--compressed:invalid {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiTextArea--compressed:disabled {
    cursor: not-allowed;
    color: #98a2b3;
    -webkit-text-fill-color: #98a2b3;
    background: #eef2f7;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiTextArea--compressed:disabled::-webkit-input-placeholder {
    color: #98a2b3;
}

.euiTextArea--compressed:disabled::-moz-placeholder {
    color: #98a2b3;
}

.euiTextArea--compressed:disabled:-ms-input-placeholder {
    color: #98a2b3;
}

.euiTextArea--compressed:disabled:-moz-placeholder {
    color: #98a2b3;
}

.euiTextArea--compressed:disabled::placeholder {
    color: #98a2b3;
}

.euiTextArea--compressed[readOnly] {
    cursor: default;
    background: rgba(211, 218, 230, 0.05);
    border-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.euiTextArea--inGroup {
    box-shadow: none !important;
    border-radius: 0;
}

.euiTextArea,
.euiTextArea--compressed {
    height: auto;
}

.euiTextArea--resizeVertical {
    resize: vertical;
}

.euiTextArea--resizeHorizontal {
    resize: horizontal;
}

.euiTextArea--resizeBoth {
    resize: both;
}

.euiTextArea--resizeNone {
    resize: none;
}

.euiHeader {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    height: 49px;
    position: relative;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-bottom: 1px solid #d3dae6;
}

.euiHeader--fixed {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.euiHeader--fixed + .euiHeader--fixed {
    top: 49px;
}

.euiHeader--dark {
    background-color: #25282f;
    border-bottom-color: #25282f;
}

.euiHeader--dark .euiHeaderLogo__text,
.euiHeader--dark .euiHeaderLink,
.euiHeader--dark .euiHeaderSectionItemButton {
    color: #fff;
}

.euiHeader--dark .euiHeaderSectionItem:after {
    background: #69707d;
}

.euiHeader--dark .euiHeaderLogo:hover,
.euiHeader--dark .euiHeaderLink:hover,
.euiHeader--dark .euiHeaderSectionItemButton:hover {
    background: rgba(105, 112, 125, 0.5);
}

.euiHeader--dark .euiHeaderSectionItemButton__notification--badge {
    box-shadow: 0 0 0 1px #25282f;
}

.euiHeader--dark .euiHeaderSectionItemButton__notification--dot {
    stroke: #25282f;
}

.euiHeaderProfile {
    padding: 16px;
}

.euiHeaderLinks {
    display: flex;
}

.euiHeaderLinks__list {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.euiHeaderLinks__list--gutterXS > * {
    margin: 0 4px;
}

.euiHeaderLinks__list--gutterS > * {
    margin: 0 8px;
}

.euiHeaderLinks__list--gutterM > * {
    margin: 0 12px;
}

.euiHeaderLinks__list--gutterL > * {
    margin: 0 24px;
}

.euiHeaderLinks__mobileList .euiHeaderLink {
    display: block;
    width: 100%;
    padding: 8px;
}

.euiHeaderLinks__mobileList .euiHeaderLink > span {
    justify-content: flex-start;
}

.euiHeaderLogo {
    text-align: left;
    position: relative;
    height: 48px;
    line-height: 48px;
    min-width: 49px;
    padding: 0 13px 0 12px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    white-space: nowrap;
}

.euiHeaderLogo:hover {
    text-decoration: underline;
}

.euiHeaderLogo:focus {
    text-decoration: underline;
    background: #fae9ea;
}

.euiHeaderLogo:focus,
.euiHeaderLogo:hover {
    text-decoration: none;
}

.euiHeaderLogo__text {
    color: #1a1c21;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.025em;
    padding-left: 16px;
    font-weight: 300;
}

@media only screen and (max-width: 574px) {
    .euiHeaderLogo {
        padding: 0 12px;
    }

    .euiHeaderLogo__icon.euiIcon--xLarge {
        width: 24px;
        height: 24px;
    }

    .euiHeaderLogo__text {
        color: #1a1c21;
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        letter-spacing: -0.02em;
        font-weight: 400;
    }
}

.euiHeaderAlert {
    min-width: 300px;
    position: relative;
    margin-bottom: 24px;
    padding: 0 8px 24px;
    border-bottom: 1px solid #d3dae6;
    border-top: none;
}

.euiHeaderAlert .euiHeaderAlert__dismiss {
    opacity: 0;
    position: absolute;
    right: 12px;
    top: 12px;
    transition: opacity 250ms ease-in;
}

.euiHeaderAlert:hover .euiHeaderAlert__dismiss,
.euiHeaderAlert .euiHeaderAlert__dismiss:focus {
    opacity: 1;
}

.euiHeaderAlert .euiHeaderAlert__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
}

.euiHeaderAlert .euiHeaderAlert__text {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 16px;
}

.euiHeaderAlert .euiHeaderAlert__action {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiHeaderAlert .euiHeaderAlert__date {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    color: #69707d;
}

.euiHeaderBreadcrumbs {
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.euiHeaderSection {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
}

.euiHeaderSection--grow,
.euiHeaderSection--left {
    flex-grow: 1;
}

.euiHeaderSection--dontGrow {
    flex-grow: 0;
}

.euiHeaderSectionItem {
    position: relative;
    display: flex;
    align-items: center;
}

.euiHeaderSectionItem:after {
    position: absolute;
    content: '';
    top: 16px;
    bottom: 0;
    background: #d3dae6;
    left: 0;
}

.euiHeaderSectionItem--borderLeft:after {
    left: 0;
    width: 1px;
}

.euiHeaderSectionItem--borderRight:after {
    width: 1px;
    left: auto;
    right: 0;
}

@media only screen and (max-width: 574px) {
    .euiHeaderSectionItem {
        min-width: 36px;
    }

    .euiHeaderSectionItem--borderLeft:after,
    .euiHeaderSectionItem--borderRight:after {
        display: none;
    }
}

.euiHeaderSectionItemButton {
    position: relative;
    height: 48px;
    min-width: 48px;
    text-align: center;
    font-size: 0;
}

.euiHeaderSectionItemButton:hover {
    background: #f5f7fa;
}

.euiHeaderSectionItemButton__notification {
    position: absolute;
}

.euiHeaderSectionItemButton__notification--dot {
    top: 0;
    right: 0;
    stroke: #fff;
}

.euiHeaderSectionItemButton__notification--badge {
    top: 9%;
    right: 9%;
    box-shadow: 0 0 0 1px #fff;
}

.euiHeaderSectionItemButton__content {
    display: inline-block;
}

@media only screen and (max-width: 574px) {
    .euiHeaderSectionItemButton {
        min-width: 36px;
    }

    .euiHeaderSectionItemButton__notification.euiHeaderSectionItemButton__notification--dot {
        width: 16px;
        height: 16px;
        top: 9%;
    }
}

.euiHealth {
    display: inline-block;
}

.euiHealth--textSizeXS {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
}

.euiHealth--textSizeS {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiHealth--textSizeM {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.5;
}

.euiHealth--textSizeInherit {
    font-size: inherit;
}

.euiHorizontalRule {
    border: none;
    height: 1px;
    background-color: #d3dae6;
    flex-shrink: 0;
    flex-grow: 0;
}

.euiHorizontalRule.euiHorizontalRule--full {
    width: 100%;
}

.euiHorizontalRule.euiHorizontalRule--half {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.euiHorizontalRule.euiHorizontalRule--quarter {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}

.euiHorizontalRule--marginXSmall {
    margin: 8px 0;
}

.euiHorizontalRule--marginSmall {
    margin: 12px 0;
}

.euiHorizontalRule--marginMedium {
    margin: 16px 0;
}

.euiHorizontalRule--marginLarge {
    margin: 24px 0;
}

.euiHorizontalRule--marginXLarge {
    margin: 32px 0;
}

.euiHorizontalRule--marginXXLarge {
    margin: 40px 0;
}

.euiIcon {
    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    transform: translate(0, 0);
}

.euiIcon:focus {
    opacity: 1;
    background: #fae9ea;
}

.euiIcon--app {
    fill: #343741;
}

.euiIcon--app .euiIcon__fillSecondary {
    fill: #017d73;
}

.euiIcon-isLoading {
    opacity: 0.05;
    background-color: currentColor;
    border-radius: 4px;
}

.euiIcon-isLoaded {
    animation: euiIconLoading 250ms ease-in 0s 1 forwards;
}

.euiIcon--accent {
    color: #dd0a73;
}

.euiIcon--danger {
    color: #bd271e;
}

.euiIcon--ghost {
    color: #fff;
}

.euiIcon--secondary {
    color: #017d73;
}

.euiIcon--success {
    color: #017d73;
}

.euiIcon--subdued {
    color: #6a717d;
}

.euiIcon--text {
    color: #343741;
}

.euiIcon--warning {
    color: #be8100;
}

.euiIcon--text,
.euiIcon--text .euiIcon__fillSecondary,
.euiIcon--subdued,
.euiIcon--subdued .euiIcon__fillSecondary,
.euiIcon--primary,
.euiIcon--primary .euiIcon__fillSecondary,
.euiIcon--customColor,
.euiIcon--customColor .euiIcon__fillSecondary {
    fill: currentColor;
}

.euiIcon__fillNegative {
    fill: #343741;
}

.euiIcon--small {
    width: 12px;
    height: 12px;
}

.euiIcon--medium {
    width: 16px;
    height: 16px;
}

.euiIcon--large {
    width: 24px;
    height: 24px;
}

.euiIcon--xLarge {
    width: 32px;
    height: 32px;
}

.euiIcon--xxLarge {
    width: 40px;
    height: 40px;
}

@keyframes euiIconLoading {
    0% {
        opacity: 0.05;
    }
    100% {
        opacity: 1;
    }
}

/**
 * 1. Fix for IE where the image correctly resizes in width but doesn't collapse its height
      (https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421)
 */
.euiImage {
    display: inline-block;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    /* 1 */
    line-height: 0;
}

.euiImage .euiImage__img {
    margin-bottom: 0;
}

.euiImage.euiImage--hasShadow .euiImage__img {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiImage .euiImage__button {
    position: relative;
    cursor: pointer;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiImage .euiImage__button:hover .euiImage__icon {
    visibility: visible;
    fill-opacity: 1;
}

.euiImage .euiImage__button--fullWidth {
    width: 100%;
}

.euiImage.euiImage--allowFullScreen:hover .euiImage__caption {
    text-decoration: underline;
}

.euiImage.euiImage--allowFullScreen:not(.euiImage--hasShadow) .euiImage__button:hover,
.euiImage.euiImage--allowFullScreen:not(.euiImage--hasShadow) .euiImage__button:focus {
    box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
        0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiImage.euiImage--allowFullScreen.euiImage--hasShadow .euiImage__button:hover,
.euiImage.euiImage--allowFullScreen.euiImage--hasShadow .euiImage__button:focus {
    box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
}

.euiImage.euiImage--small .euiImage__img {
    width: 7.5rem;
}

.euiImage.euiImage--medium .euiImage__img {
    width: 12.5rem;
}

.euiImage.euiImage--large .euiImage__img {
    width: 22.5rem;
}

.euiImage.euiImage--xlarge .euiImage__img {
    width: 37.5rem;
}

.euiImage.euiImage--fullWidth {
    width: 100%;
}

.euiImage.euiImage--original .euiImage__img {
    width: auto;
    max-width: 100%;
}

.euiImage.euiImage--floatLeft {
    float: left;
}

.euiImage.euiImage--floatLeft[class*='euiImage--margin'] {
    margin-left: 0;
    margin-top: 0;
}

.euiImage.euiImage--floatRight {
    float: right;
}

.euiImage.euiImage--floatRight[class*='euiImage--margin'] {
    margin-right: 0;
    margin-top: 0;
}

.euiImage.euiImage--marginSmall {
    margin: 8px;
}

.euiImage.euiImage--marginMedium {
    margin: 16px;
}

.euiImage.euiImage--marginLarge {
    margin: 24px;
}

.euiImage.euiImage--marginXlarge {
    margin: 32px;
}

.euiImage__img {
    width: 100%;
    vertical-align: middle;
}

.euiImage__caption {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-top: 4px;
    text-align: center;
}

.euiImage__icon {
    visibility: hidden;
    fill-opacity: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    transition: fill-opacity 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    cursor: pointer;
}

.euiImage-isFullScreen {
    position: relative;
    max-height: 80vh;
    max-width: 80vw;
    animation: euiImageFullScreen 500ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.euiImage-isFullScreen:hover .euiImage__button {
    box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
}

.euiImage-isFullScreen:hover .euiImage__caption {
    text-decoration: underline;
}

.euiImage-isFullScreen__img {
    max-height: 80vh;
    max-width: 80vw;
    vertical-align: middle;
    cursor: pointer;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiImage-isFullScreenCloseIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    pointer-events: none;
}

@keyframes euiImageFullScreen {
    0% {
        opacity: 0;
        transform: translateY(64px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 574px) {
    .euiImage.euiImage--floatLeft,
    .euiImage.euiImage--floatRight {
        float: none;
    }

    .euiImage.euiImage--floatLeft[class*='euiImage--margin'],
    .euiImage.euiImage--floatRight[class*='euiImage--margin'] {
        margin-top: inherit;
        margin-right: inherit;
        margin-bottom: inherit;
        margin-left: inherit;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiImage.euiImage--floatLeft,
    .euiImage.euiImage--floatRight {
        float: none;
    }

    .euiImage.euiImage--floatLeft[class*='euiImage--margin'],
    .euiImage.euiImage--floatRight[class*='euiImage--margin'] {
        margin-top: inherit;
        margin-right: inherit;
        margin-bottom: inherit;
        margin-left: inherit;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiImage.euiImage--floatLeft,
    .euiImage.euiImage--floatRight {
        float: none;
    }

    .euiImage.euiImage--floatLeft[class*='euiImage--margin'],
    .euiImage.euiImage--floatRight[class*='euiImage--margin'] {
        margin-top: inherit;
        margin-right: inherit;
        margin-bottom: inherit;
        margin-left: inherit;
    }
}

/**
 * 1. Default to grid of 3
 */
.euiKeyPadMenu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 288px;
    max-width: 100%;
}

/**
 * 1. If this class is applied to a button, we need to override the Chrome default font.
 * 2. If it has a BetaBadge, make sure only the first letter shows
 */
.euiKeyPadMenuItem {
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    /* 1 */
    display: block;
    padding: 4px;
    height: 96px;
    width: 96px;
    color: #69707d;
    border: 1px solid #d3dae6;
    border-color: transparent;
    border-radius: 4px;
    transition: border-color 150ms ease-in, box-shadow 150ms ease-in;
}

.euiKeyPadMenuItem:not(:disabled):hover,
.euiKeyPadMenuItem:not(:disabled):focus {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    border-color: #d3dae6;
}

.euiKeyPadMenuItem:not(:disabled):hover .euiKeyPadMenuItem__icon,
.euiKeyPadMenuItem:not(:disabled):focus .euiKeyPadMenuItem__icon {
    transform: translateY(0);
}

.euiKeyPadMenuItem:disabled {
    color: #afb0b3;
    cursor: not-allowed;
}

.euiKeyPadMenuItem:disabled .euiKeyPadMenuItem__icon {
    filter: grayscale(100%);
}

.euiKeyPadMenuItem:disabled .euiKeyPadMenuItem__icon svg * {
    fill: #afb0b3;
}

.euiKeyPadMenuItem__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.euiKeyPadMenuItem--hasBetaBadge .euiKeyPadMenuItem__inner {
    position: relative;
}

.euiKeyPadMenuItem--hasBetaBadge .euiKeyPadMenuItem__inner .euiKeyPadMenuItem__betaBadgeWrapper {
    position: absolute;
    top: 4px;
    right: 8px;
    z-index: 3;
}

.euiKeyPadMenuItem--hasBetaBadge
    .euiKeyPadMenuItem__inner
    .euiKeyPadMenuItem__betaBadgeWrapper
    .euiKeyPadMenuItem__betaBadge:not(.euiBetaBadge--iconOnly) {
    padding: 0 6px;
    /* 2 */
    overflow: hidden;
    /* 2 */
    letter-spacing: 3rem;
    /* 2 */
}

.euiKeyPadMenuItem__betaBadge {
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #000;
    background-color: #e9edf3;
    box-shadow: none;
}

.euiKeyPadMenuItem__betaBadge .euiBetaBadge__icon {
    width: 12px;
    height: 12px;
}

.euiKeyPadMenuItem__icon {
    transition: transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transform: translateY(2px);
    margin-bottom: 12px;
}

.euiKeyPadMenuItem__label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
}

.euiLink {
    text-align: left;
}

.euiLink:hover {
    text-decoration: underline;
}

.euiLink:focus {
    text-decoration: underline;
    background: #fae9ea;
}

.euiLink .euiLink__externalIcon {
    margin-left: 4px;
}

.euiLink.euiLink-disabled {
    text-decoration: none;
    cursor: default;
}

.euiLink.euiLink--subdued {
    color: #6a717d;
}

.euiLink.euiLink--subdued:hover,
.euiLink.euiLink--subdued:focus {
    color: #535861;
    text-decoration: underline;
}

.euiLink.euiLink--subdued:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--primary:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--secondary {
    color: #017d73;
}

.euiLink.euiLink--secondary:hover,
.euiLink.euiLink--secondary:focus {
    color: #014a44;
    text-decoration: underline;
}

.euiLink.euiLink--secondary:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--accent {
    color: #dd0a73;
}

.euiLink.euiLink--accent:hover,
.euiLink.euiLink--accent:focus {
    color: #ac085a;
    text-decoration: underline;
}

.euiLink.euiLink--accent:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--warning {
    color: #9b6900;
}

.euiLink.euiLink--warning:hover,
.euiLink.euiLink--warning:focus {
    color: #684600;
    text-decoration: underline;
}

.euiLink.euiLink--warning:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--danger {
    color: #bd271e;
}

.euiLink.euiLink--danger:hover,
.euiLink.euiLink--danger:focus {
    color: #911e17;
    text-decoration: underline;
}

.euiLink.euiLink--danger:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--text {
    color: #343741;
}

.euiLink.euiLink--text:hover,
.euiLink.euiLink--text:focus {
    color: #1d1f25;
    text-decoration: underline;
}

.euiLink.euiLink--text:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiLink.euiLink--ghost {
    color: #fff;
}

.euiLink.euiLink--ghost:hover,
.euiLink.euiLink--ghost:focus {
    color: #e6e6e6;
    text-decoration: underline;
}

.euiLink.euiLink--ghost:focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

button.euiLink {
    user-select: text;
}

/**
 * The List Group component provides neatly styled lists containing plain text
 * or links. The outer container can be bordered, with padding, or borderless
 * with links flush to the sides.
 */
.euiListGroup.euiListGroup-flush {
    padding: 0;
    border: none;
}

.euiListGroup.euiListGroup-bordered {
    border-radius: 4px;
    border: 1px solid #d3dae6;
}

.euiListGroup-maxWidthDefault {
    max-width: 400px;
}

.euiListGroup--gutterSmall {
    padding: 8px;
}

.euiListGroup--gutterSmall .euiListGroupItem:not(:first-of-type) {
    margin-top: 8px;
}

.euiListGroup--gutterMedium {
    padding: 16px;
}

.euiListGroup--gutterMedium .euiListGroupItem:not(:first-of-type) {
    margin-top: 16px;
}

.euiListGroupItem {
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition: background-color 150ms;
    position: relative;
}

.euiListGroupItem.euiListGroupItem-isActive,
.euiListGroupItem.euiListGroupItem-isClickable:hover {
    background-color: rgba(211, 218, 230, 0.25);
}

.euiListGroupItem.euiListGroupItem-isClickable:focus-within {
    background-color: rgba(211, 218, 230, 0.25);
}

.euiListGroupItem.euiListGroupItem--ghost.euiListGroupItem-isClickable:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.euiListGroupItem.euiListGroupItem--ghost.euiListGroupItem-isClickable:focus-within {
    background-color: rgba(255, 255, 255, 0.1);
}

.euiListGroupItem.euiListGroupItem-isClickable:hover .euiListGroupItem__button,
.euiListGroupItem .euiListGroupItem__button:hover,
.euiListGroupItem .euiListGroupItem__button:focus {
    text-decoration: underline;
}

.euiListGroupItem.euiListGroupItem-isDisabled,
.euiListGroupItem.euiListGroupItem-isDisabled:hover,
.euiListGroupItem.euiListGroupItem-isDisabled:focus,
.euiListGroupItem.euiListGroupItem-isDisabled .euiListGroupItem__button:hover,
.euiListGroupItem.euiListGroupItem-isDisabled .euiListGroupItem__button:focus {
    color: #c2c3c6;
    cursor: not-allowed;
    background-color: transparent;
    text-decoration: none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiListGroupItem__button:hover,
    .euiListGroupItem__button:focus {
        background-color: rgba(211, 218, 230, 0.25);
        border-radius: 4px;
    }

    .euiListGroupItem__button:hover .euiListGroupItem--ghost .euiListGroupItem__button:hover,
    .euiListGroupItem__button:hover .euiListGroupItem--ghost .euiListGroupItem__button:focus,
    .euiListGroupItem__button:focus .euiListGroupItem--ghost .euiListGroupItem__button:hover,
    .euiListGroupItem__button:focus .euiListGroupItem--ghost .euiListGroupItem__button:focus {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.euiListGroupItem__text,
.euiListGroupItem__button {
    line-height: 24px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    text-align: left;
    max-width: 100%;
    font-weight: inherit;
}

.euiListGroupItem-hasExtraAction .euiListGroupItem__text,
.euiListGroupItem-hasExtraAction .euiListGroupItem__button {
    max-width: calc(100% - 32px);
}

.euiListGroupItem--text .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--text .euiListGroupItem__button:not(:disabled) {
    color: #343741;
}

.euiListGroupItem--subdued .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--subdued .euiListGroupItem__button:not(:disabled) {
    color: #6a717d;
}

.euiListGroupItem--ghost .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--ghost .euiListGroupItem__button:not(:disabled) {
    color: #fff;
}

.euiListGroupItem-isActive:not(.euiListGroupItem--ghost) .euiListGroupItem__text,
.euiListGroupItem-isActive:not(.euiListGroupItem--ghost) .euiListGroupItem__button {
    color: #343741;
}

.euiListGroupItem__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.euiListGroupItem__extraAction {
    opacity: 0;
    margin-right: 8px;
    transition: opacity 150ms;
}

.euiListGroupItem:not(.euiListGroupItem-isDisabled):focus .euiListGroupItem__extraAction,
.euiListGroupItem:not(.euiListGroupItem-isDisabled):hover .euiListGroupItem__extraAction,
.euiListGroupItem__extraAction.euiListGroupItem__extraAction-alwaysShow,
.euiListGroupItem__extraAction:focus {
    opacity: 1;
}

.euiListGroupItem__icon {
    margin-right: 12px;
    flex-grow: 0;
    flex-shrink: 0;
}

.euiListGroupItem--xSmall {
    font-size: 12px;
}

.euiListGroupItem--small {
    font-size: 14px;
}

.euiListGroupItem--medium {
    font-size: 16px;
}

.euiListGroupItem--large {
    font-size: 20px;
}

.euiListGroupItem--xSmall,
.euiListGroupItem--small {
    font-weight: 500;
    letter-spacing: 0;
}

.euiListGroupItem--xSmall .euiListGroupItem__button,
.euiListGroupItem--xSmall .euiListGroupItem__text {
    line-height: 16px;
}

.euiListGroupItem--large .euiListGroupItem__button,
.euiListGroupItem--large .euiListGroupItem__text {
    line-height: 32px;
}

.euiListGroupItem--wrapText .euiListGroupItem__button,
.euiListGroupItem--wrapText .euiListGroupItem__text {
    width: 100%;
    word-break: break-word;
}

.euiListGroupItem--wrapText .euiListGroupItem__label {
    white-space: inherit;
}

.euiListGroup-flush .euiListGroupItem {
    border-radius: 0;
}

.euiListGroup-bordered .euiListGroupItem:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.euiListGroup-bordered .euiListGroupItem:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.euiListGroupItem__tooltip {
    width: 100%;
}

.euiPinnableListGroup__itemExtraAction svg {
    transform: rotate(45deg);
}

.euiPinnableListGroup__itemExtraAction-pinned:not(:hover):not(:focus) {
    color: #8c919a;
}

.euiLoadingKibana {
    position: relative;
    display: inline-block;
}

.euiLoadingKibana:before,
.euiLoadingKibana:after {
    position: absolute;
    content: '';
    width: 90%;
    left: 5%;
    border-radius: 50%;
    opacity: 0.2;
    z-index: 1;
}

.euiLoadingKibana:before {
    box-shadow: 0 0 8px #000;
    animation: 1s euiLoadingKibanaPulsateAndFade cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiLoadingKibana:after {
    background-color: #000;
    animation: 1s euiLoadingKibanaPulsate cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiLoadingKibana .euiLoadingKibana__icon {
    display: block;
}

/**
 * 1. Requires pixel math for animation.
 */
.euiLoadingKibana--medium {
    width: 16px;
}

.euiLoadingKibana--medium:before,
.euiLoadingKibana--medium:after {
    height: 3px;
    /* 1 */
    bottom: -4px;
}

.euiLoadingKibana--medium .euiLoadingKibana__icon {
    z-index: 999;
    animation: 1s euiLoadingKibanaBounceMedium cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

/**
 * 1. Requires pixel math for animation.
 */
.euiLoadingKibana--large {
    width: 24px;
}

.euiLoadingKibana--large:before,
.euiLoadingKibana--large:after {
    height: 6px;
    /* 1 */
    bottom: -8px;
}

.euiLoadingKibana--large .euiLoadingKibana__icon {
    animation: 1s euiLoadingKibanaBounceLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiLoadingKibana--xLarge {
    width: 32px;
}

.euiLoadingKibana--xLarge:before,
.euiLoadingKibana--xLarge:after {
    height: 8px;
    bottom: -12px;
}

.euiLoadingKibana--xLarge .euiLoadingKibana__icon {
    animation: 1s euiLoadingKibanaBounceXLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

@keyframes euiLoadingKibanaBounceMedium {
    50% {
        transform: translateY(-8px);
    }
}

@keyframes euiLoadingKibanaBounceLarge {
    50% {
        transform: translateY(-12px);
    }
}

@keyframes euiLoadingKibanaBounceXLarge {
    50% {
        transform: translateY(-16px);
    }
}

@keyframes euiLoadingKibanaPulsateAndFade {
    0% {
        opacity: 0;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes euiLoadingKibanaPulsate {
    0% {
        opacity: 0.15;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.05;
    }
    100% {
        opacity: 0.15;
    }
}

.euiLoadingElastic {
    position: relative;
    display: inline-block;
}

.euiLoadingElastic--medium {
    width: 16px;
}

.euiLoadingElastic--large {
    width: 24px;
}

.euiLoadingElastic--xLarge {
    width: 32px;
}

.euiLoadingElastic--xxLarge {
    width: 40px;
}

.euiLoadingElastic path {
    animation-name: euiLoadingElastic;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    transform-style: preserve-3d;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0, 0.63, 0.49, 1);
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
}

.euiLoadingElastic path:nth-of-type(1) {
    animation-delay: 0s;
}

.euiLoadingElastic path:nth-of-type(2) {
    animation-delay: 0.035s;
}

.euiLoadingElastic path:nth-of-type(3) {
    animation-delay: 0.125s;
}

.euiLoadingElastic path:nth-of-type(4) {
    animation-delay: 0.155s;
}

.euiLoadingElastic path:nth-of-type(5) {
    animation-delay: 0.075s;
}

.euiLoadingElastic path:nth-of-type(6) {
    animation-delay: 0.06s;
}

@keyframes euiLoadingElastic {
    0% {
        transform: scale3d(0, 0, -0.7);
        opacity: 0;
    }
    40% {
        transform: scale3d(1, 1, 2);
        opacity: 1;
    }
    50% {
        transform: scale3d(0.99, 0.99, 2);
    }
    70% {
        transform: scale3d(0.96, 0.96, -2.5);
    }
    100% {
        transform: scale3d(0.98, 0.98, 2);
    }
}

.euiLoadingChart {
    height: 32px;
    z-index: 500;
    overflow: hidden;
    display: inline-block;
}

.euiLoadingChart__bar {
    height: 100%;
    width: 8px;
    display: inline-block;
    margin-bottom: -16px;
    margin-left: 2px;
    animation: euiLoadingChart 1s infinite;
}

.euiLoadingChart__bar:nth-child(1) {
    background-color: #54b399;
}

.euiLoadingChart__bar:nth-child(2) {
    background-color: #6092c0;
    animation-delay: 0.1s;
}

.euiLoadingChart__bar:nth-child(3) {
    background-color: #d36086;
    animation-delay: 0.2s;
}

.euiLoadingChart__bar:nth-child(4) {
    background-color: #9170b8;
    animation-delay: 0.3s;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(1) {
    background-color: #d3dae6;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(2) {
    background-color: #cbd1dd;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(3) {
    background-color: #c2c9d4;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(4) {
    background-color: #bac0ca;
}

.euiLoadingChart--medium {
    height: 16px;
}

.euiLoadingChart--medium > span {
    width: 2px;
    margin-left: 2px;
    margin-bottom: 8px;
}

.euiLoadingChart--large {
    height: 24px;
}

.euiLoadingChart--large > span {
    width: 4px;
    margin-left: 2px;
    margin-bottom: 12px;
}

.euiLoadingChart--xLarge {
    height: 32px;
}

.euiLoadingChart--xLarge > span {
    width: 8px;
    margin-left: 4px;
    margin-bottom: 16px;
}

@keyframes euiLoadingChart {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(66%);
    }
    100% {
        transform: translateY(0);
    }
}

.euiLoadingContent__loader {
    display: block;
    width: 100%;
}

.euiLoadingContent__singleLine {
    display: block;
    width: 100%;
    height: 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    overflow: hidden;
}

.euiLoadingContent__singleLine:last-child:not(:only-child) {
    width: 75%;
}

.euiLoadingContent__singleLineBackground {
    display: block;
    width: 220%;
    height: 100%;
    background: linear-gradient(to right, #d5dce7 45%, #e9edf3 50%, #d5dce7 55%);
    animation: euiLoadingContentGradientLoad 1.5s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

@keyframes euiLoadingContentGradientLoad {
    0% {
        transform: translateX(-53%);
    }
    100% {
        transform: translateX(0);
    }
}

.euiLoadingSpinner--small {
    width: 8px;
    height: 8px;
    border-width: 1px;
}

.euiLoadingSpinner--medium {
    width: 16px;
    height: 16px;
    border-width: 1px;
}

.euiLoadingSpinner--large {
    width: 24px;
    height: 24px;
}

.euiLoadingSpinner--xLarge {
    width: 32px;
    height: 32px;
}

@keyframes euiLoadingSpinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.euiMarkdownEditor--isPreviewing .euiMarkdownEditor__toggleContainer {
    display: none;
}

.euiMarkdownEditor--fullHeight {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.euiMarkdownEditor--fullHeight .euiMarkdownEditorTextArea {
    resize: none;
}

.euiMarkdownEditor--fullHeight .euiMarkdownEditorDropZone {
    height: 100%;
}

.euiMarkdownEditorDropZone {
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: '150px';
}

.euiMarkdownEditorDropZone__input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
}

.euiMarkdownEditorDropZone__input:hover {
    cursor: pointer;
}

.euiMarkdownEditorDropZone__input:hover:disabled {
    cursor: not-allowed;
}

.euiMarkdownFormat {
    font-family: 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    color: #343741;
    font-weight: 400;
}

.euiMarkdownFormat--reversed {
    color: #f5f7fa;
}

.euiMarkdownFormat > div > *:first-child {
    margin-top: 0 !important;
}

.euiMarkdownFormat > div > * {
    margin-top: 0;
    margin-bottom: 1em;
}

.euiMarkdownFormat > div > *:last-child,
.euiMarkdownFormat .euiCheckbox {
    margin-bottom: 0 !important;
}

.euiMarkdownFormat .euiCheckbox + *:not(.euiCheckbox) {
    margin-top: 1em;
}

.euiMarkdownFormat p,
.euiMarkdownFormat blockquote,
.euiMarkdownFormat ul,
.euiMarkdownFormat ol,
.euiMarkdownFormat dl,
.euiMarkdownFormat pre,
.euiMarkdownFormat table {
    margin-top: 0;
    margin-bottom: 1em;
    line-height: 1.5em;
}

.euiMarkdownFormat strong {
    font-weight: 600;
}

.euiMarkdownFormat h1,
.euiMarkdownFormat h2,
.euiMarkdownFormat h3,
.euiMarkdownFormat h4,
.euiMarkdownFormat h5,
.euiMarkdownFormat h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

.euiMarkdownFormat h1 {
    font-size: 2.25em;
    line-height: 1.333333em;
    font-weight: 300;
}

.euiMarkdownFormat h2 {
    font-size: 1.75em;
    line-height: 1.428571em;
    font-weight: 300;
}

.euiMarkdownFormat h3 {
    font-size: 1.25em;
    line-height: 1.6em;
    font-weight: 600;
}

.euiMarkdownFormat h4 {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 600;
}

.euiMarkdownFormat h5 {
    font-size: 0.875em;
    line-height: 1.142857em;
    font-weight: 700;
}

.euiMarkdownFormat h6 {
    font-size: 0.75em;
    line-height: 1.333333em;
    font-weight: 700;
    text-transform: uppercase;
}

.euiMarkdownFormat img {
    max-width: 100%;
    box-sizing: content-box;
    border-style: none;
}

.euiMarkdownFormat blockquote {
    padding: 0 1em;
    border-left: 0.25em solid rgba(0, 0, 0, 0.15);
}

.euiMarkdownFormat--reversed blockquote {
    border-left-color: rgba(255, 255, 255, 0.15);
}

.euiMarkdownFormat hr {
    border: none;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    margin: 1.5em 0;
}

.euiMarkdownFormat hr::before {
    display: table;
    content: '';
}

.euiMarkdownFormat hr::after {
    display: table;
    clear: both;
    content: '';
}

.euiMarkdownFormat ul,
.euiMarkdownFormat ol {
    padding-left: 1.5em;
    margin-top: 0;
    margin-bottom: 1em;
}

.euiMarkdownFormat ul {
    list-style-type: disc;
}

.euiMarkdownFormat ol {
    list-style-type: decimal;
}

.euiMarkdownFormat ul ul {
    list-style-type: circle;
}

.euiMarkdownFormat ol ol,
.euiMarkdownFormat ul ol {
    list-style-type: lower-roman;
}

.euiMarkdownFormat ul ul ol,
.euiMarkdownFormat ul ol ol,
.euiMarkdownFormat ol ul ol,
.euiMarkdownFormat ol ol ol {
    list-style-type: lower-alpha;
}

.euiMarkdownFormat dd {
    margin-left: 0;
}

.euiMarkdownFormat ul ul,
.euiMarkdownFormat ul ol,
.euiMarkdownFormat ol ol,
.euiMarkdownFormat ol ul {
    margin-top: 0;
    margin-bottom: 0;
}

.euiMarkdownFormat li > p {
    margin-bottom: 0.5em;
}

.euiMarkdownFormat li + li {
    margin-top: 0.25em;
}

.euiMarkdownFormat .task-list-item {
    list-style-type: none;
}

.euiMarkdownFormat .task-list-item + .task-list-item {
    margin-top: 0.25em;
}

.euiMarkdownFormat .task-list-item input {
    margin: 0 0.2em 0.25em -1.6em;
    vertical-align: middle;
}

.euiMarkdownFormat table {
    display: block;
    width: 100%;
    overflow: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-spacing: 0;
    border-collapse: collapse;
}

.euiMarkdownFormat td,
.euiMarkdownFormat th {
    padding: 0;
}

.euiMarkdownFormat table th,
.euiMarkdownFormat table td {
    padding: 0.25em 0.5em;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.euiMarkdownFormat table th:last-child,
.euiMarkdownFormat table td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.euiMarkdownFormat table tr {
    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.euiMarkdownEditorFooter {
    display: inline-flex;
    padding: 4px;
    border: 1px solid #d3dae6;
    align-items: center;
    background: #fafbfd;
}

.euiMarkdownEditorFooter__popover {
    width: 300px;
}

.euiMarkdownEditorFooter__actions {
    flex: 1;
    display: inline-flex;
}

.euiMarkdownEditorFooter__actions > button,
.euiMarkdownEditorFooter__actions > span {
    margin-right: 4px;
    align-self: center;
}

.euiMarkdownEditorFooter__actions .euiMarkdownEditorFooter__uploadError {
    position: relative;
    left: -1px;
    line-height: 1;
    border-radius: 4px;
}

.euiMarkdownEditorFooter__actions .euiMarkdownEditorFooter__uploadError > span {
    padding: 0 4px;
}

.euiMarkdownEditorFooter__help {
    justify-self: flex-end;
}

.euiMarkdownEditorFooter__help > svg {
    width: 26px;
}

.euiMarkdownEditorFooter__errors > svg {
    color: #6a717d;
}

.euiMarkdownEditorPreview {
    scrollbar-width: thin;
    background: #fff;
    min-height: '150px';
    overflow-y: auto;
    border: 1px solid #d3dae6;
    padding: 12px;
}

.euiMarkdownEditorPreview::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiMarkdownEditorPreview::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiMarkdownEditorPreview::-webkit-scrollbar-corner,
.euiMarkdownEditorPreview::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiMarkdownEditorTextArea {
    font-family: 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 400;
    letter-spacing: -0.005em;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-kerning: normal;
    font-size: 14px;
    color: #343741;
    scrollbar-width: thin;
    width: 100%;
    height: 100%;
    min-height: '150px';
    padding: 12px;
    border: 1px solid #d3dae6;
    border-bottom: none;
    line-height: 1.5;
    resize: vertical;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    margin: 0;
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .euiMarkdownEditorTextArea {
        line-height: 1em;
    }
}

.euiMarkdownEditorTextArea::-webkit-input-placeholder {
    color: #69707d;
}

.euiMarkdownEditorTextArea::-moz-placeholder {
    color: #69707d;
}

.euiMarkdownEditorTextArea:-ms-input-placeholder {
    color: #69707d;
}

.euiMarkdownEditorTextArea:-moz-placeholder {
    color: #69707d;
}

.euiMarkdownEditorTextArea::placeholder {
    color: #69707d;
}

.euiMarkdownEditorTextArea::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiMarkdownEditorTextArea::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiMarkdownEditorTextArea::-webkit-scrollbar-corner,
.euiMarkdownEditorTextArea::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiMarkdownEditorToolbar {
    display: flex;
    flex-wrap: wrap;
    background: #f5f7fa;
    border: 1px solid #d3dae6;
    border-color: #d3dae6;
    border-bottom: none;
    padding: 4px;
}

.euiMarkdownEditorToolbar__buttons {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
}

.euiMarkdownEditorToolbar__buttons > * {
    margin-right: 4px;
}

.euiMarkdownEditorToolbar__divider {
    content: '';
    height: 24px;
    display: block;
    margin-left: 4px;
    padding-right: 4px;
    border-left: 1px solid #d3dae6;
}

.euiMarkdownTooltip__icon {
    transform: translateY(-1px);
}

.euiMark {
    background-color: transparent;
    font-weight: 700;
    color: #343741;
}

/**
 * 1. Fix IE overflow issue (min-height) by adding a separate wrapper for the
 *    flex display. https://github.com/philipwalton/flexbugs#flexbug-3
 * 2. IE has trouble with min-widths on flex elements. Use the pixel value
 *    from our forms since that's usually the smallest we want them.
 */
.euiModal {
    border: 1px solid #d3dae6;
    box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
        0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    border-color: #c6cad1;
    border-top-color: #e3e4e8;
    border-bottom-color: #aaafba;
    display: flex;
    /* 1 */
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    z-index: 8000;
    min-width: 400px;
    animation: euiModal 350ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.euiModal .euiModal__flex {
    /* 1 */
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    overflow: hidden;
}

.euiModal--maxWidth-default {
    max-width: 768px;
}

.euiModal--confirmation {
    min-width: 400px;
}

.euiModalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px 16px 24px;
    flex-grow: 0;
    flex-shrink: 0;
}

.euiModalHeader__title {
    color: #1a1c21;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 300;
    letter-spacing: -0.04em;
}

.euiModalBody {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-corner,
.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiModalBody .euiModalBody__overflow:focus {
    outline: none;
    /* 1 */
}

.euiModalFooter {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px 24px;
    flex-grow: 0;
    flex-shrink: 0;
}

.euiModalFooter > * + * {
    margin-left: 16px;
}

.euiModalHeader + .euiModalFooter {
    padding-top: 8px;
}

.euiModalBody:last-of-type .euiModalBody__overflow {
    padding-bottom: 24px;
}

.euiModal__closeIcon {
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 3;
}

@keyframes euiModal {
    0% {
        opacity: 0;
        transform: translateY(32px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 574px) {
    .euiModal {
        position: fixed;
        width: 100vw !important;
        max-width: none !important;
        min-width: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 0;
        border: none;
    }

    .euiModal.euiModal--confirmation {
        box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
            0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
        top: auto;
    }

    .euiModal .euiModal__flex {
        /* 1 */
        max-height: 100vh;
    }

    .euiModalHeader {
        width: 100%;
    }

    .euiModalFooter {
        width: 100%;
        background: #f5f7fa;
        padding: 12px 24px !important;
        justify-content: stretch;
    }

    .euiModalFooter > * {
        flex: 1;
    }

    .euiModalFooter > * + * {
        margin-left: 0;
    }

    .euiModalBody {
        width: 100%;
    }

    .euiModalBody .euiModalBody__overflow {
        padding-bottom: 24px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiModal {
        position: fixed;
        width: 100vw !important;
        max-width: none !important;
        min-width: 0 !important;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 0;
        border: none;
    }

    .euiModal.euiModal--confirmation {
        box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
            0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
        top: auto;
    }

    .euiModal .euiModal__flex {
        /* 1 */
        max-height: 100vh;
    }

    .euiModalHeader {
        width: 100%;
    }

    .euiModalFooter {
        width: 100%;
        background: #f5f7fa;
        padding: 12px 24px !important;
        justify-content: stretch;
    }

    .euiModalFooter > * {
        flex: 1;
    }

    .euiModalFooter > * + * {
        margin-left: 0;
    }

    .euiModalBody {
        width: 100%;
    }

    .euiModalBody .euiModalBody__overflow {
        padding-bottom: 24px;
    }
}

.euiNotificationEvent {
    display: flex;
    padding: 12px 0 12px 12px;
    border-bottom: 1px solid #d3dae6;
}

.euiNotificationEvent:last-child {
    border-bottom: none;
}

.euiNotificationEvent--withReadState {
    padding: 12px 0 12px 8px;
}

.euiNotificationEvent__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    display: flex;
}

.euiNotificationEvent__title--isRead {
    color: #69707d !important;
}

.euiNotificationEvent__readButton {
    margin-right: 8px;
}

.euiNotificationEvent__content {
    flex: 1;
}

.euiNotificationEvent__content > * + * {
    margin-top: 8px;
    margin-right: 12px;
}

.euiNotificationEventMeta {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-right: 4px;
    min-height: 24px;
}

.euiNotificationEventMeta--hasContextMenu {
    padding-right: 24px;
}

.euiNotificationEventMeta__contextMenuWrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.euiNotificationEventMeta__section {
    margin-right: 8px;
}

.euiNotificationEventMeta__section:first-child {
    display: flex;
    flex: 1;
    align-items: center;
}

.euiNotificationEventMeta__icon {
    margin-right: 8px;
}

.euiNotificationEventMeta__badge {
    max-width: 100%;
    display: inline-grid;
}

.euiNotificationEventMeta__time {
    font-size: 12px;
    color: #6a717d;
}

.euiNotificationEventMessages {
    font-size: 14px;
}

.euiNotificationEventMessages__accordion {
    color: #69707d;
}

.euiNotificationEventMessages__accordionContent > * {
    padding-top: 8px;
}

.euiNotificationEventReadButton--isRead svg {
    fill: transparent;
    stroke-width: 1px;
    stroke: #d3dae6;
}

.euiOverlayMask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
    animation: euiAnimFadeIn 150ms ease-in;
    background: rgba(255, 255, 255, 0.8);
}

.euiBody-hasOverlayMask {
    overflow: hidden;
}

.euiOverlayMask--aboveHeader {
    z-index: 6000;
}

.euiOverlayMask--belowHeader {
    z-index: 999;
}

.euiPagination {
    display: flex;
    align-items: center;
}

.euiPagination__compressedText {
    display: inline-flex;
    align-items: center;
}

.euiPagination__compressedText > *:first-child {
    margin-right: 4px;
}

.euiPagination__compressedText > *:last-child {
    margin-left: 4px;
}

.euiPagination__list {
    display: flex;
}

@media only screen and (max-width: 574px) {
    .euiPagination__list {
        display: none;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPagination__list {
        display: none;
    }
}

.euiPaginationButton {
    font-size: 14px;
    padding: 0;
    text-align: center;
    border-radius: 4px;
}

.euiPaginationButton-isActive {
    font-weight: 700;
}

.euiPaginationButton-isActive.euiPaginationButton-isActive .euiButtonEmpty__content {
    cursor: default;
}

.euiPaginationButton-isActive.euiPaginationButton-isActive,
.euiPaginationButton-isActive.euiPaginationButton-isActive:hover {
    text-decoration: underline;
}

.euiPaginationButton-isPlaceholder {
    align-items: baseline;
    color: #afb0b3;
    font-size: 14px;
    padding: 0 8px;
    height: 24px;
    padding-top: 6px;
}

@media only screen and (max-width: 574px) {
    .euiPaginationButton-isPlaceholder,
    .euiPaginationButton--hideOnMobile {
        display: none;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPaginationButton-isPlaceholder,
    .euiPaginationButton--hideOnMobile {
        display: none;
    }
}

.euiPanel {
    background-color: #fff;
    border: 1px solid #d3dae6;
    border-radius: 4px;
    flex-grow: 1;
}

.euiPanel.euiPanel--flexGrowZero {
    flex-grow: 0;
}

.euiPanel.euiPanel--noBorder {
    border: none;
}

.euiPanel.euiPanel--hasShadow {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
    border: 1px solid #d3dae6;
}

.euiPanel.euiPanel--isClickable {
    display: block;
    width: 100%;
    text-align: left;
    transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiPanel.euiPanel--isClickable:hover,
.euiPanel.euiPanel--isClickable:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    transform: translateY(-2px);
    cursor: pointer;
}

.euiPanel.euiPanel--borderRadiusNone {
    border-radius: 0;
}

.euiPanel.euiPanel--borderRadiusMedium {
    border-radius: 4px;
}

.euiPanel.euiPanel--transparent {
    background-color: transparent;
}

.euiPanel.euiPanel--plain {
    background-color: #fff;
}

.euiPanel.euiPanel--subdued {
    background-color: #fafbfd;
}

.euiPanel.euiPanel--accent {
    background-color: #fce7f1;
}

.euiPanel.euiPanel--primary {
    background-color: #fae9ea;
}

.euiPanel.euiPanel--success {
    background-color: #e6f2f1;
}

.euiPanel.euiPanel--warning {
    background-color: #fef6e6;
}

.euiPanel.euiPanel--danger {
    background-color: #f8e9e9;
}

.euiPanel--hasBetaBadge {
    position: relative;
    overflow: visible;
}

.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    min-width: 40%;
    max-width: calc(100% - 32px);
}

.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiToolTipAnchor,
.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiPanel__betaBadge {
    width: 100%;
}

.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiPanel__betaBadge {
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;
}

.euiPanel--paddingSmall {
    padding: 8px;
}

.euiPanel--paddingSmall .euiPanel__betaBadgeWrapper {
    max-width: calc(100% - 16px);
}

.euiPanel--paddingMedium {
    padding: 16px;
}

.euiPanel--paddingMedium .euiPanel__betaBadgeWrapper {
    max-width: calc(100% - 32px);
}

.euiPanel--paddingLarge {
    padding: 24px;
}

.euiPanel--paddingLarge .euiPanel__betaBadgeWrapper {
    max-width: calc(100% - 48px);
}

.euiSplitPanel {
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.euiSplitPanel .euiSplitPanel__inner {
    flex-basis: 0%;
}

.euiSplitPanel.euiPanel--borderRadiusNone .euiSplitPanel__inner:first-child {
    border-top-left-radius: -1;
    border-top-right-radius: -1;
}

.euiSplitPanel.euiPanel--borderRadiusNone .euiSplitPanel__inner:last-child {
    border-bottom-left-radius: -1;
    border-bottom-right-radius: -1;
}

.euiSplitPanel.euiPanel--borderRadiusMedium .euiSplitPanel__inner:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.euiSplitPanel.euiPanel--borderRadiusMedium .euiSplitPanel__inner:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.euiSplitPanel--row {
    flex-direction: row;
}

.euiSplitPanel--row.euiSplitPanel-isResponsive {
    flex-direction: column;
}

.euiSplitPanel--row.euiPanel--borderRadiusNone .euiSplitPanel__inner:first-child {
    border-top-left-radius: -1;
    border-bottom-left-radius: -1;
}

.euiSplitPanel--row.euiPanel--borderRadiusNone .euiSplitPanel__inner:last-child {
    border-bottom-right-radius: -1;
    border-top-right-radius: -1;
}

.euiSplitPanel--row.euiPanel--borderRadiusMedium .euiSplitPanel__inner:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.euiSplitPanel--row.euiPanel--borderRadiusMedium .euiSplitPanel__inner:last-child {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.euiPage {
    display: flex;
    background-color: #fafbfd;
    flex-shrink: 0;
    max-width: 100%;
}

.euiPage--restrictWidth-default,
.euiPage--restrictWidth-custom {
    margin-left: auto;
    margin-right: auto;
}

.euiPage--restrictWidth-default {
    max-width: 1000px;
}

.euiPage--grow {
    flex-grow: 1;
}

.euiPage--column {
    flex-direction: column;
}

@media only screen and (max-width: 574px) {
    .euiPage {
        flex-direction: column;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPage {
        flex-direction: column;
    }
}

.euiPage--paddingSmall {
    padding: 8px;
}

.euiPage--paddingSmall .euiPageSideBar {
    min-width: 192px;
    margin-right: 8px;
}

@media only screen and (max-width: 574px) {
    .euiPage--paddingSmall .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 8px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPage--paddingSmall .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 8px;
    }
}

.euiPage--paddingSmall .euiPageHeader {
    margin-bottom: 8px;
}

.euiPage--paddingMedium {
    padding: 16px;
}

.euiPage--paddingMedium .euiPageSideBar {
    min-width: 192px;
    margin-right: 16px;
}

@media only screen and (max-width: 574px) {
    .euiPage--paddingMedium .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPage--paddingMedium .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 16px;
    }
}

.euiPage--paddingMedium .euiPageHeader {
    margin-bottom: 16px;
}

.euiPage--paddingLarge {
    padding: 24px;
}

.euiPage--paddingLarge .euiPageSideBar {
    min-width: 192px;
    margin-right: 24px;
}

@media only screen and (max-width: 574px) {
    .euiPage--paddingLarge .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 24px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPage--paddingLarge .euiPageSideBar {
        margin-right: 0;
        margin-bottom: 24px;
    }
}

.euiPage--paddingLarge .euiPageHeader {
    margin-bottom: 24px;
}

.euiPageBody {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 100%;
    max-width: 100%;
    min-width: 0;
}

.euiPageBody--restrictWidth-default,
.euiPageBody--restrictWidth-custom {
    margin-left: auto;
    margin-right: auto;
}

.euiPageBody--restrictWidth-default {
    max-width: 1000px;
}

.euiPageBody.euiPageBody--borderRadiusNone {
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}

.euiPageBody--paddingSmall {
    padding: 8px;
}

.euiPageBody--paddingSmall > .euiPageHeader:not([class*='--padding']) {
    margin-bottom: 8px;
}

.euiPageBody--paddingSmall.euiPanel > .euiPageHeader {
    border-bottom: 1px solid #d3dae6;
}

.euiPageBody--paddingSmall.euiPanel > .euiPageHeader:not(.euiPageHeader--tabsAtBottom) {
    padding-bottom: 8px;
}

.euiPageBody--paddingMedium {
    padding: 16px;
}

.euiPageBody--paddingMedium > .euiPageHeader:not([class*='--padding']) {
    margin-bottom: 16px;
}

.euiPageBody--paddingMedium.euiPanel > .euiPageHeader {
    border-bottom: 1px solid #d3dae6;
}

.euiPageBody--paddingMedium.euiPanel > .euiPageHeader:not(.euiPageHeader--tabsAtBottom) {
    padding-bottom: 16px;
}

.euiPageBody--paddingLarge {
    padding: 24px;
}

.euiPageBody--paddingLarge > .euiPageHeader:not([class*='--padding']) {
    margin-bottom: 24px;
}

.euiPageBody--paddingLarge.euiPanel > .euiPageHeader {
    border-bottom: 1px solid #d3dae6;
}

.euiPageBody--paddingLarge.euiPanel > .euiPageHeader:not(.euiPageHeader--tabsAtBottom) {
    padding-bottom: 24px;
}

.euiPageContent {
    width: 100%;
    min-width: 0;
}

.euiPageContent.euiPageContent--borderRadiusNone {
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
}

.euiPageContent.euiPageContent--verticalCenter {
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
    flex-grow: 0;
}

.euiPageContent.euiPageContent--horizontalCenter {
    width: auto;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 0;
}

.euiPageContentBody--restrictWidth-default,
.euiPageContentBody--restrictWidth-custom {
    margin-left: auto;
    margin-right: auto;
}

.euiPageContentBody--restrictWidth-default {
    max-width: 1000px;
}

.euiPageContentBody--paddingSmall {
    padding: 8px;
}

.euiPageContentBody--paddingMedium {
    padding: 16px;
}

.euiPageContentBody--paddingLarge {
    padding: 24px;
}

.euiPageContentHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.euiPageContent[class*='paddingSmall'] .euiPageContentHeader {
    margin-bottom: 8px;
}

.euiPageContent[class*='paddingMedium'] .euiPageContentHeader {
    margin-bottom: 16px;
}

.euiPageContent[class*='paddingLarge'] .euiPageContentHeader {
    margin-bottom: 24px;
}

@media only screen and (max-width: 574px) {
    .euiPageContentHeader--responsive {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPageContentHeader--responsive {
        flex-direction: column;
        align-items: flex-start;
    }
}

.euiPageContentHeaderSection + .euiPageContentHeaderSection {
    margin-left: 32px;
}

@media only screen and (max-width: 574px) {
    .euiPageContent[class*='paddingSmall']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 4px;
    }

    .euiPageContent[class*='paddingMedium']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 8px;
    }

    .euiPageContent[class*='paddingLarge']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 12px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPageContent[class*='paddingSmall']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 4px;
    }

    .euiPageContent[class*='paddingMedium']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 8px;
    }

    .euiPageContent[class*='paddingLarge']
        .euiPageContentHeader--responsive
        .euiPageContentHeaderSection
        + .euiPageContentHeaderSection {
        margin-left: 0;
        margin-top: 12px;
    }
}

/**
 * Note: Margin is added in _page.scss when EuiPage has `paddingSize`
 * 1. Prevent side bar width from changing when content width changes.
 */
.euiPageSideBar {
    min-width: 240px;
    flex: 0 1 0%;
    /* 1 */
}

@media only screen and (max-width: 574px) {
    .euiPageSideBar {
        width: 100%;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPageSideBar {
        width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiPageSideBar--sticky {
        scrollbar-width: thin;
        overflow-y: auto;
        flex-grow: 1;
        position: sticky;
        max-height: 100vh;
        top: 0;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-thumb {
        background-color: rgba(105, 112, 125, 0.5);
        border: 6px solid transparent;
        background-clip: content-box;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-corner,
    .euiPageSideBar--sticky::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .euiPageSideBar--sticky {
        scrollbar-width: thin;
        overflow-y: auto;
        flex-grow: 1;
        position: sticky;
        max-height: 100vh;
        top: 0;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-thumb {
        background-color: rgba(105, 112, 125, 0.5);
        border: 6px solid transparent;
        background-clip: content-box;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-corner,
    .euiPageSideBar--sticky::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

@media only screen and (min-width: 1200px) {
    .euiPageSideBar--sticky {
        scrollbar-width: thin;
        overflow-y: auto;
        flex-grow: 1;
        position: sticky;
        max-height: 100vh;
        top: 0;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-thumb {
        background-color: rgba(105, 112, 125, 0.5);
        border: 6px solid transparent;
        background-clip: content-box;
    }

    .euiPageSideBar--sticky::-webkit-scrollbar-corner,
    .euiPageSideBar--sticky::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

/**
 * Note: Bottom margin is added in _page.scss when EuiPage has `paddingSize`
 *       Or it's added in _page_body.scss when EuiPageBody has `paddingSize`
 */
.euiPageHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.euiPageHeader--restrictWidth-default,
.euiPageHeader--restrictWidth-custom {
    margin-left: auto;
    margin-right: auto;
}

.euiPageHeader--restrictWidth-default {
    max-width: 1000px;
}

.euiPageHeader--paddingSmall {
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 8px;
}

.euiPageHeader--paddingMedium {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
}

.euiPageHeader--paddingLarge {
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
}

.euiPageHeader--tabsAtBottom {
    margin-bottom: 0;
}

.euiPageHeader--top {
    align-items: flex-start;
}

.euiPageHeader--bottom {
    align-items: flex-end;
}

.euiPageHeader--stretch {
    align-items: stretch;
}

@media only screen and (max-width: 574px) {
    .euiPageHeader--responsive {
        flex-direction: column;
    }

    .euiPageHeader--responsiveReverse {
        flex-direction: column-reverse;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPageHeader--responsive {
        flex-direction: column;
    }

    .euiPageHeader--responsiveReverse {
        flex-direction: column-reverse;
    }
}

.euiPageHeader .euiPageHeaderContent {
    width: 100%;
}

.euiPageHeaderContent__titleIcon {
    top: -4px;
    position: relative;
    margin-right: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiPageHeaderContent__rightSideItems {
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .euiPageHeaderContent__rightSideItems {
        flex-direction: row-reverse;
    }
}

@media only screen and (min-width: 1200px) {
    .euiPageHeaderContent__rightSideItems {
        flex-direction: row-reverse;
    }
}

.euiPageHeaderSection:not(:first-of-type) {
    margin-left: 32px;
}

@media only screen and (max-width: 574px) {
    .euiPageHeader--responsive .euiPageHeaderSection {
        width: 100%;
    }

    .euiPageHeader--responsive .euiPageHeaderSection:not(:first-of-type) {
        margin-left: 0;
        margin-top: 16px;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection {
        width: 100%;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection:not(:first-of-type) {
        margin-left: 0;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection:not(:last-of-type) {
        margin-top: 16px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiPageHeader--responsive .euiPageHeaderSection {
        width: 100%;
    }

    .euiPageHeader--responsive .euiPageHeaderSection:not(:first-of-type) {
        margin-left: 0;
        margin-top: 16px;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection {
        width: 100%;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection:not(:first-of-type) {
        margin-left: 0;
    }

    .euiPageHeader--responsiveReverse .euiPageHeaderSection:not(:last-of-type) {
        margin-top: 16px;
    }
}

.euiPopover {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    max-width: 100%;
}

.euiPopover__anchor {
    display: inline-block;
}

.euiPopover--displayBlock {
    display: block;
}

.euiPopover--displayBlock .euiPopover__anchor {
    display: block;
}

/**
 * 1. Can expand further, but it looks weird if it's smaller than the originating button.
 * 2. Animation happens on the panel. But don't animate when using the attached mode like for inputs
 * 3. Make sure the panel stays within the window.
 */
.euiPopover__panel {
    box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    border-color: #d0d3d9;
    border-top-color: #d9dce0;
    border-bottom-color: #aaafba;
    position: absolute;
    min-width: 112px;
    /* 1 */
    max-width: calc(100vw - 32px);
    /* 3 */
    backface-visibility: hidden;
    pointer-events: none;
    opacity: 0;
    /* 2 */
    visibility: hidden;
    /* 2 */
    transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
        visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms;
}

.euiPopover__panel:not(.euiPopover__panel-isAttached) {
    transform: translateY(0) translateX(0) translateZ(0);
    /* 2 */
    transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
        visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
        transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms;
}

.euiPopover__panel.euiPopover__panel-isOpen {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.euiPopover__panel .euiPopover__panelArrow {
    position: absolute;
    width: 0;
    height: 0;
}

.euiPopover__panel .euiPopover__panelArrow:before {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
}

.euiPopover__panel .euiPopover__panelArrow:after {
    position: absolute;
    content: '';
    height: 0;
    width: 0;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--top:before {
    bottom: -10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #d3dae6;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--top:after {
    bottom: -9px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #fff;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--right:before {
    left: -12px;
    top: 50%;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #d3dae6;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--right:after {
    left: -11px;
    top: 50%;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--bottom:before {
    top: -12px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #d3dae6;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--bottom:after {
    top: -11px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:before {
    right: -11px;
    top: 50%;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #d3dae6;
}

.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:after {
    right: -10px;
    top: 50%;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #fff;
}

.euiPopover__panel.euiPopover__panel-noArrow .euiPopover__panelArrow {
    display: none;
}

.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--bottom {
    border-top-color: rgba(211, 218, 230, 0.8);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--top {
    box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
        0 0 2px 0 rgba(152, 162, 179, 0.2);
    border-bottom-color: rgba(211, 218, 230, 0.8);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--top,
.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--top {
    transform: translateY(8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--bottom,
.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--bottom {
    transform: translateY(-8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--left {
    transform: translateX(8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--right {
    transform: translateX(-8px) translateZ(0);
}

.euiPopoverTitle {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #d3dae6;
}

.euiPopoverTitle--paddingNone {
    padding: 0;
}

.euiPopoverTitle--paddingSmall {
    padding: 8px;
}

.euiPopoverTitle--paddingMedium {
    padding: 16px;
}

.euiPopoverTitle--paddingLarge {
    padding: 24px;
}

.euiPopover__panel.euiPanel--paddingSmall .euiPopoverTitle {
    margin: -8px -8px 8px;
}

.euiPopover__panel.euiPanel--paddingSmall
    .euiPopoverTitle:not([class*='euiPopoverTitle--padding']) {
    padding: 12px 8px;
}

.euiPopover__panel.euiPanel--paddingMedium .euiPopoverTitle {
    margin: -16px -16px 16px;
}

.euiPopover__panel.euiPanel--paddingMedium
    .euiPopoverTitle:not([class*='euiPopoverTitle--padding']) {
    padding: 12px 16px;
}

.euiPopover__panel.euiPanel--paddingLarge .euiPopoverTitle {
    margin: -24px -24px 24px;
}

.euiPopover__panel.euiPanel--paddingLarge
    .euiPopoverTitle:not([class*='euiPopoverTitle--padding']) {
    padding: 12px 24px;
}

.euiPopoverFooter {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 12px;
    border-top: 1px solid #d3dae6;
}

.euiPopoverFooter--paddingNone {
    padding: 0;
}

.euiPopoverFooter--paddingSmall {
    padding: 8px;
}

.euiPopoverFooter--paddingMedium {
    padding: 16px;
}

.euiPopoverFooter--paddingLarge {
    padding: 24px;
}

.euiPopover__panel.euiPanel--paddingSmall .euiPopoverFooter {
    margin: 8px -8px -8px;
}

.euiPopover__panel.euiPanel--paddingSmall
    .euiPopoverFooter:not([class*='euiPopoverFooter--padding']) {
    padding: 12px 8px;
}

.euiPopover__panel.euiPanel--paddingMedium .euiPopoverFooter {
    margin: 16px -16px -16px;
}

.euiPopover__panel.euiPanel--paddingMedium
    .euiPopoverFooter:not([class*='euiPopoverFooter--padding']) {
    padding: 12px 16px;
}

.euiPopover__panel.euiPanel--paddingLarge .euiPopoverFooter {
    margin: 24px -24px -24px;
}

.euiPopover__panel.euiPanel--paddingLarge
    .euiPopoverFooter:not([class*='euiPopoverFooter--padding']) {
    padding: 12px 24px;
}

.euiInputPopover {
    max-width: 400px;
}

.euiInputPopover.euiInputPopover--fullWidth {
    max-width: 100%;
}

/**
 * 1. Portal content is absolutely positioned (e.g. tooltips, popovers, flyouts).
 */
.euiBody-hasPortalContent {
    position: relative;
    /* 1 */
}

.euiProgress {
    position: relative;
    overflow: hidden;
    background-color: #d3dae6;
}

.euiProgress--xs {
    height: 2px;
}

.euiProgress--s {
    height: 4px;
}

.euiProgress--m {
    height: 8px;
}

.euiProgress--l {
    height: 16px;
}

.euiProgress--native {
    display: block;
    width: 100%;
    appearance: none;
    border: none;
}

.euiProgress--native::-webkit-progress-bar {
    background-color: #d3dae6;
}

.euiProgress--native::-webkit-progress-value {
    transition: width 250ms linear;
}

.euiProgress--native::-moz-progress-bar {
    transition: width 250ms linear;
}

/**
 * An indeterminate bar has an unreliable end time. Because of a Firefox animation issue,
 * we apply this style to a <div> instead of a <progress> element.
 * See https://css-tricks.com/html5-progress-element/ for more info.
 */
.euiProgress--indeterminate:before {
    position: absolute;
    content: '';
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(0) translateX(0%);
    animation: euiProgress 1s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiProgress--fixed {
    position: fixed;
    z-index: 1001;
}

.euiProgress--absolute {
    position: absolute;
}

.euiProgress--fixed,
.euiProgress--absolute {
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
}

.euiProgress--fixed.euiProgress--native::-webkit-progress-bar,
.euiProgress--absolute.euiProgress--native::-webkit-progress-bar {
    background-color: transparent;
}

.euiProgress--fixed.euiProgress--native::-moz-progress-bar,
.euiProgress--absolute.euiProgress--native::-moz-progress-bar {
    background-color: transparent;
}

.euiProgress--secondary.euiProgress--native::-webkit-progress-value {
    background-color: #017d73;
}

.euiProgress--secondary.euiProgress--native::-moz-progress-bar {
    background-color: #017d73;
}

.euiProgress--secondary.euiProgress--indeterminate:before {
    background-color: #017d73;
}

.euiProgress__data--secondary .euiProgress__valueText {
    color: #017d73;
}

.euiProgress--success.euiProgress--native::-webkit-progress-value {
    background-color: #017d73;
}

.euiProgress--success.euiProgress--native::-moz-progress-bar {
    background-color: #017d73;
}

.euiProgress--success.euiProgress--indeterminate:before {
    background-color: #017d73;
}

.euiProgress__data--success .euiProgress__valueText {
    color: #017d73;
}

.euiProgress--warning.euiProgress--native::-webkit-progress-value {
    background-color: #f5a700;
}

.euiProgress--warning.euiProgress--native::-moz-progress-bar {
    background-color: #f5a700;
}

.euiProgress--warning.euiProgress--indeterminate:before {
    background-color: #f5a700;
}

.euiProgress__data--warning .euiProgress__valueText {
    color: #9b6900;
}

.euiProgress--danger.euiProgress--native::-webkit-progress-value {
    background-color: #bd271e;
}

.euiProgress--danger.euiProgress--native::-moz-progress-bar {
    background-color: #bd271e;
}

.euiProgress--danger.euiProgress--indeterminate:before {
    background-color: #bd271e;
}

.euiProgress__data--danger .euiProgress__valueText {
    color: #bd271e;
}

.euiProgress--accent.euiProgress--native::-webkit-progress-value {
    background-color: #dd0a73;
}

.euiProgress--accent.euiProgress--native::-moz-progress-bar {
    background-color: #dd0a73;
}

.euiProgress--accent.euiProgress--indeterminate:before {
    background-color: #dd0a73;
}

.euiProgress__data--accent .euiProgress__valueText {
    color: #dd0a73;
}

.euiProgress--subdued.euiProgress--native::-webkit-progress-value {
    background-color: #6a717d;
}

.euiProgress--subdued.euiProgress--native::-moz-progress-bar {
    background-color: #6a717d;
}

.euiProgress--subdued.euiProgress--indeterminate:before {
    background-color: #6a717d;
}

.euiProgress__data--subdued .euiProgress__valueText {
    color: #6a717d;
}

.euiProgress--vis0.euiProgress--native::-webkit-progress-value {
    background-color: #54b399;
}

.euiProgress--vis0.euiProgress--native::-moz-progress-bar {
    background-color: #54b399;
}

.euiProgress--vis0.euiProgress--indeterminate:before {
    background-color: #54b399;
}

.euiProgress__data--vis0 .euiProgress__valueText {
    color: #3b7d6a;
}

.euiProgress--vis1.euiProgress--native::-webkit-progress-value {
    background-color: #6092c0;
}

.euiProgress--vis1.euiProgress--native::-moz-progress-bar {
    background-color: #6092c0;
}

.euiProgress--vis1.euiProgress--indeterminate:before {
    background-color: #6092c0;
}

.euiProgress__data--vis1 .euiProgress__valueText {
    color: #4e779c;
}

.euiProgress--vis2.euiProgress--native::-webkit-progress-value {
    background-color: #d36086;
}

.euiProgress--vis2.euiProgress--native::-moz-progress-bar {
    background-color: #d36086;
}

.euiProgress--vis2.euiProgress--indeterminate:before {
    background-color: #d36086;
}

.euiProgress__data--vis2 .euiProgress__valueText {
    color: #b55273;
}

.euiProgress--vis3.euiProgress--native::-webkit-progress-value {
    background-color: #9170b8;
}

.euiProgress--vis3.euiProgress--native::-moz-progress-bar {
    background-color: #9170b8;
}

.euiProgress--vis3.euiProgress--indeterminate:before {
    background-color: #9170b8;
}

.euiProgress__data--vis3 .euiProgress__valueText {
    color: #8365a6;
}

.euiProgress--vis4.euiProgress--native::-webkit-progress-value {
    background-color: #ca8eae;
}

.euiProgress--vis4.euiProgress--native::-moz-progress-bar {
    background-color: #ca8eae;
}

.euiProgress--vis4.euiProgress--indeterminate:before {
    background-color: #ca8eae;
}

.euiProgress__data--vis4 .euiProgress__valueText {
    color: #8d647a;
}

.euiProgress--vis5.euiProgress--native::-webkit-progress-value {
    background-color: #d6bf57;
}

.euiProgress--vis5.euiProgress--native::-moz-progress-bar {
    background-color: #d6bf57;
}

.euiProgress--vis5.euiProgress--indeterminate:before {
    background-color: #d6bf57;
}

.euiProgress__data--vis5 .euiProgress__valueText {
    color: #807234;
}

.euiProgress--vis6.euiProgress--native::-webkit-progress-value {
    background-color: #b9a888;
}

.euiProgress--vis6.euiProgress--native::-moz-progress-bar {
    background-color: #b9a888;
}

.euiProgress--vis6.euiProgress--indeterminate:before {
    background-color: #b9a888;
}

.euiProgress__data--vis6 .euiProgress__valueText {
    color: #7b705a;
}

.euiProgress--vis7.euiProgress--native::-webkit-progress-value {
    background-color: #da8b45;
}

.euiProgress--vis7.euiProgress--native::-moz-progress-bar {
    background-color: #da8b45;
}

.euiProgress--vis7.euiProgress--indeterminate:before {
    background-color: #da8b45;
}

.euiProgress__data--vis7 .euiProgress__valueText {
    color: #a16633;
}

.euiProgress--vis8.euiProgress--native::-webkit-progress-value {
    background-color: #aa6556;
}

.euiProgress--vis8.euiProgress--native::-moz-progress-bar {
    background-color: #aa6556;
}

.euiProgress--vis8.euiProgress--indeterminate:before {
    background-color: #aa6556;
}

.euiProgress__data--vis8 .euiProgress__valueText {
    color: #a26052;
}

.euiProgress--vis9.euiProgress--native::-webkit-progress-value {
    background-color: #e7664c;
}

.euiProgress--vis9.euiProgress--native::-moz-progress-bar {
    background-color: #e7664c;
}

.euiProgress--vis9.euiProgress--indeterminate:before {
    background-color: #e7664c;
}

.euiProgress__data--vis9 .euiProgress__valueText {
    color: #bc533e;
}

.euiProgress--customColor.euiProgress--native::-webkit-progress-value {
    background-color: currentColor;
}

.euiProgress--customColor.euiProgress--native::-moz-progress-bar {
    background-color: currentColor;
}

.euiProgress--customColor.euiProgress--indeterminate:before {
    background-color: currentColor;
}

@keyframes euiProgress {
    0% {
        transform: scaleX(1) translateX(-100%);
    }
    100% {
        transform: scaleX(1) translateX(100%);
    }
}

.euiProgress__data {
    display: flex;
    justify-content: space-between;
}

.euiProgress__label,
.euiProgress__valueText {
    color: #343741;
    font-weight: 400;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

.euiProgress__label {
    flex-grow: 1;
}

.euiProgress__label + .euiProgress__valueText {
    padding-left: 4px;
    flex-grow: 1;
    text-align: right;
    flex-shrink: 0;
}

.euiProgress__valueText {
    font-feature-settings: 'tnum' 1;
    margin-left: auto;
}

.euiProgress__data--l .euiProgress__label,
.euiProgress__data--l .euiProgress__valueText {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
}

.euiTreeView__wrapper .euiTreeView {
    margin: 0;
    list-style-type: none;
}

.euiTreeView .euiTreeView {
    padding-left: 24px;
}

.euiTreeView__node {
    max-height: 32px;
    line-height: 32px;
}

.euiTreeView__node--expanded {
    max-height: 100vh;
}

.euiTreeView__nodeInner {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    padding-left: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    border-radius: 4px;
    width: 100%;
    text-align-last: left;
}

.euiTreeView__nodeInner:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiTreeView__nodeInner:hover,
.euiTreeView__nodeInner:active,
.euiTreeView__nodeInner:focus {
    background-color: #fae9ea;
}

.euiTreeView__nodeInner .euiTreeView__iconPlaceholder {
    width: 32px;
}

.euiTreeView__nodeLabel {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

.euiTreeView__iconWrapper {
    margin-top: -2px;
    margin-right: 8px;
}

.euiTreeView__iconWrapper .euiToken {
    margin-top: 2px;
}

.euiTreeView--compressed .euiTreeView__node {
    max-height: 24px;
    line-height: 24px;
}

.euiTreeView--compressed .euiTreeView__node .euiTreeView__nodeInner {
    height: 24px;
}

.euiTreeView--compressed .euiTreeView__node .euiTreeView__iconWrapper {
    margin: 0 6px 0 0;
}

.euiTreeView--compressed .euiTreeView__node .euiTreeView__nodeLabel {
    margin-top: -1px;
}

.euiTreeView--compressed .euiTreeView__node .euiTreeView__iconPlaceholder {
    width: 24px;
}

.euiTreeView--compressed .euiTreeView__node--expanded {
    max-height: 100vh;
}

.euiTreeView--withArrows .euiTreeView__expansionArrow {
    margin-right: 4px;
}

.euiTreeView--withArrows.euiTreeView .euiTreeView__nodeInner--withArrows .euiTreeView__iconWrapper {
    margin-left: 0;
}

.euiTreeView--withArrows.euiTreeView .euiTreeView__iconWrapper {
    margin-left: 20px;
}

.euiTreeView--withArrows.euiTreeView--compressed
    .euiTreeView__nodeInner--withArrows
    .euiTreeView__iconWrapper {
    margin-left: 0;
}

.euiTreeView--withArrows.euiTreeView--compressed .euiTreeView__iconWrapper {
    margin-left: 16px;
}

.euiResizableButton {
    position: relative;
    flex-shrink: 0;
    z-index: 1000;
}

.euiResizableButton:before,
.euiResizableButton:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #343741;
    transition: width 150ms ease, height 150ms ease, transform 150ms ease,
        background-color 150ms ease;
}

.euiResizableButton.euiResizableButton--horizontal {
    cursor: col-resize;
    width: 16px;
    margin-left: -8px;
    margin-right: -8px;
}

.euiResizableButton.euiResizableButton--horizontal:before,
.euiResizableButton.euiResizableButton--horizontal:after {
    width: 1px;
    height: 12px;
}

.euiResizableButton.euiResizableButton--horizontal:before {
    transform: translate(-2px, -50%);
}

.euiResizableButton.euiResizableButton--horizontal:after {
    transform: translate(1px, -50%);
}

.euiResizableButton.euiResizableButton--vertical {
    cursor: row-resize;
    height: 16px;
    margin-top: -8px;
    margin-bottom: -8px;
}

.euiResizableButton.euiResizableButton--vertical:before,
.euiResizableButton.euiResizableButton--vertical:after {
    width: 12px;
    height: 1px;
}

.euiResizableButton.euiResizableButton--vertical:before {
    transform: translate(-50%, -2px);
}

.euiResizableButton.euiResizableButton--vertical:after {
    transform: translate(-50%, 1px);
}

.euiResizableButton:hover:not(:disabled):before,
.euiResizableButton:hover:not(:disabled):after {
    background-color: #98a2b3;
    transition-delay: 150ms;
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--horizontal:before,
.euiResizableButton:hover:not(:disabled).euiResizableButton--horizontal:after,
.euiResizableButton:focus:not(:disabled).euiResizableButton--horizontal:before,
.euiResizableButton:focus:not(:disabled).euiResizableButton--horizontal:after {
    height: 100%;
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--horizontal:before,
.euiResizableButton:focus:not(:disabled).euiResizableButton--horizontal:before {
    transform: translate(-1px, -50%);
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--horizontal:after,
.euiResizableButton:focus:not(:disabled).euiResizableButton--horizontal:after {
    transform: translate(0, -50%);
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--vertical:before,
.euiResizableButton:hover:not(:disabled).euiResizableButton--vertical:after,
.euiResizableButton:focus:not(:disabled).euiResizableButton--vertical:before,
.euiResizableButton:focus:not(:disabled).euiResizableButton--vertical:after {
    width: 100%;
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--vertical:before,
.euiResizableButton:focus:not(:disabled).euiResizableButton--vertical:before {
    transform: translate(-50%, -1px);
}

.euiResizableButton:hover:not(:disabled).euiResizableButton--vertical:after,
.euiResizableButton:focus:not(:disabled).euiResizableButton--vertical:after {
    transform: translate(-50%, 0);
}

.euiResizableButton:disabled {
    display: none !important;
}

/**
 * 1. The default position of the button should always be `middle`, so
 *    those position styles aren't restricted to a class
 * 2. When collpsed, the button itself is the full collapsed area and we use
 *    flex to align the icon within
 */
.euiResizableToggleButton {
    box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
    position: absolute;
    z-index: 1001;
    animation: none !important;
    transition-property: background, box-shadow;
}

.euiResizableToggleButton:focus {
    box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
}

.euiResizableToggleButton-isCollapsed {
    box-shadow: none;
    background: transparent;
    border-radius: 0;
}

.euiResizableToggleButton:not(:focus):not(:active):not(.euiResizableToggleButton-isVisible):not(.euiResizableToggleButton-isCollapsed) {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--after {
    right: 0;
    top: 50%;
    /* 1 */
    transform: translate(50%, -50%);
    /* 1 */
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--after.euiResizableToggleButton--top {
    top: 0;
    transform: translate(50%, 16px);
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--after.euiResizableToggleButton--bottom {
    top: auto;
    bottom: 0;
    transform: translate(50%, -16px);
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--before {
    left: 0;
    top: 50%;
    /* 1 */
    transform: translate(-50%, -50%);
    /* 1 */
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--before.euiResizableToggleButton--top {
    top: 0;
    transform: translate(-50%, 16px);
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton--before.euiResizableToggleButton--bottom {
    top: auto;
    bottom: 0;
    transform: translate(-50%, -16px);
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton-isCollapsed {
    top: 0 !important;
    bottom: 0 !important;
    transform: none !important;
    height: 100%;
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton-isCollapsed.euiResizableToggleButton--top {
    padding-top: 16px;
    align-items: flex-start;
    /* 2 */
}

.euiResizableToggleButton--horizontal.euiResizableToggleButton.euiResizableToggleButton-isCollapsed.euiResizableToggleButton--bottom {
    padding-bottom: 16px;
    align-items: flex-end;
    /* 2 */
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--after {
    top: 100%;
    left: 50%;
    /* 1 */
    transform: translate(-50%, -50%);
    /* 1 */
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--after.euiResizableToggleButton--left {
    left: 0;
    transform: translate(16px, -50%);
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--after.euiResizableToggleButton--right {
    left: auto;
    right: 0;
    transform: translate(-16px, -50%);
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--before {
    bottom: 100%;
    left: 50%;
    /* 1 */
    transform: translate(-50%, 50%);
    /* 1 */
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--before.euiResizableToggleButton--left {
    left: 0;
    transform: translate(16px, 50%);
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton--before.euiResizableToggleButton--right {
    left: auto;
    right: 0;
    transform: translate(-16px, 50%);
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton-isCollapsed {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100%;
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton-isCollapsed.euiResizableToggleButton--left {
    padding-left: 16px;
    justify-content: flex-start;
    /* 2 */
}

.euiResizableToggleButton--vertical.euiResizableToggleButton.euiResizableToggleButton-isCollapsed.euiResizableToggleButton--right {
    padding-right: 16px;
    justify-content: flex-end;
    /* 2 */
}

.euiResizableContainer {
    display: flex;
    width: 100%;
}

.euiResizableContainer--vertical {
    flex-direction: column;
}

.euiResizablePanel {
    position: relative;
}

.euiResizablePanel--paddingSmall {
    padding: 8px;
}

.euiResizablePanel--paddingMedium {
    padding: 16px;
}

.euiResizablePanel--paddingLarge {
    padding: 24px;
}

.euiResizablePanel__content {
    height: 100%;
}

.euiResizablePanel__content:not([class*='plain']) {
    border-width: 0;
}

.euiResizablePanel__content--scrollable {
    scrollbar-width: thin;
    overflow-y: auto;
}

.euiResizablePanel__content--scrollable::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiResizablePanel__content--scrollable::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiResizablePanel__content--scrollable::-webkit-scrollbar-corner,
.euiResizablePanel__content--scrollable::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiResizablePanel-isCollapsed {
    overflow: hidden;
}

.euiResizablePanel-isCollapsed .euiResizablePanel__content * {
    display: none;
}

.euiResizableContainer--horizontal .euiResizablePanel-isCollapsed {
    min-width: 0 !important;
}

.euiResizableContainer--horizontal .euiResizablePanel--collapsible.euiResizablePanel-isCollapsed {
    min-width: 24px !important;
}

.euiResizableContainer--vertical .euiResizablePanel-isCollapsed {
    min-height: 0 !important;
}

.euiResizableContainer--vertical .euiResizablePanel--collapsible.euiResizablePanel-isCollapsed {
    min-height: 24px !important;
}

.euiSideNav__mobileToggle {
    display: none;
    border-bottom: 1px solid #d3dae6;
    padding: 16px;
    width: 100%;
    text-align: left;
    border-radius: 0 !important;
}

.euiSideNav__mobileToggle .euiButtonContent {
    justify-content: space-between;
}

@media only screen and (max-width: 574px) {
    /**
     * 1. Animation doesn't work against height. Need max-height instead.
     *    We set a value larger than what is needed to fake "auto".
     */
    .euiSideNav-isOpenMobile .euiSideNav__content {
        visibility: visible;
        opacity: 1;
        margin: 24px;
        max-height: 5000px;
        /* 1 */
        transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    .euiSideNav__mobileToggle {
        display: block;
    }

    .euiSideNav__content {
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        margin: 0 24px;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    /**
     * 1. Animation doesn't work against height. Need max-height instead.
     *    We set a value larger than what is needed to fake "auto".
     */
    .euiSideNav-isOpenMobile .euiSideNav__content {
        visibility: visible;
        opacity: 1;
        margin: 24px;
        max-height: 5000px;
        /* 1 */
        transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    .euiSideNav__mobileToggle {
        display: block;
    }

    .euiSideNav__content {
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        max-height: 0;
        margin: 0 24px;
    }
}

/**
 * 1. Text-align defaults to center, so we have to override that.
 * 2. Color the text at the item level and then have the button inherit so overrides are easier
 * 3. Enable ellipsis overflow to work (https://css-tricks.com/flexbox-truncated-text/)
 * 4. Restrict the underline to the button __label so it doesn't affect other components that might live within
 */
.euiSideNavItemButton {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: left;
    /* 1 */
    display: block;
    width: 100%;
    padding: 2px 0;
    color: inherit;
    /* 2 */
}

.euiSideNavItemButton.euiSideNavItemButton--isClickable:not(:disabled):hover {
    cursor: pointer;
}

.euiSideNavItemButton.euiSideNavItemButton--isClickable:not(:disabled):hover
    .euiSideNavItemButton__label,
.euiSideNavItemButton.euiSideNavItemButton--isClickable:not(:disabled):focus
    .euiSideNavItemButton__label {
    text-decoration: underline;
    /* 4 */
}

.euiSideNavItemButton.euiSideNavItemButton-isSelected .euiSideNavItemButton__label {
    text-decoration: underline;
    /* 4 */
}

.euiSideNavItemButton:disabled {
    cursor: not-allowed;
    text-decoration: none;
    color: #afb0b3;
}

.euiSideNavItemButton__content {
    display: flex;
    align-items: center;
}

.euiSideNavItemButton__icon {
    margin-right: 8px;
}

.euiSideNavItemButton__labelContainer {
    min-width: 0;
    /* 3 */
}

.euiSideNavItemButton__label {
    flex-grow: 1;
}

.euiSideNavItemButton__label--truncated {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
}

.euiSideNavItem--root {
    /**
     * 1. Create padding around focus area without indenting the item itself.
     */
}

.euiSideNavItem--root.euiSideNavItem--rootIcon > .euiSideNavItem__items {
    margin-left: 24px;
}

.euiSideNavItem--root > .euiSideNavItemButton {
    margin-bottom: 8px;
    padding: 0;
    padding-left: 8px;
    /* 1 */
    padding-right: 8px;
    /* 1 */
    margin-left: -8px;
    /* 1 */
    width: calc(100% + 16px);
    /* 1 */
}

.euiSideNavItem--root > .euiSideNavItemButton .euiSideNavItemButton__label {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: inherit;
}

.euiSideNavItem--root > .euiSideNavItem__items {
    position: static;
    margin-left: 0;
}

.euiSideNavItem--root > .euiSideNavItem__items:after {
    display: none;
}

.euiSideNavItem--root + .euiSideNavItem--root {
    margin-top: 32px;
}

.euiSideNavItem--trunk {
    color: #1a1c21;
    /* 2 */
    /**
     * 1. Create padding around focus area without indenting the item itself.
     */
}

.euiSideNavItem--trunk > .euiSideNavItemButton {
    padding-left: 8px;
    /* 1 */
    padding-right: 8px;
    /* 1 */
    margin-left: -8px;
    /* 1 */
    width: calc(100% + 16px);
    /* 1 */
}

.euiSideNavItem--trunk > .euiSideNavItem__items {
    margin-left: 8px;
    width: 100%;
}

.euiSideNavItem--branch {
    /**
    * 1. Draw the vertical line to group an expanded item's child items together.
    */
    position: relative;
    color: #656b77;
    /* 2 */
    /**
     * 2. Absolutely position the horizontal tick connecting the item to the vertical line.
     */
}

.euiSideNavItem--branch::after {
    /* 1 */
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 1px;
    background: #d3dae6;
    left: 0;
}

.euiSideNavItem--branch:last-of-type::after {
    height: 12px;
}

.euiSideNavItem--branch > .euiSideNavItemButton {
    position: relative;
    /* 2 */
    padding-left: 8px;
    padding-right: 8px;
    /* 2 */
}

.euiSideNavItem--branch > .euiSideNavItemButton:after {
    position: absolute;
    /* 2 */
    content: '';
    top: 12px;
    left: 0;
    width: 4px;
    height: 1px;
    background: #d3dae6;
}

.euiSideNavItem--branch > .euiSideNavItem__items {
    margin-left: 16px;
}

.euiSideNavItem--emphasized {
    background: #eff2f6;
    color: #1a1c21;
    box-shadow: 100px 0 0 0 #eff2f6, -100px 0 0 0 #eff2f6;
}

.euiSideNavItem--emphasized > .euiSideNavItemButton {
    font-weight: 700;
}

.euiSideNavItem--emphasized .euiSideNavItem--emphasized {
    background: transparent;
    box-shadow: none;
}

.euiSpacer--xs {
    height: 4px;
}

.euiSpacer--s {
    height: 8px;
}

.euiSpacer--m {
    height: 16px;
}

.euiSpacer--l {
    height: 24px;
}

.euiSpacer--xl {
    height: 32px;
}

.euiSpacer--xxl {
    height: 40px;
}

.euiSearchBar__searchHolder {
    min-width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiSearchBar__filtersHolder {
        max-width: calc(100% - 16px);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .euiSearchBar__filtersHolder {
        max-width: calc(100% - 16px);
    }
}

@media only screen and (min-width: 1200px) {
    .euiSearchBar__filtersHolder {
        max-width: calc(100% - 16px);
    }
}

.euiSelectable {
    display: flex;
    flex-direction: column;
}

.euiSelectable-fullHeight {
    height: 100%;
}

.euiSelectableList:focus-within {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiSelectableList-fullHeight {
    flex-grow: 1;
}

.euiSelectableList-bordered {
    overflow: hidden;
    border: 1px solid #d3dae6;
    border-radius: 4px;
}

.euiSelectableList__list::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiSelectableList__list::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiSelectableList__list::-webkit-scrollbar-corner,
.euiSelectableList__list::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiSelectableList__groupLabel {
    color: #1a1c21;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eef2f7;
    padding: 4px 12px;
}

.euiSelectableListItem {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    display: inline-flex;
    width: 100%;
    text-align: left;
    color: #343741;
    cursor: pointer;
}

.euiSelectableListItem:not(:last-of-type) {
    border-bottom: 1px solid #eef2f7;
}

.euiSelectableListItem-isFocused:not([aria-disabled='true']) .euiSelectableListItem__text,
.euiSelectableListItem:hover:not([aria-disabled='true']) .euiSelectableListItem__text {
    text-decoration: underline;
}

.euiSelectableListItem[aria-disabled='true'] {
    color: #98a2b3;
    cursor: not-allowed;
}

.euiSelectableListItem__content {
    padding: 4px 12px;
    width: 100%;
    display: flex;
    align-items: center;
}

.euiSelectableListItem__icon,
.euiSelectableListItem__prepend {
    margin-right: 12px;
    flex-shrink: 0;
}

.euiSelectableListItem__append {
    margin-left: 12px;
    flex-shrink: 0;
}

.euiSelectableListItem__text {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    flex-grow: 1;
}

/**
  * 1. Prevent really long input from overflowing the container.
  */
.euiSelectableMessage {
    padding: 8px;
    text-align: center;
    word-wrap: break-word;
    /* 1 */
    display: flex;
    justify-content: center;
    align-items: center;
}

.euiSelectableMessage--bordered {
    overflow: hidden;
    border: 1px solid #d3dae6;
    border-radius: 4px;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout {
    background-color: transparent;
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout--group,
.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout input {
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) {
    color: rgba(255, 255, 255, 0.7);
}

.euiHeader--dark .euiSelectableTemplateSitewide .euiFormControlLayout:not(:focus-within) input {
    color: inherit;
    background-color: transparent;
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    input:-moz-placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.euiHeader--dark
    .euiSelectableTemplateSitewide
    .euiFormControlLayout:not(:focus-within)
    .euiFormControlLayout__append {
    background-color: transparent;
    color: inherit;
}

.euiSelectableTemplateSitewide__listItem .euiSelectableListItem__text {
    text-decoration: none !important;
}

.euiSelectableTemplateSitewide__listItem[class*='-isFocused']:not([aria-disabled='true'])
    .euiSelectableTemplateSitewide__listItemTitle,
.euiSelectableTemplateSitewide__listItem:hover:not([aria-disabled='true'])
    .euiSelectableTemplateSitewide__listItemTitle {
    text-decoration: underline;
}

.euiSelectableTemplateSitewide__optionMetasList {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    color: #6a717d;
}

.euiSelectableTemplateSitewide__optionMeta:not(:last-of-type)::after {
    content: '•';
    margin: 0 4px;
    color: #6a717d;
}

.euiSelectableTemplateSitewide__optionMeta--application {
    color: #4e779c;
    font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--deployment {
    color: #3b7d6a;
    font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--article {
    color: #8365a6;
    font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--case {
    color: #bc533e;
    font-weight: 500;
}

.euiSelectableTemplateSitewide__optionMeta--platform {
    color: #807234;
    font-weight: 500;
}

.euiStat .euiStat__title {
    color: #000;
}

.euiStat .euiStat__title--subdued {
    color: #6a717d;
}

.euiStat .euiStat__title--secondary {
    color: #017d73;
}

.euiStat .euiStat__title--danger {
    color: #bd271e;
}

.euiStat .euiStat__title--accent {
    color: #dd0a73;
}

.euiStat .euiStat__title-isLoading {
    animation: euiStatPulse 1.5s infinite ease-in-out;
}

.euiStat.euiStat--leftAligned {
    text-align: left;
    align-items: flex-start;
}

.euiStat.euiStat--centerAligned {
    text-align: center;
    align-items: center;
}

.euiStat.euiStat--rightAligned {
    text-align: right;
    align-items: flex-end;
}

@keyframes euiStatPulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}

.euiStepNumber .euiStepNumber__icon {
    vertical-align: middle;
    position: relative;
    top: -2px;
}

.euiStepNumber--small .euiStepNumber__icon {
    top: -1px;
}

.euiStepNumber--complete .euiStepNumber__icon {
    stroke: currentColor;
    stroke-width: 0.5px;
}

@media screen and (prefers-reduced-motion: no-preference) {
    .euiStepNumber--complete,
    .euiStepNumber--warning,
    .euiStepNumber--danger {
        animation: euiGrow 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
    }
}

.euiStepNumber--loading {
    background: transparent;
}

.euiStepNumber.euiStepNumber-isHollow .euiStepNumber__number {
    display: none;
}

.euiStepNumber--warning {
    color: #936400;
    background-color: #fef6e6;
}

.euiStepNumber--danger {
    color: #bd271e;
    background-color: #f8e9e9;
}

.euiStepNumber--disabled {
    color: #646a77;
    background-color: #f0f1f2;
}

.euiStepNumber--incomplete {
    color: #646a77;
    background-color: #f0f1f2;
}

/**
 * 1. Ensure that the step number vertically aligns with the title text
 */
.euiStep:not(:last-of-type) {
    background-image: linear-gradient(
        to right,
        transparent 0,
        transparent 15px,
        #d3dae6 15px,
        #d3dae6 17px,
        transparent 17px,
        transparent 100%
    );
    background-repeat: no-repeat;
    background-position: left 40px;
}

.euiStep--small:not(:last-of-type) {
    background-position: left -4px top 32px;
}

.euiStep--small .euiStep__content {
    padding-left: 28px;
    margin-left: 12px;
}

.euiStep__titleWrapper {
    display: flex;
}

.euiStep__circle {
    flex-shrink: 0;
    margin-right: 16px;
    vertical-align: top;
    /* 1 */
}

.euiStep__title {
    font-weight: 500;
}

.euiStep__content {
    padding: 16px 16px 32px;
    margin: 8px 0;
    padding-left: 32px;
    margin-left: 16px;
}

.euiSubSteps {
    padding: 16px;
    background-color: #f5f7fa;
    margin-bottom: 16px;
}

.euiSubSteps > *:last-child {
    margin-bottom: 0;
}

.euiText .euiSubSteps ol,
.euiSubSteps ol {
    list-style-type: lower-alpha;
}

/**
 * 1. Ensure the connecting lines stays behind the number
 * 2. Make each step the same width
 * 3. Make the content of each step align to the top, even if the steps are of varying heights,
 *    e.g. due to some of their titles wrapping to multiple lines
 */
.euiStepsHorizontal {
    display: flex;
    align-items: stretch;
    background: rgba(245, 247, 250, 0.5);
}

.euiStepHorizontal__item {
    flex-grow: 1;
    /* 2 */
    flex-basis: 0%;
    /* 2 */
}

.euiStepHorizontal__item:first-of-type > .euiStepHorizontal::before,
.euiStepHorizontal__item:last-of-type > .euiStepHorizontal::after {
    display: none;
}

.euiStepHorizontal {
    padding: 24px 16px 16px;
    display: flex;
    /* 3 */
    flex-direction: column;
    /* 3 */
    align-items: center;
    /* 3 */
    justify-content: flex-start;
    /* 3 */
    cursor: pointer;
    position: relative;
    width: 100%;
}

.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__title,
.euiStepHorizontal:hover:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__title {
    text-decoration: underline;
}

.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) {
    outline: none;
}

.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__number {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
}

.euiStepHorizontal.euiStepHorizontal-isDisabled {
    cursor: not-allowed;
}

.euiStepHorizontal::before,
.euiStepHorizontal::after {
    content: '';
    position: absolute;
    width: calc(50% - 16px);
    height: 1px;
    top: 40px;
    background-color: #d3dae6;
    z-index: 0;
    /* 1 */
}

.euiStepHorizontal::before {
    left: 0;
}

.euiStepHorizontal::after {
    right: 0;
}

.euiStepHorizontal__number {
    position: relative;
    /* 1 */
    z-index: 1;
    /* 1 */
    transition: all 150ms ease-in-out;
}

.euiStepHorizontal__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    margin-top: 8px;
    font-weight: 400;
    text-align: center;
}

.euiStepHorizontal-isDisabled .euiStepHorizontal__title {
    color: #69707d;
}

@media only screen and (max-width: 574px) {
    .euiStepHorizontal {
        padding-top: 16px;
    }

    .euiStepHorizontal::before,
    .euiStepHorizontal::after {
        top: 32px;
    }

    .euiStepHorizontal__title {
        display: none;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiStepHorizontal {
        padding-top: 16px;
    }

    .euiStepHorizontal::before,
    .euiStepHorizontal::after {
        top: 32px;
    }

    .euiStepHorizontal__title {
        display: none;
    }
}

.euiSuggestItem {
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
}

.euiSuggestItem.euiSuggestItem-isClickable {
    width: 100%;
    text-align: left;
}

.euiSuggestItem.euiSuggestItem-isClickable:hover,
.euiSuggestItem.euiSuggestItem-isClickable:focus {
    cursor: pointer;
    background-color: #f5f7fa;
}

.euiSuggestItem.euiSuggestItem-isClickable:hover .euiSuggestItem__type,
.euiSuggestItem.euiSuggestItem-isClickable:focus .euiSuggestItem__type {
    color: #343741;
}

.euiSuggestItem .euiSuggestItem__type--tint0 {
    background-color: #e0f1ed;
    color: #357160;
}

.euiSuggestItem .euiSuggestItem__type--tint1 {
    background-color: #e2ebf4;
    color: #466b8d;
}

.euiSuggestItem .euiSuggestItem__type--tint2 {
    background-color: #f7e2e9;
    color: #a34a68;
}

.euiSuggestItem .euiSuggestItem__type--tint3 {
    background-color: #ebe5f2;
    color: #765b96;
}

.euiSuggestItem .euiSuggestItem__type--tint4 {
    background-color: #f5ebf0;
    color: #865f74;
}

.euiSuggestItem .euiSuggestItem__type--tint5 {
    background-color: #f8f3e1;
    color: #7a6c31;
}

.euiSuggestItem .euiSuggestItem__type--tint6 {
    background-color: #f2efea;
    color: #756a56;
}

.euiSuggestItem .euiSuggestItem__type--tint7 {
    background-color: #f8eade;
    color: #915c2e;
}

.euiSuggestItem .euiSuggestItem__type--tint8 {
    background-color: #f0e3e1;
    color: #92564a;
}

.euiSuggestItem .euiSuggestItem__type--tint9 {
    background-color: #fbe3df;
    color: #aa4b38;
}

.euiSuggestItem .euiSuggestItem__type--tint10 {
    background-color: #e4e5e8;
    color: #5f6571;
}

.euiSuggestItem .euiSuggestItem__label,
.euiSuggestItem .euiSuggestItem__type,
.euiSuggestItem .euiSuggestItem__description {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
}

.euiSuggestItem .euiSuggestItem__type {
    position: relative;
    flex-shrink: 0;
    flex-basis: auto;
    width: 32px;
    height: 32px;
    text-align: center;
    overflow: hidden;
    padding: 4px;
    justify-content: center;
    align-items: center;
}

.euiSuggestItem .euiSuggestItem__label {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;
    color: #343741;
    display: block;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width20 {
    flex-basis: 20%;
    min-width: 20%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width21 {
    flex-basis: 21%;
    min-width: 21%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width22 {
    flex-basis: 22%;
    min-width: 22%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width23 {
    flex-basis: 23%;
    min-width: 23%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width24 {
    flex-basis: 24%;
    min-width: 24%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width25 {
    flex-basis: 25%;
    min-width: 25%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width26 {
    flex-basis: 26%;
    min-width: 26%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width27 {
    flex-basis: 27%;
    min-width: 27%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width28 {
    flex-basis: 28%;
    min-width: 28%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width29 {
    flex-basis: 29%;
    min-width: 29%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width30 {
    flex-basis: 30%;
    min-width: 30%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width31 {
    flex-basis: 31%;
    min-width: 31%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width32 {
    flex-basis: 32%;
    min-width: 32%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width33 {
    flex-basis: 33%;
    min-width: 33%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width34 {
    flex-basis: 34%;
    min-width: 34%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width35 {
    flex-basis: 35%;
    min-width: 35%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width36 {
    flex-basis: 36%;
    min-width: 36%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width37 {
    flex-basis: 37%;
    min-width: 37%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width38 {
    flex-basis: 38%;
    min-width: 38%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width39 {
    flex-basis: 39%;
    min-width: 39%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width40 {
    flex-basis: 40%;
    min-width: 40%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width41 {
    flex-basis: 41%;
    min-width: 41%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width42 {
    flex-basis: 42%;
    min-width: 42%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width43 {
    flex-basis: 43%;
    min-width: 43%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width44 {
    flex-basis: 44%;
    min-width: 44%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width45 {
    flex-basis: 45%;
    min-width: 45%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width46 {
    flex-basis: 46%;
    min-width: 46%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width47 {
    flex-basis: 47%;
    min-width: 47%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width48 {
    flex-basis: 48%;
    min-width: 48%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width49 {
    flex-basis: 49%;
    min-width: 49%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width50 {
    flex-basis: 50%;
    min-width: 50%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width51 {
    flex-basis: 51%;
    min-width: 51%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width52 {
    flex-basis: 52%;
    min-width: 52%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width53 {
    flex-basis: 53%;
    min-width: 53%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width54 {
    flex-basis: 54%;
    min-width: 54%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width55 {
    flex-basis: 55%;
    min-width: 55%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width56 {
    flex-basis: 56%;
    min-width: 56%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width57 {
    flex-basis: 57%;
    min-width: 57%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width58 {
    flex-basis: 58%;
    min-width: 58%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width59 {
    flex-basis: 59%;
    min-width: 59%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width60 {
    flex-basis: 60%;
    min-width: 60%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width61 {
    flex-basis: 61%;
    min-width: 61%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width62 {
    flex-basis: 62%;
    min-width: 62%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width63 {
    flex-basis: 63%;
    min-width: 63%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width64 {
    flex-basis: 64%;
    min-width: 64%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width65 {
    flex-basis: 65%;
    min-width: 65%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width66 {
    flex-basis: 66%;
    min-width: 66%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width67 {
    flex-basis: 67%;
    min-width: 67%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width68 {
    flex-basis: 68%;
    min-width: 68%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width69 {
    flex-basis: 69%;
    min-width: 69%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width70 {
    flex-basis: 70%;
    min-width: 70%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width71 {
    flex-basis: 71%;
    min-width: 71%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width72 {
    flex-basis: 72%;
    min-width: 72%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width73 {
    flex-basis: 73%;
    min-width: 73%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width74 {
    flex-basis: 74%;
    min-width: 74%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width75 {
    flex-basis: 75%;
    min-width: 75%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width76 {
    flex-basis: 76%;
    min-width: 76%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width77 {
    flex-basis: 77%;
    min-width: 77%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width78 {
    flex-basis: 78%;
    min-width: 78%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width79 {
    flex-basis: 79%;
    min-width: 79%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width80 {
    flex-basis: 80%;
    min-width: 80%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width81 {
    flex-basis: 81%;
    min-width: 81%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width82 {
    flex-basis: 82%;
    min-width: 82%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width83 {
    flex-basis: 83%;
    min-width: 83%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width84 {
    flex-basis: 84%;
    min-width: 84%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width85 {
    flex-basis: 85%;
    min-width: 85%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width86 {
    flex-basis: 86%;
    min-width: 86%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width87 {
    flex-basis: 87%;
    min-width: 87%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width88 {
    flex-basis: 88%;
    min-width: 88%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width89 {
    flex-basis: 89%;
    min-width: 89%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__label--width90 {
    flex-basis: 90%;
    min-width: 90%;
}

.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__labelDisplay--expand {
    flex-basis: auto;
    flex-shrink: 1;
}

.euiSuggestItem .euiSuggestItem__description {
    color: #69707d;
    flex-basis: auto;
    padding-top: 2px;
    display: block;
}

.euiSuggestItem .euiSuggestItem__description.euiSuggestItem__description--wrap {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    white-space: normal;
    line-height: 14px;
}

.euiSuggestItem .euiSuggestItem__description.euiSuggestItem__description--truncate {
    max-width: 100%;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important;
    line-height: 1.5;
}

.euiSuggestItem .euiSuggestItem__description:empty {
    flex-grow: 0;
    margin-left: 0;
}

.euiSuggestInput__statusIcon {
    background-color: transparent !important;
}

.euiText a:not([class]):focus {
    background-color: #fae9ea;
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiText img {
    display: block;
    width: 100%;
}

.euiText ul {
    list-style: disc;
}

.euiText ol {
    list-style: decimal;
}

.euiText blockquote {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
    letter-spacing: normal;
}

.euiText blockquote p:last-child {
    margin-bottom: 0;
}

.euiText blockquote:before,
.euiText blockquote:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 50%;
    right: 0;
    transform: translateX(-50%);
    background: #69707d;
}

.euiText blockquote:before {
    top: 0;
}

.euiText blockquote:after {
    bottom: 0;
}

.euiText h1 {
    color: #1a1c21;
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 3rem;
    font-weight: 300;
    letter-spacing: -0.03em;
}

.euiText h2 {
    color: #1a1c21;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 300;
    letter-spacing: -0.04em;
}

.euiText h3 {
    color: #1a1c21;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.025em;
}

.euiText h4,
.euiText dt {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.euiText h5 {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.euiText h6 {
    color: #1a1c21;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}

.euiText pre {
    white-space: pre-wrap;
    background: #f5f7fa;
    color: #343741;
}

.euiText pre,
.euiText pre code {
    display: block;
}

.euiText code {
    letter-spacing: normal;
    display: inline-block;
    font-weight: 400;
}

.euiText p,
.euiText ul,
.euiText ol,
.euiText dl,
.euiText blockquote,
.euiText img,
.euiText pre {
    margin-bottom: 1.5rem;
}

.euiText ul,
.euiText ol {
    margin-left: 1.5rem;
}

.euiText blockquote {
    padding: 1.5rem;
    font-size: 1.125rem;
}

.euiText h1,
.euiText h2,
.euiText h3,
.euiText h4,
.euiText h5,
.euiText h6 {
    margin-bottom: 0.5rem;
}

.euiText dd + dt {
    margin-top: 1rem;
}

.euiText * + h2,
.euiText * + h3,
.euiText * + h4,
.euiText * + h5,
.euiText * + h6 {
    margin-top: 2rem;
}

.euiText h1 {
    font-size: 2.25rem;
    line-height: 3rem;
}

.euiText h2 {
    font-size: 1.75rem;
    line-height: 2.5rem;
}

.euiText h3 {
    font-size: 1.25rem;
    line-height: 2rem;
}

.euiText h4,
.euiText dt,
.euiText .eui-definitionListReverse dd {
    font-size: 1rem;
    line-height: 1.5rem;
}

.euiText .eui-definitionListReverse dt {
    font-size: 0.75rem;
    color: #343741;
}

.euiText h5 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.euiText h6 {
    font-size: 0.75rem;
    line-height: 1rem;
}

.euiText small {
    font-size: 0.875rem;
}

.euiText pre {
    padding: 16px;
}

.euiText code {
    font-size: 0.9rem;
}

.euiText.euiText--constrainedWidth {
    max-width: 36em;
    min-width: 75%;
}

.euiText > :last-child,
.euiText .euiTextColor > :last-child {
    margin-bottom: 0 !important;
}

.euiText--small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.euiText--small p,
.euiText--small ul,
.euiText--small ol,
.euiText--small dl,
.euiText--small blockquote,
.euiText--small img,
.euiText--small pre {
    margin-bottom: 1.3125rem;
}

.euiText--small ul,
.euiText--small ol {
    margin-left: 1.3125rem;
}

.euiText--small blockquote {
    padding: 1.3125rem;
    font-size: 0.984375rem;
}

.euiText--small h1,
.euiText--small h2,
.euiText--small h3,
.euiText--small h4,
.euiText--small h5,
.euiText--small h6 {
    margin-bottom: 0.4375rem;
}

.euiText--small dd + dt {
    margin-top: 0.875rem;
}

.euiText--small * + h2,
.euiText--small * + h3,
.euiText--small * + h4,
.euiText--small * + h5,
.euiText--small * + h6 {
    margin-top: 1.75rem;
}

.euiText--small h1 {
    font-size: 1.96875rem;
    line-height: 2.625rem;
}

.euiText--small h2 {
    font-size: 1.53125rem;
    line-height: 2.1875rem;
}

.euiText--small h3 {
    font-size: 1.09375rem;
    line-height: 1.75rem;
}

.euiText--small h4,
.euiText--small dt,
.euiText--small .eui-definitionListReverse dd {
    font-size: 0.875rem;
    line-height: 1.3125rem;
}

.euiText--small .eui-definitionListReverse dt {
    font-size: 0.65625rem;
    color: #343741;
}

.euiText--small h5 {
    font-size: 0.765625rem;
    line-height: 0.875rem;
}

.euiText--small h6 {
    font-size: 0.65625rem;
    line-height: 0.875rem;
}

.euiText--small small {
    font-size: 0.765625rem;
}

.euiText--small pre {
    padding: 14px;
}

.euiText--small code {
    font-size: 0.7875rem;
}

.euiText--extraSmall {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.euiText--extraSmall p,
.euiText--extraSmall ul,
.euiText--extraSmall ol,
.euiText--extraSmall dl,
.euiText--extraSmall blockquote,
.euiText--extraSmall img,
.euiText--extraSmall pre {
    margin-bottom: 1.125rem;
}

.euiText--extraSmall ul,
.euiText--extraSmall ol {
    margin-left: 1.125rem;
}

.euiText--extraSmall blockquote {
    padding: 1.125rem;
    font-size: 0.84375rem;
}

.euiText--extraSmall h1,
.euiText--extraSmall h2,
.euiText--extraSmall h3,
.euiText--extraSmall h4,
.euiText--extraSmall h5,
.euiText--extraSmall h6 {
    margin-bottom: 0.375rem;
}

.euiText--extraSmall dd + dt {
    margin-top: 0.75rem;
}

.euiText--extraSmall * + h2,
.euiText--extraSmall * + h3,
.euiText--extraSmall * + h4,
.euiText--extraSmall * + h5,
.euiText--extraSmall * + h6 {
    margin-top: 1.5rem;
}

.euiText--extraSmall h1 {
    font-size: 1.6875rem;
    line-height: 2.25rem;
}

.euiText--extraSmall h2 {
    font-size: 1.3125rem;
    line-height: 1.875rem;
}

.euiText--extraSmall h3 {
    font-size: 0.9375rem;
    line-height: 1.5rem;
}

.euiText--extraSmall h4,
.euiText--extraSmall dt,
.euiText--extraSmall .eui-definitionListReverse dd {
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.euiText--extraSmall .eui-definitionListReverse dt {
    font-size: 0.5625rem;
    color: #343741;
}

.euiText--extraSmall h5 {
    font-size: 0.65625rem;
    line-height: 0.75rem;
}

.euiText--extraSmall h6 {
    font-size: 0.5625rem;
    line-height: 0.75rem;
}

.euiText--extraSmall small {
    font-size: 0.65625rem;
}

.euiText--extraSmall pre {
    padding: 12px;
}

.euiText--extraSmall code {
    font-size: 0.675rem;
}

.euiTextColor--default {
    color: #343741;
}

.euiTextColor--subdued {
    color: #6a717d;
}

.euiTextColor--secondary {
    color: #017d73;
}

.euiTextColor--accent {
    color: #dd0a73;
}

.euiTextColor--warning {
    color: #9b6900;
}

.euiTextColor--danger {
    color: #bd271e;
}

.euiTextColor--ghost {
    color: #717171;
    color: #fff !important;
}

.euiTextAlign--left {
    text-align: left;
}

.euiTextAlign--right {
    text-align: right;
}

.euiTextAlign--center {
    text-align: center;
}

.euiToast--success {
    border-top: 2px solid #017d73;
}

.euiToast--warning {
    border-top: 2px solid #c88800;
}

.euiToast--danger {
    border-top: 2px solid #bd271e;
}

/**
 * 1. Align icon with first line of title text if it wraps.
 * 2. Apply margin to all but last item in the flex.
 * 3. Account for close button.
 */
.euiToastHeader {
    padding-right: 24px;
    /* 3 */
    display: flex;
    align-items: baseline;
    /* 1 */
}

.euiToastHeader > * + * {
    margin-left: 8px;
    /* 2 */
}

/**
  * 1. Vertically center icon with first line of title.
  */
.euiToastHeader__icon {
    flex: 0 0 auto;
    fill: #1a1c21;
    transform: translateY(2px);
    /* 1 */
}

.euiToastHeader__title {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    font-weight: 300;
}

.euiToastHeader--withBody {
    margin-bottom: 8px;
}

/**
 * 1. Prevent long lines from overflowing.
 */
.euiToastBody {
    word-wrap: break-word;
    /* 1 */
}

.euiToken {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.euiToken svg {
    height: 100%;
    margin: auto;
}

.euiToken--circle {
    border-radius: 50%;
}

.euiToken--square {
    border-radius: 3px;
}

.euiToken--xsmall {
    width: 12px;
    height: 12px;
}

.euiToken--xsmall.euiToken--rectangle {
    padding: 0 4px;
}

.euiToken--small {
    width: 16px;
    height: 16px;
}

.euiToken--small.euiToken--rectangle {
    padding: 0 4px;
}

.euiToken--medium {
    width: 24px;
    height: 24px;
}

.euiToken--medium.euiToken--rectangle {
    padding: 0 8px;
}

.euiToken--large {
    width: 32px;
    height: 32px;
}

.euiToken--large.euiToken--rectangle {
    padding: 0 8px;
}

.euiToken--rectangle {
    box-sizing: content-box;
}

.euiToken--euiColorVis0 {
    color: #54b399;
}

.euiToken--euiColorVis0.euiToken--light {
    color: #387765;
    background-color: #eef7f5;
    box-shadow: inset 0 0 0 1px #cce8e0;
}

.euiToken--euiColorVis0.euiToken--dark {
    background-color: #6dccb1;
    color: #000;
}

.euiToken--euiColorVis1 {
    color: #6092c0;
}

.euiToken--euiColorVis1.euiToken--light {
    color: #4a7194;
    background-color: #eff4f9;
    box-shadow: inset 0 0 0 1px #cfdeec;
}

.euiToken--euiColorVis1.euiToken--dark {
    background-color: #79aad9;
    color: #000;
}

.euiToken--euiColorVis2 {
    color: #d36086;
}

.euiToken--euiColorVis2.euiToken--light {
    color: #ac4e6d;
    background-color: #fbeff3;
    box-shadow: inset 0 0 0 1px #f2cfdb;
}

.euiToken--euiColorVis2.euiToken--dark {
    background-color: #ee789d;
    color: #000;
}

.euiToken--euiColorVis3 {
    color: #9170b8;
}

.euiToken--euiColorVis3.euiToken--light {
    color: #7c609e;
    background-color: #f4f1f8;
    box-shadow: inset 0 0 0 1px #ded4ea;
}

.euiToken--euiColorVis3.euiToken--dark {
    background-color: #a987d1;
    color: #000;
}

.euiToken--euiColorVis4 {
    color: #ca8eae;
}

.euiToken--euiColorVis4.euiToken--light {
    color: #8d647a;
    background-color: #faf4f7;
    box-shadow: inset 0 0 0 1px #efdde7;
}

.euiToken--euiColorVis4.euiToken--dark {
    background-color: #e4a6c7;
    color: #000;
}

.euiToken--euiColorVis5 {
    color: #d6bf57;
}

.euiToken--euiColorVis5.euiToken--light {
    color: #807234;
    background-color: #fbf9ee;
    box-shadow: inset 0 0 0 1px #f3eccd;
}

.euiToken--euiColorVis5.euiToken--dark {
    background-color: #f1d86f;
    color: #000;
}

.euiToken--euiColorVis6 {
    color: #b9a888;
}

.euiToken--euiColorVis6.euiToken--light {
    color: #7b705a;
    background-color: #f8f6f3;
    box-shadow: inset 0 0 0 1px #eae5db;
}

.euiToken--euiColorVis6.euiToken--dark {
    background-color: #d2c0a0;
    color: #000;
}

.euiToken--euiColorVis7 {
    color: #da8b45;
}

.euiToken--euiColorVis7.euiToken--light {
    color: #996130;
    background-color: #fbf3ec;
    box-shadow: inset 0 0 0 1px #f4dcc7;
}

.euiToken--euiColorVis7.euiToken--dark {
    background-color: #f5a35c;
    color: #000;
}

.euiToken--euiColorVis8 {
    color: #aa6556;
}

.euiToken--euiColorVis8.euiToken--light {
    color: #9a5b4e;
    background-color: #f7f0ee;
    box-shadow: inset 0 0 0 1px #e6d1cc;
}

.euiToken--euiColorVis8.euiToken--dark {
    background-color: #c47c6c;
    color: #000;
}

.euiToken--euiColorVis9 {
    color: #e7664c;
}

.euiToken--euiColorVis9.euiToken--light {
    color: #b34f3b;
    background-color: #fdf0ed;
    box-shadow: inset 0 0 0 1px #f8d1c9;
}

.euiToken--euiColorVis9.euiToken--dark {
    background-color: #ff7e62;
    color: #000;
}

.euiToken--gray {
    color: #69707d;
}

.euiToken--gray.euiToken--light {
    color: #646a77;
    background-color: #f0f1f2;
    box-shadow: inset 0 0 0 1px #d2d4d8;
}

.euiToken--gray.euiToken--dark {
    background-color: #69707d;
    color: #fff;
}

/*
 * 1. Shift arrow 1px more than half its size to account for border radius
 */
.euiToolTip {
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
        0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #404040;
    color: #fff;
    z-index: 9000;
    max-width: 256px;
    overflow-wrap: break-word;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 12px;
    animation: euiToolTipTop 350ms ease-out 0s forwards;
    position: absolute;
    opacity: 0;
    /* 1 */
    /* 1 */
}

.euiToolTip .euiToolTip__arrow {
    content: '';
    position: absolute;
    transform-origin: center;
    border-radius: 2px;
    background-color: #404040;
    width: 12px;
    height: 12px;
    transform: translateY(-7px) rotateZ(45deg);
    /* 1 */
}

.euiToolTip.euiToolTip--right {
    animation-name: euiToolTipRight;
}

.euiToolTip.euiToolTip--right .euiToolTip__arrow {
    transform: translateX(-5px) rotateZ(45deg);
    /* 1 */
}

.euiToolTip.euiToolTip--bottom {
    animation-name: euiToolTipBottom;
}

.euiToolTip.euiToolTip--bottom .euiToolTip__arrow {
    transform: translateY(-5px) rotateZ(45deg);
    /* 1 */
}

.euiToolTip.euiToolTip--left {
    animation-name: euiToolTipLeft;
}

.euiToolTip.euiToolTip--left .euiToolTip__arrow {
    transform: translateX(-7px) rotateZ(45deg);
    /* 1 */
}

.euiToolTip .euiToolTip__title {
    font-weight: 700;
    border-bottom: solid 1px #595959;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.euiToolTipAnchor {
    display: inline-block;
}

.euiToolTipAnchor *[disabled] {
    pointer-events: none;
}

.euiToolTipAnchor.euiToolTipAnchor--displayBlock {
    display: block;
}

@keyframes euiToolTipTop {
    0% {
        opacity: 0;
        transform: translateY(-16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes euiToolTipBottom {
    0% {
        opacity: 0;
        transform: translateY(16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes euiToolTipLeft {
    0% {
        opacity: 0;
        transform: translateX(-16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes euiToolTipRight {
    0% {
        opacity: 0;
        transform: translateX(16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.euiTour--minWidth-default {
    min-width: 240px;
}

.euiTourHeader {
    border-bottom: none;
    margin-bottom: 8px !important;
}

.euiTourHeader .euiTourHeader__title {
    margin-top: 0;
    text-transform: capitalize;
}

.euiTourHeader__subtitle {
    color: #69707d;
}

.euiTourFooter {
    background-color: #f5f7fa;
    margin-top: 24px !important;
}

.euiTour .euiTour__beacon {
    pointer-events: none;
    position: absolute;
    opacity: 0;
    transition: opacity 0s 350ms;
}

.euiTour .euiPopover__panelArrow.euiPopover__panelArrow--right .euiTour__beacon {
    opacity: 1;
    top: 6px;
    left: -24px;
}

.euiTour .euiPopover__panelArrow.euiPopover__panelArrow--left .euiTour__beacon {
    opacity: 1;
    top: 6px;
    left: 12px;
}

.euiTour .euiPopover__panelArrow.euiPopover__panelArrow--top:after {
    border-top-color: #f5f7fa;
}

.euiTour .euiPopover__panelArrow.euiPopover__panelArrow--top .euiTour__beacon {
    opacity: 1;
    top: 12px;
    left: 6px;
}

.euiTour .euiPopover__panelArrow.euiPopover__panelArrow--bottom .euiTour__beacon {
    opacity: 1;
    top: -24px;
    left: 6px;
}

.euiTourStepIndicator {
    display: inline-block;
}

.euiText {
    color: #343741;
    font-weight: 400;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5;
    color: inherit;
    clear: both;
    line-height: 1.5rem;
}

.euiTab.euiTab-isDisabled {
    color: #afb0b3;
}

.euiTab.euiTab-isDisabled:hover {
    color: #afb0b3;
    cursor: not-allowed;
    text-decoration: none;
}

.euiTab.euiTab-isDisabled::after {
    background-color: #afb0b3;
}

.euiTabs--small .euiTab {
    font-size: 14px;
    font-size: 0.875rem;
    padding: 8px;
}

.euiTabs--condensed .euiTab {
    font-weight: 600;
    padding: 8px 4px;
}

.euiTabs--condensed .euiTab:focus {
    background-color: #fae9ea;
}

.euiTabs--condensed .euiTab:focus::before {
    display: none;
}

.euiTabs--condensed .euiTab-isSelected {
    text-decoration: none;
}

.euiTabs--condensed .euiTab + .euiTab {
    margin-left: 16px;
}

.euiTabs--small.euiTabs--condensed .euiTab {
    padding-top: 6px;
    padding-bottom: 6px;
}

.euiTabs--large.euiTabs--condensed .euiTab + .euiTab {
    margin-left: 24px;
}

.euiTabs--expand .euiTab {
    flex-basis: 0%;
    flex-grow: 1;
}

.euiTab__content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(0);
    transition: transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
    white-space: nowrap;
}

@keyframes euiTab {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.euiTextDiff del {
    color: #bd271e;
}

.euiTextDiff ins {
    color: #017d73;
}

.euiTitle + .euiTitle {
    margin-top: 24px;
}

.euiTitle--uppercase {
    text-transform: uppercase;
}

.euiTitle--xxxsmall {
    color: #1a1c21;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.euiTitle--xxsmall {
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
}

.euiTitle--xsmall {
    color: #1a1c21;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.euiTitle--small {
    color: #1a1c21;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.025em;
}

.euiTitle--medium {
    color: #1a1c21;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 300;
    letter-spacing: -0.04em;
}

.euiTitle--large {
    color: #1a1c21;
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 3rem;
    font-weight: 300;
    letter-spacing: -0.03em;
}

/**
 * 1. Allow list to expand as items are added, but cap it at the screen height.
 * 2. Allow some padding for shadow
 */
.euiGlobalToastList {
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: fixed;
    z-index: 9000;
    bottom: 0;
    width: 400px;
    /* 2 */
    max-height: 100vh;
    /* 1 */
    overflow-y: auto;
    scrollbar-width: none;
}

.euiGlobalToastList::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiGlobalToastList::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiGlobalToastList::-webkit-scrollbar-corner,
.euiGlobalToastList::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiGlobalToastList::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.euiGlobalToastList:not(:empty) {
    padding: 16px;
}

.euiGlobalToastList--right:not(:empty) {
    right: 0;
    padding-left: 64px;
    /* 2 */
}

.euiGlobalToastList--left:not(:empty) {
    left: 0;
    padding-right: 64px;
    /* 2 */
}

.euiGlobalToastListItem {
    margin-bottom: 16px;
    animation: 250ms euiShowToast cubic-bezier(0.694, 0.0482, 0.335, 1);
    opacity: 1;
    /**
     * 1. justify-content: flex-end interferes with overflowing content, so we'll use this to push
     *    items to the bottom instead.
     */
}

.euiGlobalToastListItem:first-child {
    margin-top: auto;
    /* 1 */
}

.euiGlobalToastListItem:last-child {
    margin-bottom: 0;
}

.euiGlobalToastListItem.euiGlobalToastListItem-isDismissed {
    transition: opacity 250ms;
    opacity: 0;
}

@keyframes euiShowToast {
    from {
        transform: translateY(24px) scale(0.9);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@media only screen and (max-width: 574px) {
    /**
     * 1. Mobile we make these 100%. Matching change happens on the item as well.
     */
    .euiGlobalToastList:not(:empty) {
        left: 0;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        /* 1 */
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    /**
     * 1. Mobile we make these 100%. Matching change happens on the item as well.
     */
    .euiGlobalToastList:not(:empty) {
        left: 0;
        padding-left: 16px;
        padding-right: 16px;
        width: 100%;
        /* 1 */
    }
}

.euiToast {
    border: 1px solid #d3dae6;
    box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
        0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
        0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
    border-color: #c6cad1;
    border-top-color: #e3e4e8;
    border-bottom-color: #aaafba;
    position: relative;
    padding: 16px;
    background-color: #fff;
    width: 100%;
}

.euiToast:hover .euiToast__closeButton,
.euiToast:focus .euiToast__closeButton {
    opacity: 1;
}

/**
  * 1. Fit button to icon.
  */
.euiToast__closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    line-height: 0;
    /* 1 */
    appearance: none;
    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiToast__closeButton svg {
    fill: #8d8e90;
}

.euiToast__closeButton:hover svg {
    fill: #1a1c21;
}

.euiToast__closeButton:focus {
    background-color: #fae9ea;
    opacity: 1;
}

/**
  * Overflow scrolling with shadows
  */
.eui-yScrollWithShadows {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
}

.eui-xScrollWithShadows {
    scrollbar-width: thin;
    overflow-x: auto;
    mask-image: linear-gradient(
        to right,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
}

.euiYScrollWithShadows {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
}

.euiYScrollWithShadows::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiCard--isSelectable--danger.euiCard-isSelected {
    border-color: #bd271e;
}

.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100%;
}

.euiButtonEmpty--danger {
    color: #bd271e;
}

.euiTabs {
    scrollbar-width: thin;
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
}

.euiTabs::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}

.euiTabs::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    border: 6px solid transparent;
    background-clip: content-box;
}

.euiTabs::-webkit-scrollbar-corner,
.euiTabs::-webkit-scrollbar-track {
    background-color: transparent;
}

.euiTabs::-webkit-scrollbar {
    height: 3px;
}

.euiTabs:not(.euiTabs--condensed)::before {
    background-color: #d3dae6;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
}

.euiTab {
    font-size: 1rem;
    color: #343741;
    background-color: transparent;
    cursor: pointer;
    line-height: 1.5;
    padding: 12px 16px;
    position: relative;
    transition: color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        background-color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiTab:hover:not(.euiTab-isSelected) {
    text-decoration: underline;
}

.euiTab:focus {
    background-color: #fae9ea;
    text-decoration: underline;
    outline-offset: -3px;
}

.euiTab:focus::before {
    background-color: #d3dae6;
    bottom: 0;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
}

.euiTable {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    font-feature-settings: 'calt' 1, 'kern' 1, 'liga' 1, 'tnum' 1;
    width: 100%;
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    background-color: #fff;
}

.euiTable.euiTable--auto {
    table-layout: auto;
}

.euiTableCaption {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiTable--compressed .euiTableCellContent {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 1.5;
        padding: 4px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .euiTable--compressed .euiTableCellContent {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 1.5;
        padding: 4px;
    }
}

@media only screen and (min-width: 1200px) {
    .euiTable--compressed .euiTableCellContent {
        font-size: 12px;
        font-size: 0.75rem;
        line-height: 1.5;
        padding: 4px;
    }
}

.euiTableFooterCell,
.euiTableHeaderCell {
    vertical-align: middle;
    border-top: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
    font-weight: inherit;
    text-align: inherit;
    color: #1a1c21;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    font-weight: 500;
    border-top: none;
}

.euiTableFooterCell .euiTableHeaderButton,
.euiTableHeaderCell .euiTableHeaderButton {
    text-align: left;
    font-weight: 500;
}

.euiTableFooterCell .euiTableCellContent__text,
.euiTableHeaderCell .euiTableCellContent__text {
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: 600;
}

.euiTableHeaderButton {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5;
    color: inherit;
    width: 100%;
}

.euiTableSortIcon {
    margin-left: 4px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
}

.euiTableHeaderButton-isSorted .euiTableSortIcon {
    fill: #1a1c21;
}

.euiTableHeaderCellCheckbox {
    vertical-align: middle;
    border-top: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
    font-weight: inherit;
    text-align: inherit;
    width: 32px;
    vertical-align: middle;
    border-top: none;
}

.euiTableRow:hover {
    background-color: #fafbfd;
}

.euiTableRow.euiTableRow-isExpandedRow {
    background-color: #fafbfd;
}

.euiTableRow.euiTableRow-isExpandedRow.euiTableRow-isSelectable .euiTableCellContent {
    padding-left: 40px;
}

.euiTableRow.euiTableRow-isSelected {
    background-color: #eef5fa;
}

.euiTableRow.euiTableRow-isSelected + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
    background-color: #eef5fa;
}

.euiTableRow.euiTableRow-isSelected:hover,
.euiTableRow.euiTableRow-isSelected:hover
    + .euiTableRow.euiTableRow-isExpandedRow
    .euiTableRowCell {
    background-color: #e6f0f8;
}

.euiTableRowCell {
    vertical-align: middle;
    border-top: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
    font-weight: inherit;
    text-align: inherit;
    color: #343741;
}

.euiTableRowCell.euiTableRowCell--isMobileHeader {
    display: none;
}

.euiTableRowCellCheckbox {
    vertical-align: middle;
    border-top: 1px solid #d3dae6;
    border-bottom: 1px solid #d3dae6;
    font-weight: inherit;
    text-align: inherit;
    width: 32px;
    vertical-align: middle;
}

.euiTableFooterCell {
    background-color: #f5f7fa;
    border-bottom: none;
}

/**
 * 1. Vertically align all children.
 * 2. The padding on this div allows the ellipsis to show if the content is truncated. If
 *    the padding was on the cell, the ellipsis would be cropped.
 * 3. Support wrapping.
 * 4. Prevent very long single words (e.g. the name of a field in a document) from overflowing
 *    the cell.
 */
.euiTableCellContent {
    overflow: hidden;
    /* 4 */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    /* 1 */
    padding: 8px;
    /* 2 */
}

.euiTableCellContent__text {
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    word-break: break-word;
    /* 4 */
    min-width: 0;
    text-overflow: ellipsis;
}

.euiTableCellContent--alignRight {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right;
}

.euiTableCellContent--alignCenter {
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
}

.euiTableHeaderCell,
.euiTableFooterCell,
.euiTableCellContent--truncateText {
    white-space: nowrap;
    /* 3 */
}

.euiTableHeaderCell .euiTableCellContent__text,
.euiTableFooterCell .euiTableCellContent__text,
.euiTableCellContent--truncateText .euiTableCellContent__text {
    overflow: hidden;
}

.euiTableCellContent--overflowingContent {
    overflow: visible;
    white-space: normal;
    word-break: break-all;
    word-break: break-word;
}

.euiTableCellContent--showOnHover > *:not(:first-child) {
    margin-left: 8px;
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    opacity: 0.7;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        -webkit-filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
        -webkit-filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide,
.euiTableRow-hasActions
    .euiTableCellContent--showOnHover
    .expandedItemActions__completelyHide:disabled,
.euiTableRow-hasActions
    .euiTableCellContent--showOnHover
    .expandedItemActions__completelyHide:disabled:hover,
.euiTableRow-hasActions
    .euiTableCellContent--showOnHover
    .expandedItemActions__completelyHide:disabled:focus,
.euiTableRow:hover
    .euiTableRow-hasActions
    .euiTableCellContent--showOnHover
    .expandedItemActions__completelyHide:disabled {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 0;
}

.euiTableRow-hasActions:hover
    .euiTableCellContent--showOnHover
    .euiTableCellContent__hoverItem:not(:disabled),
.euiTableRow-hasActions:hover
    .euiTableCellContent--showOnHover
    .euiTableCellContent__hoverItem:not(:disabled):hover,
.euiTableRow-hasActions:hover
    .euiTableCellContent--showOnHover
    .euiTableCellContent__hoverItem:not(:disabled):focus {
    opacity: 1;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

.euiTableRow-isExpandedRow .euiTableCellContent {
    overflow: hidden;
    -webkit-animation: 250ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards growExpandedRow;
    animation: 250ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards growExpandedRow;
}

.euiTableRowCell__mobileHeader {
    display: none;
}

@media only screen and (max-width: 574px) {
    .euiTableRowCell--hideForMobile {
        display: none !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiTableRowCell--hideForMobile {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .euiTableRowCell--hideForDesktop {
        display: none !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .euiTableRowCell--hideForDesktop {
        display: none !important;
    }
}

@media only screen and (min-width: 1200px) {
    .euiTableRowCell--hideForDesktop {
        display: none !important;
    }
}

@media only screen and (max-width: 574px) {
    .euiTable.euiTable--responsive thead {
        display: none;
    }

    .euiTable.euiTable--responsive tfoot {
        display: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
        max-width: 100%;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important;
        font-size: 11px;
        font-size: 0.6875rem;
        display: block;
        color: #69707d;
        padding: 8px;
        padding-bottom: 0;
        margin-bottom: -8px;
        min-height: 24px;
    }

    .euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
        min-height: 0;
    }

    .euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5;
    }

    .euiTable.euiTable--responsive .euiTableRowCell--isMobileFullWidth {
        width: 100%;
    }

    .euiTable.euiTable--responsive
        .euiTableRowCell--isMobileFullWidth
        .euiTableCellContent--alignCenter {
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
    }

    .euiTable.euiTable--responsive .euiTableRow {
        background-color: #fff;
        border: 1px solid #d3dae6;
        border-radius: 4px;
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
        -webkit-flex-grow: 0;
        flex-grow: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--noBorder {
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasShadow {
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        border: 1px solid #d3dae6;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
        display: block;
        width: 100%;
        text-align: left;
        transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover,
    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
        box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        cursor: pointer;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusNone {
        border-radius: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusMedium {
        border-radius: 4px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--transparent {
        background-color: transparent;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--plain {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--subdued {
        background-color: #fafbfd;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--accent {
        background-color: #fce7f1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--primary {
        background-color: #e6f0f8;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--success {
        background-color: #e6f2f1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--warning {
        background-color: #fef6e6;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--danger {
        background-color: #f8e9e9;
    }

    .euiTable.euiTable--responsive .euiTableRow {
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        background-color: #fff;
        border-radius: 4px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 8px;
        margin-bottom: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow:hover {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable,
    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
        background-image: linear-gradient(
            to right,
            rgba(152, 162, 179, 0.1) 0,
            rgba(152, 162, 179, 0.1) 1px,
            transparent 1px,
            transparent 100%
        );
        background-size: 40px 100%;
        background-position-x: right;
        background-repeat: no-repeat;
        padding-right: 40px;
        position: relative;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions {
        min-width: 0;
        width: 24px;
        position: absolute;
        top: 16px;
        right: 8px;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander::before,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions::before {
        display: none;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander
        .euiTableCellContent,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions
        .euiTableCellContent {
        -webkit-flex-direction: column;
        flex-direction: column;
        padding: 0;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander
        .euiTableCellContent
        .euiLink,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions
        .euiTableCellContent
        .euiLink {
        padding: 4px;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable
        .euiTableRowCell--isExpander {
        top: auto;
        bottom: 16px;
        right: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
        padding-left: 36px;
        position: relative;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
        position: absolute;
        left: 4px;
        top: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
        background-image: linear-gradient(
            to right,
            rgba(152, 162, 179, 0.1) 0,
            rgba(152, 162, 179, 0.1) 1px,
            transparent 1px,
            transparent 100%
        );
        background-size: 40px 100%;
        background-position-x: right;
        background-repeat: no-repeat;
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        margin-top: -16px;
        position: relative;
        z-index: 2;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-left: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
        width: calc(100% - 40px);
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell::before {
        display: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCell {
        display: block;
        /* IE requires block to grow and wrap. */
        min-width: 50%;
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCellCheckbox {
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
        margin-left: 0;
    }

    .euiTable.euiTable--responsive
        .euiTableRow-hasActions
        .euiTableCellContent--showOnHover
        .expandedItemActions__completelyHide {
        display: none;
    }

    .euiTable.euiTable--responsive
        .euiTableRow-hasActions
        .euiTableCellContent--showOnHover
        .euiTableCellContent__hoverItem {
        opacity: 1;
        -webkit-filter: none;
        filter: none;
        margin-left: 0;
        margin-bottom: 8px;
    }

    .euiTable.euiTable--responsive .euiTableCellContent--alignRight {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }

    .euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiTable.euiTable--responsive thead {
        display: none;
    }

    .euiTable.euiTable--responsive tfoot {
        display: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
        max-width: 100%;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        word-wrap: normal !important;
        font-size: 11px;
        font-size: 0.6875rem;
        display: block;
        color: #69707d;
        padding: 8px;
        padding-bottom: 0;
        margin-bottom: -8px;
        min-height: 24px;
    }

    .euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
        min-height: 0;
    }

    .euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5;
    }

    .euiTable.euiTable--responsive .euiTableRowCell--isMobileFullWidth {
        width: 100%;
    }

    .euiTable.euiTable--responsive
        .euiTableRowCell--isMobileFullWidth
        .euiTableCellContent--alignCenter {
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
    }

    .euiTable.euiTable--responsive .euiTableRow {
        background-color: #fff;
        border: 1px solid #d3dae6;
        border-radius: 4px;
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
        -webkit-flex-grow: 0;
        flex-grow: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--noBorder {
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--hasShadow {
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        border: 1px solid #d3dae6;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
        display: block;
        width: 100%;
        text-align: left;
        transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover,
    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
        box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        cursor: pointer;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusNone {
        border-radius: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--borderRadiusMedium {
        border-radius: 4px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--transparent {
        background-color: transparent;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--plain {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--subdued {
        background-color: #fafbfd;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--accent {
        background-color: #fce7f1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--success {
        background-color: #e6f2f1;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--warning {
        background-color: #fef6e6;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow--danger {
        background-color: #f8e9e9;
    }

    .euiTable.euiTable--responsive .euiTableRow {
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        background-color: #fff;
        border-radius: 4px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 8px;
        margin-bottom: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow:hover {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable,
    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
        background-image: linear-gradient(
            to right,
            rgba(152, 162, 179, 0.1) 0,
            rgba(152, 162, 179, 0.1) 1px,
            transparent 1px,
            transparent 100%
        );
        background-size: 40px 100%;
        background-position-x: right;
        background-repeat: no-repeat;
        padding-right: 40px;
        position: relative;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions {
        min-width: 0;
        width: 24px;
        position: absolute;
        top: 16px;
        right: 8px;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander::before,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions::before {
        display: none;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander
        .euiTableCellContent,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions
        .euiTableCellContent {
        -webkit-flex-direction: column;
        flex-direction: column;
        padding: 0;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isExpandable
        .euiTableRowCell--isExpander
        .euiTableCellContent
        .euiLink,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions
        .euiTableRowCell--hasActions
        .euiTableCellContent
        .euiLink {
        padding: 4px;
    }

    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable
        .euiTableRowCell--isExpander {
        top: auto;
        bottom: 16px;
        right: 0;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
        padding-left: 36px;
        position: relative;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
        position: absolute;
        left: 4px;
        top: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected,
    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isSelected
        + .euiTableRow.euiTableRow-isExpandedRow,
    .euiTable.euiTable--responsive
        .euiTableRow.euiTableRow-isSelected:hover
        + .euiTableRow.euiTableRow-isExpandedRow
        .euiTableRowCell {
        background-color: #eef5fa;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
        background-image: linear-gradient(
            to right,
            rgba(152, 162, 179, 0.1) 0,
            rgba(152, 162, 179, 0.1) 1px,
            transparent 1px,
            transparent 100%
        );
        background-size: 40px 100%;
        background-position-x: right;
        background-repeat: no-repeat;
        box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
        margin-top: -16px;
        position: relative;
        z-index: 2;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding-left: 8px;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
        background-color: #fff;
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
        width: calc(100% - 40px);
    }

    .euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell::before {
        display: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCell {
        display: block;
        /* IE requires block to grow and wrap. */
        min-width: 50%;
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRowCellCheckbox {
        border: none;
    }

    .euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
        margin-left: 0;
    }

    .euiTable.euiTable--responsive
        .euiTableRow-hasActions
        .euiTableCellContent--showOnHover
        .expandedItemActions__completelyHide {
        display: none;
    }

    .euiTable.euiTable--responsive
        .euiTableRow-hasActions
        .euiTableCellContent--showOnHover
        .euiTableCellContent__hoverItem {
        opacity: 1;
        -webkit-filter: none;
        filter: none;
        margin-left: 0;
        margin-bottom: 8px;
    }

    .euiTable.euiTable--responsive .euiTableCellContent--alignRight {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }

    .euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
    }
}

.euiTableHeaderMobile,
.euiTableHeaderCell--hideForDesktop {
    display: none;
}

@media only screen and (max-width: 574px) {
    .euiTableHeaderMobile {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        padding: 8px 0;
    }

    .euiTableSortMobile {
        display: block;
    }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
    .euiTableHeaderMobile {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        padding: 8px 0;
    }

    .euiTableSortMobile {
        display: block;
    }
}
