@keyframes connect-focusRingAnimate {
    0% {
        box-shadow: 0 0 0 6px rgba(207, 31, 47, 0);
    }
    100% {
        box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
    }
}

@keyframes connect-focusRingAnimateLarge {
    0% {
        box-shadow: 0 0 0 10px rgba(207, 31, 47, 0);
    }
    100% {
        box-shadow: 0 0 0 4px rgba(207, 31, 47, 0.3);
    }
}

.connect a {
    text-decoration: none;
    color: #cf1f2f;
}

.connect .euiAccordion__button:focus .euiAccordion__iconWrapper {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards
        connect-focusRingAnimate !important;
    color: #cf1f2f;
}

.connect .euiAccordion__iconButton:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards
        connect-focusRingAnimate !important;
    color: #cf1f2f;
}

.connect .euiBasicTable-loading tbody:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #cf1f2f;
    animation: euiBasicTableLoading 1000ms linear;
    animation-iteration-count: infinite;
}

.connect .euiButton.euiButton-isDisabled .euiButtonContent__spinner {
    border-color: #cf1f2f currentColor currentColor currentColor;
}

.connect .euiButton--primary {
    color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiButton--primary.euiButton--fill {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
    color: #fff;
}

.connect .euiButton--primary.euiButton--fill:not([class*='isDisabled']):hover,
.connect .euiButton--primary.euiButton--fill:not([class*='isDisabled']):focus,
.connect .euiButton--primary.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #b91c2a;
    border-color: #b91c2a;
}

.connect .euiButtonEmpty:disabled .euiButtonContent__spinner {
    border-color: #cf1f2f currentColor currentColor currentColor;
}

.connect .euiButtonEmpty--primary {
    color: #cf1f2f;
}

.connect .euiButtonEmpty--primary:focus {
    background-color: rgba(207, 31, 47, 0.1);
}

.connect .euiButtonIcon.euiButtonIcon-isDisabled .euiButtonContent__spinner {
    border-color: #cf1f2f currentColor currentColor currentColor;
}

.connect .euiButtonIcon--primary {
    color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiButtonIcon--primary.euiButtonIcon--fill {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
    color: #fff;
}

.connect .euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.connect .euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.connect .euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #b91c2a;
    border-color: #b91c2a;
}

.connect .euiButtonGroupButton.euiButtonGroupButton-isDisabled .euiButtonContent__spinner {
    border-color: #cf1f2f currentColor currentColor currentColor;
}

.connect .euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']) {
    color: #cf1f2f;
}

.connect
    .euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
    color: #fff;
}

.connect
    .euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.connect
    .euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.connect
    .euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #b91c2a;
    border-color: #b91c2a;
}

.connect .euiButtonGroup--compressed .euiButtonGroupButton:not([class*='isDisabled']):focus,
.connect .euiButtonGroup--compressed .euiButtonGroupButton:not([class*='isDisabled']):focus-within {
    outline: 2px solid rgba(207, 31, 47, 0.3);
}

.connect .euiCallOut--primary {
    border-color: #cf1f2f;
    background-color: #fae9ea;
}

.connect .euiCallOut--primary .euiCallOutHeader__icon {
    fill: #cf1f2f;
}

.connect .euiCallOut--primary .euiCallOutHeader__title {
    color: #cf1f2f;
}

.connect .euiCard--isSelectable--primary.euiCard-isSelected {
    border-color: #cf1f2f;
}

.connect .euiCheckableCard:not(.euiCheckableCard-isDisabled).euiCheckableCard-isChecked {
    border-color: #cf1f2f;
}

.connect .euiCheckableCard-isChecked .euiCheckableCard__control {
    background-color: #fae9ea;
}

.connect .euiCodeEditorKeyboardHint:focus {
    opacity: 1;
    border: 2px solid #cf1f2f;
    z-index: 1000;
}

.connect
    .euiCollapsibleNavGroup--dark
    .euiCollapsibleNavGroup__heading:focus
    .euiAccordion__iconWrapper {
    color: #d53f4c;
    animation-name: connect-euiCollapsibleNavGroupDarkFocusRingAnimate !important;
}

@keyframes connect-euiCollapsibleNavGroupDarkFocusRingAnimate {
    0% {
        box-shadow: 0 0 0 6px rgba(207, 31, 47, 0);
    }
    100% {
        box-shadow: 0 0 0 3px #d53f4c;
    }
}

.connect .euiHue__range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
    border-color: #cf1f2f;
}

.connect .euiHue__range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
    border-color: #cf1f2f;
}

.connect .euiHue__range:focus::-ms-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
    border-color: #cf1f2f;
}

.connect .euiColorStops:not(.euiColorStops-isDisabled):focus {
    outline: 2px solid rgba(207, 31, 47, 0.3);
}

.connect .euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap--compressed {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiComboBoxOption.euiComboBoxOption-isFocused {
    cursor: pointer;
    color: #cf1f2f;
    background-color: #fae9ea;
}

.connect .euiContextMenuItem:focus {
    background-color: #fae9ea;
}

.connect .euiControlBar__tab.euiControlBar__tab--active {
    background-color: #fafbfd;
    box-shadow: inset 0 4px 0 #cf1f2f;
    color: #cf1f2f;
}

.connect .euiControlBar__controls .euiLink.euiLink--primary {
    color: #e5868f;
}

.connect .euiControlBar__controls .euiLink.euiLink--primary:hover {
    color: #dd626d;
}

.connect
    .euiControlBar__controls
    .euiControlBar__button.euiButton--primary:enabled:not(.euiButton--fill) {
    color: #e5868f;
    border-color: #e5868f;
}

.connect .euiControlBar__controls .euiButtonIcon--primary {
    color: #e5868f;
}

.connect .react-datepicker__navigation--previous:focus {
    background-color: #fae9ea;
    box-shadow: 0 0 0 2px #fae9ea;
}

.connect .react-datepicker__navigation--next:focus {
    background-color: #fae9ea;
    box-shadow: 0 0 0 2px #fae9ea;
}

.connect
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #cf1f2f;
    color: white;
    border-radius: 2px;
}

.connect
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #cf1f2f;
}

.connect .react-datepicker__day--today {
    font-weight: bold;
    color: #cf1f2f;
}

.connect .react-datepicker__day--selected,
.connect .react-datepicker__day--in-selecting-range {
    height: 32px;
    margin: 0 2px;
    border-radius: 4px;
    background-color: #cf1f2f;
    line-height: 28px;
    border: solid 2px #cf1f2f;
    color: #fff;
}

.connect .react-datepicker__day--selected:hover,
.connect .react-datepicker__day--in-selecting-range:hover {
    background-color: #b91c2a;
}

.connect .react-datepicker__day--keyboard-selected {
    border-radius: 4px;
    border: solid 2px #cf1f2f;
    font-weight: 700;
}

.connect .react-datepicker__day--keyboard-selected:hover {
    background-color: #b91c2a;
    color: #fff;
}

.connect .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(207, 31, 47, 0.5);
}

.connect .react-datepicker__year-read-view:hover,
.connect .react-datepicker__month-read-view:hover,
.connect .react-datepicker__month-year-read-view:hover {
    cursor: pointer;
    color: #cf1f2f;
}

.connect .react-datepicker__year-option--preselected,
.connect .react-datepicker__month-option--preselected {
    background: #fae9ea;
}

.connect .react-datepicker__year-option--selected_year.react-datepicker__year-option--preselected,
.connect
    .react-datepicker__month-option--selected_month.react-datepicker__month-option--preselected {
    background: #cf1f2f;
}

.connect .react-datepicker__time-list-item--preselected,
.connect .react-datepicker__year-option--preselected,
.connect .react-datepicker__month-option--preselected {
    background: #f5d4d6;
}

.connect .react-datepicker__time-container--focus {
    background: #fae9ea;
}

.connect .react-datepicker__month--accessible:focus {
    background: #fae9ea;
}

.connect
    .react-datepicker__month--accessible:focus
    .react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    border-top-color: #fae9ea;
    border-bottom-color: #fae9ea;
}

.connect .react-datepicker__navigation:focus {
    background-color: #fae9ea;
}

.connect .react-datepicker__year-option--selected_year,
.connect .react-datepicker__month-option--selected_month {
    background: #cf1f2f;
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
}

.connect .euiDatePopoverButton:focus,
.connect .euiDatePopoverButton-isSelected {
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
}

.connect .euiDatePopoverButton-isInvalid:focus,
.connect .euiDatePopoverButton-isInvalid.euiDatePopoverButton-isSelected {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    );
}

.connect .euiSuperDatePicker__prettyFormatLink {
    color: #cf1f2f;
    padding-left: 4px;
    flex-shrink: 0;
}

.connect .euiDataGrid__controlScroll {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    max-height: 400px;
    padding: 8px;
    margin: -8px;
}

.connect .euiDataGridHeaderCell:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(207, 31, 47, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    border-top: none;
}

.connect .euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn):focus-within {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(207, 31, 47, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    border-top: none;
}

.connect .euiDataGridColumnResizer:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #cf1f2f;
}

.connect .euiDataGridRowCell:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(207, 31, 47, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    margin-top: -1px;
}

.connect .euiDataGridColumnSelector__columnList {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    max-height: 400px;
    margin: 0 -8px;
}

.connect .euiDataGridColumnSorting__fieldList {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    padding-top: 4px;
    padding-bottom: 4px;
    max-height: 300px;
}

.connect .euiDroppable--withPanel.euiDroppable--withPanel--primary {
    background-color: #fae9ea;
}

.connect .euiExpression--primary:focus {
    background-color: rgba(207, 31, 47, 0.1);
}

.connect .euiExpression--primary.euiExpression-isActive {
    border-bottom-color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiExpression--primary .euiExpression__description {
    color: #cf1f2f;
}

.connect .euiFilterSelectItem:focus,
.connect .euiFilterSelectItem-isFocused {
    background-color: #fae9ea;
    color: #cf1f2f;
}

.connect .euiFlyoutBody .euiFlyoutBody__overflow {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
}

.connect .euiFlyoutBody .euiFlyoutBody__overflow.euiFlyoutBody__overflow--hasBanner {
    mask-image: linear-gradient(to bottom, red calc(100% - 7.5px), rgba(255, 0, 0, 0.1) 100%);
}

.connect .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    border-color: #cf1f2f;
    background-color: #cf1f2f;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}

.connect .euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
    border-color: #cf1f2f;
    background-color: #cf1f2f;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%28255, 255, 255%29' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.connect .euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square,
.connect .euiCheckbox .euiCheckbox__input:active:not(:disabled) + .euiCheckbox__square {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #cf1f2f;
}

.connect .euiFieldNumber:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldNumber--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldPassword:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldPassword--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldSearch:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldSearch--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldText:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFieldText--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFilePicker__showDrop .euiFilePicker__prompt,
.connect .euiFilePicker__input:focus + .euiFilePicker__prompt {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFilePicker--compressed .euiFilePicker__showDrop .euiFilePicker__prompt,
.connect .euiFilePicker--compressed .euiFilePicker__input:focus + .euiFilePicker__prompt {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiFormControlLayout--group .euiButtonIcon:focus-visible {
    outline: 2px solid rgba(207, 31, 47, 0.3);
    outline-offset: -2px;
}

.connect .euiFormLabel.euiFormLabel-isFocused {
    color: #cf1f2f;
    /* 1 */
}

.connect .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: #cf1f2f;
    background-color: #cf1f2f;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%28255, 255, 255%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}

.connect .euiRadio .euiRadio__input:focus + .euiRadio__circle,
.connect .euiRadio .euiRadio__input:active:not(:disabled) + .euiRadio__circle {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #cf1f2f;
}

.connect .euiRangeHighlight__progress--hasFocus {
    background-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible::-webkit-slider-thumb,
.connect .euiRangeSlider--hasFocus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
}

.connect .euiRangeSlider:focus-visible::-moz-range-thumb,
.connect .euiRangeSlider--hasFocus::-moz-range-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
}

.connect .euiRangeSlider:focus-visible::-ms-thumb,
.connect .euiRangeSlider--hasFocus::-ms-thumb {
    box-shadow: 0 0 0 3px rgba(207, 31, 47, 0.3);
}

.connect .euiRangeSlider:focus-visible ~ .euiRangeThumb,
.connect .euiRangeSlider--hasFocus ~ .euiRangeThumb {
    border-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible::-webkit-slider-runnable-track,
.connect .euiRangeSlider--hasFocus::-webkit-slider-runnable-track {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible::-moz-range-track,
.connect .euiRangeSlider--hasFocus::-moz-range-track {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible::-ms-fill-lower,
.connect .euiRangeSlider--hasFocus::-ms-fill-lower {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible::-ms-fill-upper,
.connect .euiRangeSlider--hasFocus::-ms-fill-upper {
    background-color: #cf1f2f;
    border-color: #cf1f2f;
}

.connect .euiRangeSlider:focus-visible ~ .euiRangeHighlight .euiRangeHighlight__progress,
.connect .euiRangeSlider--hasFocus ~ .euiRangeHighlight .euiRangeHighlight__progress {
    background-color: #cf1f2f;
}

.connect .euiRangeThumb:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #cf1f2f;
}

.connect .euiRangeTick:enabled:hover,
.connect .euiRangeTick:focus,
.euiRangeTick--selected {
    color: #cf1f2f;
}

.connect .euiSelect:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiSelect--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiTableRow.euiTableRow-isSelected {
    background-color: #fcf0f0;
}
.connect
    .euiTableRow.euiTableRow-isSelected
    + .euiTableRow.euiTableRow-isExpandedRow
    .euiTableRowCell {
    background-color: #fcf0f0;
}
.connect .euiTableRow.euiTableRow-isSelected:hover,
.euiTableRow.euiTableRow-isSelected:hover
    + .euiTableRow.euiTableRow-isExpandedRow
    .euiTableRowCell {
    background-color: #fae9ea;
}

.connect .euiSuperSelectControl:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiSuperSelectControl--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiSuperSelectControl.euiSuperSelect--isOpen__button {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiTextArea:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiTextArea--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(133, 68, 43, 0.1);
}

.connect .euiHeader--dark .euiHeaderLink-isActive {
    color: #df6b75;
}

.connect .euiHeader--dark .euiHeaderLogo:focus,
.connect .euiHeader--dark .euiHeaderLink:focus,
.connect .euiHeader--dark .euiHeaderSectionItemButton:focus {
    background: #681018;
}

.connect .euiHeaderSectionItemButton:focus {
    background: #fae9ea;
}

.connect .euiIcon--primary {
    color: #cf1f2f;
}

.connect .euiImage .euiImage__button:focus {
    outline: 2px solid rgba(207, 31, 47, 0.3);
}

.connect .euiLink.euiLink--primary {
    color: #cf1f2f;
}

.connect .euiLink.euiLink--primary:hover,
.connect .euiLink.euiLink--primary:focus {
    color: #a31825;
    text-decoration: underline;
}

.connect .euiListGroupItem--primary .euiListGroupItem__text:not(:disabled),
.connect .euiListGroupItem--primary .euiListGroupItem__button:not(:disabled) {
    color: #cf1f2f;
}

.connect .euiLoadingSpinner {
    flex-shrink: 0;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: solid 2px #d3dae6;
    border-color: #cf1f2f #d3dae6 #d3dae6 #d3dae6;
    animation: euiLoadingSpinner 0.6s infinite linear;
}

.connect .euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorFooter,
.connect .euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea,
.connect .euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea:focus,
.connect
    .euiMarkdownEditorDropZone--isDragging
    .euiMarkdownEditor:focus-within
    .euiMarkdownEditorTextArea {
    background-color: rgba(207, 31, 47, 0.1) !important;
}

.connect .euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea,
.connect .euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea:focus {
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    ) !important;
}

.connect .euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorFooter,
.connect .euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorTextArea,
.connect .euiMarkdownEditorDropZone--isDraggingError .euiMarkdownEditorTextArea:focus,
.connect
    .euiMarkdownEditorDropZone--isDraggingError
    .euiMarkdownEditor:focus-within
    .euiMarkdownEditorTextArea {
    background-color: rgba(189, 39, 30, 0.1) !important;
}

.connect .euiMarkdownEditorDropZone--hasError .euiMarkdownEditorTextArea,
.connect .euiMarkdownEditorDropZone--hasError .euiMarkdownEditorTextArea:focus {
    background-image: linear-gradient(
        to top,
        #bd271e,
        #bd271e 2px,
        transparent 2px,
        transparent 100%
    ) !important;
}

.connect .euiMarkdownEditorTextArea:focus,
.connect .euiMarkdownEditor:focus-within .euiMarkdownEditorTextArea {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #cf1f2f,
        #cf1f2f 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
}

.connect .euiModalBody .euiModalBody__overflow {
    scrollbar-width: thin;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    padding: 8px 24px;
}

.connect .euiNotificationEvent__title.euiLink {
    color: #cf1f2f;
}

.connect .euiNotificationEventMessages__accordionButton {
    color: #cf1f2f;
}

.connect .euiPaginationButton-isActive.euiPaginationButton-isActive {
    color: #cf1f2f;
}

.connect .euiProgress--primary.euiProgress--native::-webkit-progress-value {
    background-color: #cf1f2f;
}

.connect .euiProgress--primary.euiProgress--native::-moz-progress-bar {
    background-color: #cf1f2f;
}

.connect .euiProgress--primary.euiProgress--indeterminate:before {
    background-color: #cf1f2f;
}

.connect .euiProgress__data--primary .euiProgress__valueText {
    color: #cf1f2f;
}

.connect .euiResizableButton:focus:not(:disabled) {
    background-color: rgba(207, 31, 47, 0.1);
}

.connect .euiResizableButton:focus:not(:disabled):before,
.connect .euiResizableButton:focus:not(:disabled):after {
    background-color: #cf1f2f;
    transition: width 150ms ease, height 150ms ease, transform 150ms ease;
    transition-delay: 75ms;
}

.connect .euiSideNavItemButton.euiSideNavItemButton-isSelected {
    color: #cf1f2f;
    font-weight: 700;
}

.connect .euiSelectableList__list {
    mask-image: linear-gradient(
        to bottom,
        rgba(255, 0, 0, 0.1) 0%,
        red 7.5px,
        red calc(100% - 7.5px),
        rgba(255, 0, 0, 0.1) 100%
    );
    scrollbar-width: thin;
}

.connect .euiSelectableListItem-isFocused:not([aria-disabled='true']),
.connect .euiSelectableListItem:hover:not([aria-disabled='true']) {
    color: #cf1f2f;
    background-color: #fae9ea;
}

.connect .euiStat .euiStat__title--primary {
    color: #cf1f2f;
}

.connect .euiStepNumber {
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    border-radius: 32px;
    text-align: center;
    color: #fff;
    background-color: #cf1f2f;
    font-size: 16px;
    font-weight: 500;
}

.connect .euiStepNumber--small {
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    border-radius: 24px;
    text-align: center;
    color: #fff;
    background-color: #cf1f2f;
    font-size: 12px;
    font-weight: 500;
}

.connect .euiStepNumber.euiStepNumber-isHollow {
    background-color: transparent;
    border: 2px solid #cf1f2f;
}

.connect .euiStepHorizontal-isComplete::before,
.connect .euiStepHorizontal-isComplete::after {
    height: 2px;
    background-color: #cf1f2f;
}

.connect
    .euiStepHorizontal-isSelected
    .euiStepHorizontal__number:not([class*='danger']):not([class*='warning']):not([class*='loading']) {
    box-shadow: 0 2px 2px -1px rgba(183, 55, 66, 0.3);
}

.connect .euiStepHorizontal-isSelected::before {
    height: 2px;
    background-color: #cf1f2f;
}

.connect .euiTableHeaderButton:hover .euiTableCellContent__text,
.connect .euiTableHeaderButton:focus .euiTableCellContent__text {
    text-decoration: underline;
    color: #cf1f2f;
}

.connect .euiTableHeaderButton:hover .euiTableSortIcon,
.connect .euiTableHeaderButton:focus .euiTableSortIcon {
    fill: #cf1f2f;
}

.connect .euiTable.euiTable--responsive .euiTableRow.euiTableRow--primary {
    background-color: #fae9ea;
}

.connect .euiTable.euiTable--responsive .euiTableRow.euiTableRow--primary {
    background-color: #fae9ea;
}

.connect .euiTab.euiTab-isSelected {
    color: #cf1f2f;
    cursor: default;
}

.connect .euiTab.euiTab-isSelected::after {
    animation: euiTab 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    background-color: #cf1f2f;
    bottom: 0;
    content: ' ';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
}

.connect .euiToast__closeButton:focus svg {
    fill: #cf1f2f;
}

.connect .euiToast--primary {
    border-top: 2px solid #cf1f2f;
}

.connect .euiText a:not([class]) {
    color: #cf1f2f;
}

.connect .euiText a:not([class]):hover,
.connect .euiText a:not([class]):focus {
    color: #a31825;
    text-decoration: underline;
}
