@import 'elastic-common.css';
@import 'elastic-connect.css';
@import 'elastic-corporate.css';

:root {
    --connect-primary: #cf1f2f;
    --connect-secondary: #cf1f2f;
    --connect-primary-100: #b81b2a;
    --connect-primary-200: #991621;
    --connect-text-primary: #27272a;
    --connect-text-subdued: #888890;
    --connect-fore-color: white;

    --corporate-primary: #366d9d;
    --corporate-secondary: rgba(255, 213, 0, 0.86);
    --corporate-primary-100: #31638e;
    --corporate-primary-200: #2d577d;
    --corporate-fore-color: white;

    --text-primary: #27272a;
    --text-400: #4e4e4e;
    --text-secondary: #6b7280;
    --text-subdued: #a1a1aa;
    --background-light-100: #f4f5f7;
    --background-light-200: #e5e7eb;
    --background-light-300: rgba(209, 213, 219);
    --background-light-500: #63666a;
    --background-dark-300: #ACACAC;
    --background-dark-400: #63666A;
    --background-dark-500: #45484B;
    --shadow-100: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    --shadow-200: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    --shadow-300: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    --shadow-400: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    --shadow-500: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

body {
    --primary-gradient: linear-gradient(40deg, var(--primary), var(--primary-100));
}

body.connect {
    --primary: var(--connect-primary);
    --secondary: var(--connect-secondary);
    --primary-100: var(--connect-primary-100);
    --primary-200: var(--connect-primary-200);
    --fore-color: var(--connect-fore-color);
}

body.corporate {
    --primary: var(--corporate-primary);
    --secondary: var(--corporate-secondary);
    --primary-100: var(--corporate-primary-100);
    --primary-200: var(--corporate-primary-200);
    --fore-color: var(--corporate-fore-color);
}
