@charset "UTF-8";

a {
    text-decoration: none;
    color: #366d9d;
}
.euiAccordion__button:focus .euiAccordion__iconWrapper {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    color: #366d9d;
}

.euiAccordion__iconButton:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    color: #366d9d;
}
.euiBadge .euiBadge__iconButton:focus {
    background-color: rgba(255, 255, 255, 0.8);
    color: #000;
    border-radius: 2px;
}

.euiBadge--hollow {
    background-color: #fff;
    border-color: #d3dae6;
    color: #343741;
}
.euiBasicTable-loading tbody:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #366d9d;
    animation: euiBasicTableLoading 1000ms linear;
    animation-iteration-count: infinite;
}
.euiButton.euiButton-isDisabled .euiButtonContent__spinner {
    border-color: #366d9d currentColor currentColor currentColor;
}
.euiButton--primary {
    color: #366d9d;
    border-color: #366d9d;
}
.euiButton--primary.euiButton--fill {
    background-color: #366d9d;
    border-color: #366d9d;
    color: #fff;
}
.euiButton--primary.euiButton--fill:not([class*='isDisabled']):hover,
.euiButton--primary.euiButton--fill:not([class*='isDisabled']):focus,
.euiButton--primary.euiButton--fill:not([class*='isDisabled']):focus-within {
    background-color: #2f608a;
    border-color: #2f608a;
}
.euiButtonEmpty:disabled .euiButtonContent__spinner {
    border-color: #366d9d currentColor currentColor currentColor;
}
.euiButtonEmpty--primary {
    color: #366d9d;
}
.euiButtonEmpty--primary:focus {
    background-color: rgba(54, 109, 157, 0.1);
}
.euiButtonIcon.euiButtonIcon-isDisabled .euiButtonContent__spinner {
    border-color: #366d9d currentColor currentColor currentColor;
}
.euiButtonIcon--primary {
    color: #366d9d;
    border-color: #366d9d;
}
.euiButtonIcon--primary.euiButtonIcon--fill {
    background-color: #366d9d;
    border-color: #366d9d;
    color: #fff;
}
.euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):hover,
.euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):focus,
.euiButtonIcon--primary.euiButtonIcon--fill:not([class*='isDisabled']):focus-within {
    background-color: #2f608a;
    border-color: #2f608a;
}
.euiButtonGroupButton.euiButtonGroupButton-isDisabled .euiButtonContent__spinner {
    border-color: #366d9d currentColor currentColor currentColor;
}
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']) {
    color: #366d9d;
}
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #366d9d;
    border-color: #366d9d;
    color: #fff;
}
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:hover,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']).euiButtonGroupButton-isSelected:focus-within {
    background-color: #2f608a;
    border-color: #2f608a;
}
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):hover,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):focus,
.euiButtonGroupButton.euiButtonGroupButton--primary:not([class*='isDisabled']):focus-within {
    background-color: rgba(54, 109, 157, 0.1);
}
.euiButtonGroup--compressed .euiButtonGroupButton:not([class*='isDisabled']):focus,
.euiButtonGroup--compressed .euiButtonGroupButton:not([class*='isDisabled']):focus-within {
    outline: 2px solid rgba(54, 109, 157, 0.3);
}
.euiCallOut--primary {
    border-color: #366d9d;
    background-color: #ebf0f5;
}
.euiCallOut--primary .euiCallOutHeader__icon {
    fill: #366d9d;
}
.euiCallOut--primary .euiCallOutHeader__title {
    color: #366d9d;
}

.euiCard--isSelectable--primary.euiCard-isSelected:not(.euiCard-isDisabled) {
    border-color: #366d9d !important;
}

.euiCheckableCard:not(.euiCheckableCard-isDisabled).euiCheckableCard-isChecked {
    border-color: #366d9d;
}

.euiCodeBlock .token.tag {
    color: #4a7194;
}
.euiCodeBlock .token.class-name {
    color: #4a7194;
}
.euiCodeEditorKeyboardHint:focus {
    opacity: 1;
    border: 2px solid #366d9d;
    z-index: 1000;
}
.euiCollapsibleNavGroup--dark .euiCollapsibleNavGroup__heading:focus .euiAccordion__iconWrapper {
    color: #4a7ba7;
    animation-name: euiCollapsibleNavGroupDarkFocusRingAnimate !important;
}
@keyframes euiCollapsibleNavGroupDarkFocusRingAnimate {
    0% {
        box-shadow: 0 0 0 6px rgba(54, 109, 157, 0);
    }
    100% {
        box-shadow: 0 0 0 3px #4a7ba7;
    }
}
.euiHue__range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
    border-color: #366d9d;
}
.euiHue__range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
    border-color: #366d9d;
}
.euiHue__range:focus::-ms-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
    border-color: #366d9d;
}

.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap--compressed {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiComboBoxOption.euiComboBoxOption-isFocused {
    cursor: pointer;
    color: #366d9d;
    background-color: #ebf0f5;
}
.euiControlBar__tab.euiControlBar__tab--active {
    background-color: #fafbfd;
    box-shadow: inset 0 4px 0 #366d9d;
    color: #366d9d;
}

.euiControlBar__controls .euiLink.euiLink--primary {
    color: #87a8c4;
}
.euiControlBar__controls .euiLink.euiLink--primary:hover {
    color: #7299ba;
}
.euiControlBar__controls .euiLink.euiLink--text {
    color: #fff;
}
.euiControlBar__controls .euiControlBar__button.euiButton--primary:enabled {
    box-shadow: none;
}
.euiControlBar__controls .euiControlBar__button.euiButton--primary:enabled:not(.euiButton--fill) {
    color: #87a8c4;
    border-color: #87a8c4;
}
.euiControlBar__controls .euiButtonIcon--primary {
    color: #87a8c4;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #366d9d;
    color: white;
    border-radius: 2px;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background-color: #366d9d;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    color: #d3dae6;
}

.react-datepicker__week-number {
    color: #f5f7fa;
    display: inline-block;
    width: 32px;
    line-height: 28px;
    text-align: center;
    margin: 0 4px;
}
.react-datepicker__day--today {
    font-weight: bold;
    color: #366d9d;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
    height: 32px;
    margin: 0 2px;
    border-radius: 4px;
    background-color: rgba(255, 213, 0, 0.86);
    line-height: 28px;
    border: solid 2px rgba(255, 213, 0, 0.86);
    color: black;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover {
    background-color: rgba(255, 213, 0, 0.86);
}
.react-datepicker__day--keyboard-selected {
    border-radius: 4px;
    border: solid 2px rgba(255, 213, 0, 0.86);
    font-weight: 700;
}
.react-datepicker__day--keyboard-selected:hover {
    background-color: rgba(255, 213, 0, 0.86);
    color: black;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(54, 109, 157, 0.5);
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
    color: #366d9d;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #d3dce9;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    display: none;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #f5f7fa;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #d3dce9;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #d3dce9;
}

.react-datepicker__year-option--preselected,
.react-datepicker__month-option--preselected {
    background: #ebf0f5;
}

.react-datepicker__year-option--selected_year.react-datepicker__year-option--preselected,
.react-datepicker__month-option--selected_month.react-datepicker__month-option--preselected {
    background: #366d9d;
}

.react-datepicker__time-list-item--preselected,
.react-datepicker__year-option--preselected,
.react-datepicker__month-option--preselected {
    background: #dae3ed;
}

.react-datepicker__time-container--focus {
    background: #ebf0f5;
}

.react-datepicker__month-read-view:focus,
.react-datepicker__year-read-view:focus {
    text-decoration: underline;
}

.react-datepicker__month--accessible:focus {
    background: #ebf0f5;
}
.react-datepicker__month--accessible:focus
    .react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    border-top-color: #ebf0f5;
    border-bottom-color: #ebf0f5;
}

.react-datepicker__navigation:focus {
    background-color: #ebf0f5;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
    background: #366d9d;
    color: #fff;
    font-weight: 700;
    border-radius: 4px;
}

.euiDatePickerRange {
    max-width: 400px;
    width: 100%;
    height: auto;
    background-color: #fbfcfd;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
    transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
        background-size 150ms ease-in, background-color 150ms ease-in;
    display: flex;
    align-items: center;
    padding: 1px;
    /* 1 */
}
.euiDatePopoverButton:focus,
.euiDatePopoverButton-isSelected {
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
}
.euiSuperDatePicker__prettyFormatLink {
    color: #366d9d;
    padding-left: 4px;
    flex-shrink: 0;
}

.euiDataGridHeaderCell:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(54, 109, 157, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    border-top: none;
}
.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn):focus-within {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(54, 109, 157, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    border-top: none;
}
.euiDataGridColumnResizer:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #366d9d;
}
.euiDataGridRowCell:focus {
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px rgba(54, 109, 157, 0.3);
    border-radius: 1px;
    z-index: 2;
    outline: none;
    margin-top: -1px;
}
.euiExpression--primary:focus {
    background-color: rgba(54, 109, 157, 0.1);
}
.euiExpression--primary.euiExpression-isActive {
    border-bottom-color: #366d9d;
    border-color: #366d9d;
}
.euiExpression--primary .euiExpression__description {
    color: #366d9d;
}

.euiExpression--secondary:focus {
    background-color: rgba(1, 125, 115, 0.1);
}
.euiFilterSelectItem:focus,
.euiFilterSelectItem-isFocused {
    background-color: #ebf0f5;
    color: #366d9d;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    border-color: #366d9d;
    background-color: #366d9d;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
    border-color: #366d9d;
    background-color: #366d9d;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%28255, 255, 255%29' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square,
.euiCheckbox .euiCheckbox__input:active:not(:disabled) + .euiCheckbox__square {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #366d9d;
}
.euiFieldNumber:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldNumber--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldPassword--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldSearch:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldSearch--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldText:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFieldText--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFilePicker__showDrop .euiFilePicker__prompt,
.euiFilePicker__input:focus + .euiFilePicker__prompt {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFilePicker--compressed .euiFilePicker__showDrop .euiFilePicker__prompt,
.euiFilePicker--compressed .euiFilePicker__input:focus + .euiFilePicker__prompt {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiFormLabel.euiFormLabel-isFocused {
    color: #366d9d;
    /* 1 */
}
.euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: #366d9d;
    background-color: #366d9d;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%28255, 255, 255%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}
.euiRadio .euiRadio__input:focus + .euiRadio__circle,
.euiRadio .euiRadio__input:active:not(:disabled) + .euiRadio__circle {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #366d9d;
}
.euiRangeHighlight__progress--hasFocus {
    background-color: #366d9d;
}
.euiRangeLevel--primary {
    background-color: rgba(54, 109, 157, 0.3);
}
.euiRangeSlider:focus-visible::-webkit-slider-thumb,
.euiRangeSlider--hasFocus::-webkit-slider-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
}
.euiRangeSlider:focus-visible::-moz-range-thumb,
.euiRangeSlider--hasFocus::-moz-range-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
}
.euiRangeSlider:focus-visible::-ms-thumb,
.euiRangeSlider--hasFocus::-ms-thumb {
    box-shadow: 0 0 0 3px rgba(54, 109, 157, 0.3);
}
.euiRangeSlider:focus-visible ~ .euiRangeThumb,
.euiRangeSlider--hasFocus ~ .euiRangeThumb {
    border-color: #366d9d;
}
.euiRangeSlider:focus-visible::-webkit-slider-runnable-track,
.euiRangeSlider--hasFocus::-webkit-slider-runnable-track {
    background-color: #366d9d;
    border-color: #366d9d;
}
.euiRangeSlider:focus-visible::-moz-range-track,
.euiRangeSlider--hasFocus::-moz-range-track {
    background-color: #366d9d;
    border-color: #366d9d;
}
.euiRangeSlider:focus-visible::-ms-fill-lower,
.euiRangeSlider--hasFocus::-ms-fill-lower {
    background-color: #366d9d;
    border-color: #366d9d;
}
.euiRangeSlider:focus-visible::-ms-fill-upper,
.euiRangeSlider--hasFocus::-ms-fill-upper {
    background-color: #366d9d;
    border-color: #366d9d;
}
.euiRangeSlider:focus-visible ~ .euiRangeHighlight .euiRangeHighlight__progress,
.euiRangeSlider--hasFocus ~ .euiRangeHighlight .euiRangeHighlight__progress {
    background-color: #366d9d;
}
.euiRangeThumb:focus {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #366d9d;
}
.euiRangeTick:enabled:hover,
.euiRangeTick:focus,
.euiRangeTick--selected {
    color: #366d9d;
}
.euiSelect:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiSelect--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiSuperSelectControl--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiSuperSelectControl.euiSuperSelect--isOpen__button {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
    animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
    border-color: #366d9d;
}
.euiSwitch .euiSwitch__body {
    pointer-events: none;
    width: 44px;
    height: 20px;
    background-color: #366d9d;
    display: inline-block;
    position: relative;
    border-radius: 20px;
    vertical-align: middle;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb {
    border-color: #366d9d;
}

.euiTextArea:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
        inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiTextArea--compressed:focus {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
    box-shadow: inset 0 0 0 1px rgba(58, 64, 98, 0.1);
}
.euiHeader--dark .euiHeaderLink-isActive {
    color: #6c94b7;
}
.euiHeader--dark .euiHeaderLogo:focus,
.euiHeader--dark .euiHeaderLink:focus,
.euiHeader--dark .euiHeaderSectionItemButton:focus {
    background: #1b374f;
}
.euiIcon--primary {
    color: #366d9d;
}
.euiLink.euiLink--primary {
    color: #366d9d;
}
.euiLink.euiLink--primary:hover,
.euiLink.euiLink--primary:focus {
    color: #295377;
    text-decoration: underline;
}
.euiListGroupItem--primary .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--primary .euiListGroupItem__button:not(:disabled) {
    color: #366d9d;
}
.euiLoadingChart__bar:nth-child(2) {
    background-color: #6092c0;
    animation-delay: 0.1s;
}
.euiLoadingSpinner {
    flex-shrink: 0;
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: solid 2px #d3dae6;
    border-color: #366d9d #d3dae6 #d3dae6 #d3dae6;
}
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorFooter,
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea,
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea:focus,
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditor:focus-within .euiMarkdownEditorTextArea {
    background-color: rgba(54, 109, 157, 0.1) !important;
}
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea,
.euiMarkdownEditorDropZone--isDragging .euiMarkdownEditorTextArea:focus {
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    ) !important;
}
.euiMarkdownEditorTextArea:focus,
.euiMarkdownEditor:focus-within .euiMarkdownEditorTextArea {
    background-color: white;
    background-image: linear-gradient(
        to top,
        #366d9d,
        #366d9d 2px,
        transparent 2px,
        transparent 100%
    );
    background-size: 100% 100%;
}
.euiNotificationEvent__title.euiLink {
    color: #366d9d;
}
.euiNotificationEventMessages__accordionButton {
    color: #366d9d;
}
.euiPaginationButton-isActive.euiPaginationButton-isActive {
    color: #366d9d;
}
.euiProgress--primary.euiProgress--native::-webkit-progress-value {
    background-color: #366d9d;
}
.euiProgress--primary.euiProgress--native::-moz-progress-bar {
    background-color: #366d9d;
}
.euiProgress--primary.euiProgress--indeterminate:before {
    background-color: #366d9d;
}

.euiProgress__data--primary .euiProgress__valueText {
    color: #366d9d;
}

.euiProgress--vis1.euiProgress--native::-webkit-progress-value {
    background-color: #6092c0;
}
.euiProgress--vis1.euiProgress--native::-moz-progress-bar {
    background-color: #6092c0;
}
.euiProgress--vis1.euiProgress--indeterminate:before {
    background-color: #6092c0;
}

.euiProgress__data--vis1 .euiProgress__valueText {
    color: #4e779c;
}
.euiResizableButton:focus:not(:disabled) {
    background-color: rgba(54, 109, 157, 0.1);
}
.euiResizableButton:focus:not(:disabled):before,
.euiResizableButton:focus:not(:disabled):after {
    background-color: #366d9d;
    transition: width 150ms ease, height 150ms ease, transform 150ms ease;
    transition-delay: 75ms;
}
.euiSideNavItemButton.euiSideNavItemButton-isSelected {
    color: #366d9d;
    font-weight: 700;
}
.euiSelectableListItem-isFocused:not([aria-disabled='true']),
.euiSelectableListItem:hover:not([aria-disabled='true']) {
    color: #366d9d;
    background-color: #ebf0f5;
}
.euiSelectableTemplateSitewide__optionMeta--application {
    color: #4e779c;
    font-weight: 500;
}
.euiStat .euiStat__title--primary {
    color: #366d9d;
}
.euiStepNumber {
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    border-radius: 32px;
    text-align: center;
    color: #fff;
    background-color: #366d9d;
    font-size: 16px;
    font-weight: 500;
}
.euiStepNumber--small {
    width: 24px;
    height: 24px;
    display: inline-block;
    line-height: 24px;
    border-radius: 24px;
    text-align: center;
    color: #fff;
    background-color: #366d9d;
    font-size: 12px;
    font-weight: 500;
}
.euiStepNumber.euiStepNumber-isHollow {
    background-color: transparent;
    border: 2px solid #366d9d;
}
.euiStepHorizontal-isComplete::before,
.euiStepHorizontal-isComplete::after {
    height: 2px;
    background-color: #366d9d;
}

.euiStepHorizontal-isSelected
    .euiStepHorizontal__number:not([class*='danger']):not([class*='warning']):not([class*='loading']) {
    box-shadow: 0 2px 2px -1px rgba(75, 108, 136, 0.3);
}
.euiStepHorizontal-isSelected::before {
    height: 2px;
    background-color: #366d9d;
}

.euiSuggestItem .euiSuggestItem__type--tint1 {
    background-color: #e2ebf4;
    color: #466b8d;
}
.euiTableHeaderButton:hover .euiTableCellContent__text,
.euiTableHeaderButton:focus .euiTableCellContent__text {
    text-decoration: underline;
    color: #366d9d;
}
.euiTableHeaderButton:hover .euiTableSortIcon,
.euiTableHeaderButton:focus .euiTableSortIcon {
    fill: #366d9d;
}

.euiTab.euiTab-isSelected {
    color: #366d9d;
    cursor: default;
}
.euiTab.euiTab-isSelected::after {
    animation: euiTab 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
    background-color: #366d9d;
    bottom: 0;
    content: ' ';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
}
.euiToast__closeButton:focus svg {
    fill: #366d9d;
}

.euiToast--primary {
    border-top: 2px solid #366d9d;
}

.euiToken--euiColorVis1 {
    color: #6092c0;
}
.euiToken--euiColorVis1.euiToken--light {
    color: #4a7194;
    background-color: #eff4f9;
    box-shadow: inset 0 0 0 1px #cfdeec;
}
.euiToken--euiColorVis1.euiToken--dark {
    background-color: #79aad9;
    color: #000;
}

.euiText a:not([class]) {
    color: #366d9d;
}
.euiText a:not([class]):hover,
.euiText a:not([class]):focus {
    color: #295377;
    text-decoration: underline;
}
